import { useState } from "react";
import { TRACKING_NAF_OPTIONS } from "../../../../constants";
import LoadingButton from "../../../generic-components/button";
import { useMutation } from "@apollo/client";
import { logoutUser } from "../../../../graphql/mutations/logoutUser";
import { legacyAppRedirection, removeCookies } from "../../../../helpers";
import useStepper from "../../../../hooks/useStepper";

const LogoutButton = () => {
  const { setSaveError, setErrorToast, personalDetails } = useStepper();
  const [logout] = useMutation(logoutUser);

  const [isLoading, setIsLoading] = useState();

  const handleLogout = () => {
    setIsLoading(true);
    logout({
      onCompleted: (data) => {
        removeCookies();
        window.location.href = data?.destroy_session?.logout_url;
      },
      onError: (error) => {
        if (error.message === "Token has expired") {
          removeCookies();
          legacyAppRedirection(personalDetails?.sub_domain, "/");
        } else {
          setIsLoading(false);
          setSaveError(error);
          setErrorToast(true);
        }
      },
    });
  };
  return (
    <LoadingButton
      buttonTitle="LOGOUT"
      trackingDetails={TRACKING_NAF_OPTIONS}
      loading={isLoading}
      handleClick={handleLogout}
      styleClass="Header-outlined-btn Header-outlined-btn-sm"
    />
  );
};

export default LogoutButton;
