import { useState } from "react";
import { Box, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderLinksection from "./GlobalHeaderLinksection";
import ButtonSection from "./ButtonSection";
import mmmLogo from "../../../../assets/images/mmm-white-logo.png";

export default function GobalHeaderMobile() {
  const [state, setState] = useState(false);

  return (
    <Box className="headerMobile">
      <Box onClick={() => setState(true)}>
        <MenuIcon className="menuIcon" />
      </Box>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => setState(false)}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "80%" },
        }}
        className="headerMobile-drower"
      >
        <Box className="header">
          <Box className="mobile-header-logo-container">
            <img src={mmmLogo} alt="mmm-white-logo" className="header-logo" />
          </Box>
        </Box>
        <HeaderLinksection />
        <Box className="logout-container">
          <ButtonSection />
        </Box>
      </Drawer>
    </Box>
  );
}
