import React from "react";
import errorImage from "../assets/error.jpg";
import statusImage from "../assets/500.png";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function FallbackComponent() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Box className="fall-back">
        <Container>
          <h1 className="fall-back__heading">500 Error</h1>
          <div className="fall-back__middle" spacing={2}>
            <img
              src={statusImage}
              alt="alt-server-error"
              className="fall-back_image_1"
            />
            <div className="fall-back-stack">
              <h2>
                <strong>It's not you, it's us</strong>
              </h2>
              <Typography className="SharedHeader-heading">
                While we're working out what went wrong, head back to the
                homepage or go back and try again. Our systems team have been
                notified of this error.
              </Typography>
              <Button
                className="contained-btn"
                variant="contained"
                handleClick={() => navigate("/home")}
              >
                Take me there
              </Button>
            </div>
          </div>
          <img
            src={errorImage}
            alt="alt-server-error"
            className="fall-back_image"
          />
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default FallbackComponent;
