import React from "react";
import { ListItemText, ToggleButton, ToggleButtonGroup } from "@mui/material";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import { TRACKING_NAF_OPTIONS } from "../../../constants";
import { useRef, useState } from "react";
import useStepper from "../../../hooks/useStepper";

const ToggleAmountButton = ({
  updateAvcAmount,
  contributionAmount,
  showContributionAmountBtn,
}) => {
  const { userTrackingMutation } = useUserEvent();
  const toggleAmount1 = useRef();
  const toggleAmount2 = useRef();
  const toggleAmount3 = useRef();
  const { trackingCode } = useStepper();

  const [clickCounts, setClickCounts] = useState(null);

  const handleUserEvent = (element, value) => {
    const isSelected = clickCounts === value;
    setClickCounts(isSelected ? null : value);

    const fieldName = isSelected
      ? `Unselected ${element.current.textContent}`
      : `Selected ${element.current.textContent}`;

    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName,
        field_value: `£${String(element.current.value)}`,
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <>
      <ToggleButtonGroup
        value={contributionAmount}
        exclusive
        className="btn-list mt-18"
        onChange={updateAvcAmount}
        aria-label="Platform"
      >
        {showContributionAmountBtn(10) && (
          <ToggleButton
            ref={toggleAmount1}
            value={10}
            onClick={() => {
              handleUserEvent(toggleAmount1, 10);
            }}
            className="list-item item-1"
          >
            <ListItemText primary="£10" />
          </ToggleButton>
        )}
        {showContributionAmountBtn(50) && (
          <ToggleButton
            ref={toggleAmount2}
            value={50}
            onClick={() => {
              handleUserEvent(toggleAmount2, 50);
            }}
            className="list-item"
          >
            <ListItemText primary="£50" />
          </ToggleButton>
        )}
        {showContributionAmountBtn(100) && (
          <ToggleButton
            ref={toggleAmount3}
            value={100}
            onClick={() => {
              handleUserEvent(toggleAmount3, 100);
            }}
            className="list-item item-3"
          >
            <ListItemText primary="£100" />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </>
  );
};

ToggleAmountButton.propTypes = {
  contributionAmount: PropTypes.Number,
  showContributionAmountBtn: PropTypes.func,
  updateAvcAmount: PropTypes.func,
};
export default ToggleAmountButton;
