import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";

const ContactDescription = ({ organisation, isNHS, multiForm }) => {
  return (
    <Box>
      <Typography>
        {`If you have any queries about ${
          isNHS
            ? multiForm?.formSharedTitle || "My Money Matters"
            : "My Money Matters"
        }, please contact the scheme
        administrator.`}
      </Typography>
      <Typography className="primary-text mt-18">
        <strong>{organisation?.scheme_administrator_first_name || ""}</strong>
      </Typography>
      <Typography className="primary-text mt-5">
        <strong>{organisation?.scheme_administrator_last_name || ""}</strong>
      </Typography>
      <Typography className="primary-text mt-30">
        <strong>{organisation?.scheme_administrator_phone || ""}</strong>
      </Typography>
      <Typography className="primary-text mt-30">
        <strong>{organisation?.scheme_administrator_email || ""}</strong>
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    multiForm: state.multiForm,
    isNHS: isNHSApplication(state),
  };
};

ContactDescription.propTypes = {
  organisation: PropTypes.object,
  isNHS: PropTypes.bool,
  multiForm: PropTypes.object,
};

export default connect(mapStateToProps)(ContactDescription);
