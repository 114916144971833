import React from "react";
import SaveChangesAlert from "./SaveChangesalert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { Box, Button, Divider, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  legacyAppRedirection,
  parameterizedDetail,
  removeCookies,
  sortArray,
} from "../../../../helpers";
import { logoutUser } from "../../../../graphql/mutations/logoutUser";
import { useMutation } from "@apollo/client";
import { TRACKING_HEADER_OPTIONS, userRoles } from "../../../../constants";
import useUserEvent from "../../../../hooks/useUserEvent";
import { useLocation, useNavigate } from "react-router-dom";
import { newApplicationFormPath } from "../../../../constants/path";
import NavigationLinks from "../../../generic-components/navigation-links";
import useStepper from "../../../../hooks/useStepper";
import { UpdateUserCurrentOrganisation } from "../../../../graphql/mutations/personalDetails";
import usePlan from "../../../../hooks/usePlan";

const Logoutsection = ({ user, user_roles_organisation }) => {
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const [opendialog, setOpendialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [logout] = useMutation(logoutUser);
  const [setCurrentOrganisation] = useMutation(UpdateUserCurrentOrganisation);
  const { userTrackingMutation } = useUserEvent();
  const menuItemRef = React.useRef(null);

  const { pathname } = useLocation();
  const {
    trackingCode,
    personalDetails,
    setFullScreenLoading,
    setSaveError,
    setErrorToast,
  } = useStepper();
  const { isLatestActivePlan } = usePlan();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOrganisationChange = async (organisation, isAdminRole) => {
    setFullScreenLoading(true);
    setCurrentOrganisation({
      variables: {
        user_id: personalDetails?.id,
        current_organisation_id: organisation?.value,
        current_role_id: organisation?.role,
      },
      onCompleted: () => {
        const redirectionURL = organisation?.subDomain
          ? `https://${organisation?.subDomain}.${process.env.REACT_APP_GENERIC_URL}`
          : window.location.origin;

        if (isAdminRole) {
          legacyAppRedirection(
            organisation?.subDomain,
            `/organisations/${parameterizedDetail(
              organisation?.value,
              organisation?.label
            )}/dashboard`
          );
          return;
        }

        if (organisation?.isNHS) {
          legacyAppRedirection(
            organisation?.subDomain,
            "/schemes/my_salary_sacrifice_avc"
          );
          return;
        }

        window.location.href = redirectionURL;
      },
      onError: (error) => {
        setFullScreenLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleUserEvent = (element, itemName) => {
    userTrackingMutation({
      variables: {
        ...TRACKING_HEADER_OPTIONS,
        field_name: element?.current?.dataset?.type || "",
        field_value:
          itemName || element?.current?.textContent?.toString() || "",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOpenDialog = () => {
    const IS_NEW_APPLICATION_PATH = [
      newApplicationFormPath.NEW_APPLICATION_FORM,
      newApplicationFormPath.NHS_APPLICATION_FORM,
    ].includes(pathname);

    if (isLatestActivePlan || !IS_NEW_APPLICATION_PATH) {
      setLoading(true);
      logout({
        onCompleted: (data) => {
          removeCookies();
          window.location.href = data?.destroy_session?.logout_url;
        },
        onError: () => {
          setLoading(false);
          removeCookies();
          legacyAppRedirection(personalDetails?.sub_domain, "/");
        },
      });
      return;
    }

    setOpendialog(true);
    setOpen((prevOpen) => !prevOpen);
  };
  const handleCloseDialog = () => {
    setOpendialog(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        data-type="button"
        onClick={() => {
          handleUserEvent(anchorRef);
          handleToggle();
        }}
        className="Header-outlined-btn"
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon className="keydownIcon" />}
      >
        <Box>
          <Box component={Typography} className="dropdown-text">
            Hello {user.first_name || ""}!
          </Box>
          <Box component={Typography} className="moreoption">
            More Options
          </Box>
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className="menu-container mt-5"
        sx={{ width: { xs: "90%", sm: "50%", md: "100%" } }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper sx={{ width: "max-content", minWidth: "153px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {![newApplicationFormPath.CHANGE_PORTAL].includes(pathname) &&
                    !user?.backend_user &&
                    user?.total_roles &&
                    !!user_roles_organisation?.length && (
                      <>
                        <Typography variant="subtitle2" className="menu-title">
                          Your Organisations
                        </Typography>
                        {[...user_roles_organisation]
                          .sort((a, b) => sortArray(a, b, "label"))
                          .map((organisation, index) => (
                            <MenuItem
                              data-type="Menu Item"
                              onClick={() => {
                                handleOrganisationChange(
                                  organisation,
                                  user?.role_name ===
                                    userRoles.ORGANISATION_ADMIN
                                );
                                setOpen(false);
                              }}
                              key={organisation.label}
                              className={`dropdown-item menu-list ${
                                user.organisation_id === organisation.value
                                  ? "selected-item"
                                  : ""
                              }
                           `}
                            >
                              <NavigationLinks
                                name={organisation.label}
                                key={index}
                                trackingDetails={TRACKING_HEADER_OPTIONS}
                              >
                                {organisation.label}
                              </NavigationLinks>
                            </MenuItem>
                          ))}
                        <Divider light />
                      </>
                    )}
                  {user?.is_employer_and_employee && (
                    <MenuItem
                      ref={menuItemRef}
                      data-type="Menu Item"
                      onClick={() => {
                        handleUserEvent(menuItemRef, "Change Role");
                        navigate("/change_portal");
                        setOpen(false);
                      }}
                      className="dropdown-item menu-list"
                    >
                      Change Role
                    </MenuItem>
                  )}
                  <MenuItem
                    ref={menuItemRef}
                    data-type="Menu Item"
                    onClose={handleClose}
                    onClick={() => {
                      handleUserEvent(menuItemRef);
                      handleOpenDialog();
                    }}
                    className="dropdown-item menu-list"
                  >
                    {isLoading ? "Logging out" : "Logout"}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <SaveChangesAlert
        opendialog={opendialog}
        removeCookies={() => removeCookies()}
        handleClosedialog={handleCloseDialog}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    user_roles_organisation: state.organisation.user_roles_organisations,
  };
};

Logoutsection.propTypes = {
  user: PropTypes.object,
  user_roles_organisation: PropTypes.array,
};

export default connect(mapStateToProps)(Logoutsection);
