import { gql } from "@apollo/client";

export const getPayPeriod = gql`
  query getPayPeriod($organisation_id: Int!) {
    pay_periods(
      distinct_on: period
      where: {
        organisation_id: { _eq: $organisation_id }
        enabled: { _eq: true }
      }
    ) {
      id
      period
      pay_period_identifier
    }
  }
`;
