import { gql } from "@apollo/client";

export const getInvestmentOptions = gql`
  query getInvestmentOptions($pensionId: Int!, $providerId: Int!) {
    investment_options(
      where: {
        investment_options_pension_funds: {
          pension_fund_id: { _eq: $pensionId }
        }
        enabled: { _eq: true }
        provider_id: { _eq: $providerId }
      }
    ) {
      id
      display_name
      lifestyle
    }

    investment_options_pension_funds(
      where: {
        pension_fund_id: { _eq: $pensionId }
        default_option: { _eq: true }
      }
    ) {
      investment_option {
        id
        display_name
        lifestyle
      }
    }
  }
`;
