import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { redirectToURL } from "../../../../../helpers";

const TermsAndFaqs = ({ faq, termsAndConditions }) => {
  return (
    <Box className="p-30">
      <Typography variant="h6">
        <strong>Additional Information</strong>
      </Typography>
      <Box
        className="web-link cursor-pointer underline-text black-text mt-18"
        onClick={() => {
          redirectToURL(faq, true);
        }}
      >
        Frequently Asked Questions
      </Box>
      <Box
        className="web-link cursor-pointer underline-text black-text mt-18"
        onClick={() => {
          redirectToURL(termsAndConditions, true);
        }}
      >
        Terms and Conditions
      </Box>
    </Box>
  );
};

TermsAndFaqs.propTypes = {
  faq: PropTypes.string,
  termsAndConditions: PropTypes.string,
};

export default TermsAndFaqs;
