import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React from "react";
import frontImage from "../../../../assets/images/front-image.png";
import avcwiseLogoDark from "../../../../assets/images/avc-logo-dark.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AfterSignin = ({ formSharedTitle }) => {
  return (
    <>
      <Box
        className="front-screen-container container-wrapper"
        sx={{ backgroundImage: `url(${frontImage})` }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} className="content-wrapper">
              <Grid item xs={12} sm={10} md={7} sx={{ margin: "0 auto" }}>
                <Box className="content-container">
                  <Box className="inner-content-container">
                    <Box className="img-container mb-50">
                      <Box
                        component="img"
                        className="logo-img"
                        src={avcwiseLogoDark}
                        alt="logo-dark"
                      />
                    </Box>
                    <Typography variant="body1" className="content">
                      Great, so you're ready to apply to join your employer's
                      {formSharedTitle} scheme.
                    </Typography>
                    <Typography variant="body1" className="content mt-18">
                      Let's get you started on your journey to a more
                      comfortable, financially healthy retirement!
                    </Typography>
                    <Button
                      component={Link}
                      to="/"
                      variant="contained"
                      className="signinflow-btn mt-30"
                    >
                      Continue
                    </Button>
                    .
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

AfterSignin.propTypes = {
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(AfterSignin);
