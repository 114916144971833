import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const MyPlanHeader = ({ heading }) => {
  return (
    <Box className="my-plan-header">
      <Typography className="my-plan-heading">{heading}</Typography>
    </Box>
  );
};

MyPlanHeader.propTypes = {
  heading: PropTypes.object,
};

export default MyPlanHeader;
