import React from "react";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import useStepper from "../../../hooks/useStepper";
import { useNavigate } from "react-router-dom";
const LogoButton = ({ name, logoPath, trackingDetails, styleClass }) => {
  const { userTrackingMutation } = useUserEvent();
  const navigate = useNavigate();
  const { trackingCode } = useStepper();

  return (
    <>
      <Box
        component="img"
        className={styleClass}
        src={logoPath}
        alt={`${name}-icon`}
        data-type="image"
        data-value={name}
        onClick={(e) => {
          userTrackingMutation({
            variables: {
              ...trackingDetails,
              field_name: e.target?.dataset?.type || name || "",
              field_value: e.target?.dataset?.value?.toString() || "",
              avc_track_code: trackingCode,
            },
          });
          navigate("/");
        }}
      />
    </>
  );
};

LogoButton.propTypes = {
  trackingDetails: PropTypes.object,
  name: PropTypes.string,
  logoPath: PropTypes.string,
  styleClass: PropTypes.string,
};

export default LogoButton;
