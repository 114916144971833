import { Typography, Stack } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import PropTypes from "prop-types";
import IconButton from "../../../../generic-components/icon-btn";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";

const FinalSummaryBoxHeader = ({ heading, handleChange }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="final-summary-box-header"
      >
        <Stack direction="row" alignItems="center">
          <ArrowRightIcon className="arrow-right" fontSize="large" />
          <Typography className="heading stepper-content">
            <strong>{heading}</strong>
          </Typography>
        </Stack>
        <IconButton
          trackingDetails={TRACKING_NAF_OPTIONS}
          addButtonContent={heading}
          buttonTitle="Change"
          styleClass="outlined-btn change-btn"
          handleClick={handleChange}
          icon={<DriveFileRenameOutlineIcon />}
        />
      </Stack>
    </>
  );
};

FinalSummaryBoxHeader.propTypes = {
  heading: PropTypes.string,
  handleChange: PropTypes.func,
};

export default FinalSummaryBoxHeader;
