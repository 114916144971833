import { gql } from "@apollo/client";

export const updatedAvcCost = gql`
  mutation UpdatedAvcCost(
    $id: Int!
    $total_avc_amount_requested: float8!
    $additional_avc_amount: Int
    $updated_at: timestamp
    $previous_amount_added: float8!
    $contribution_amount_updated: Boolean!
    $step_number: Int!
  ) {
    update_temp_plans(
      _set: {
        total_avc_amount_requested: $total_avc_amount_requested
        updated_at: $updated_at
        contribution_amount_updated: $contribution_amount_updated
        previous_amount_added: $previous_amount_added
        additional_avc_amount: $additional_avc_amount
        step_number: $step_number
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        total_avc_amount_requested
        step_number
      }
    }
  }
`;
