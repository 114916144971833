import React from "react";
import { Box, Typography } from "@mui/material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";

const OrganisationSelect = ({ handleChange, organisation }) => {
  const items = [
    { name: "New Forest district court", value: "New Forest district court" },
    { name: "New Forest district court", value: "New Forest district court" },
    { name: "New Forest district court", value: "New Forest district court" },
    {
      name: "New Forest district court",
      value: "New Forest district court",
    },
  ];

  return (
    <Box className="Organisation mt-30">
      <Typography className="organisation-lable">
        Please select your organisation below.
      </Typography>
      {/* <SelectForm
        name="Organisation"
        labelId="Organisation"
        menuItems={items}
        label="Organisation"
        className="mt-18"
      /> */}
      <FormControl fullWidth className="mt-18">
        <InputLabel>
          <Stack direction="row" alignItems="center">
            Organisation
          </Stack>
        </InputLabel>
        <Select
          name="Organisation"
          value={organisation || ""}
          label="Organisation"
          onChange={handleChange}
          sx={{ width: "100%" }}
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          {items.map((options, index) => (
            <MenuItem key={index} value={options.value}>
              {options.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OrganisationSelect;

OrganisationSelect.propTypes = {
  handleChange: PropTypes.string,
  organisation: PropTypes.string,
};
