import { gql } from "@apollo/client";

export const userTrackingevent = gql`
  mutation userEvent(
    $tracking_module: String!
    $application_type: String!
    $field_state: String!
    $field_name: String!
    $field_value: String!
    $avc_track_code: String!
  ) {
    NewFormTracking(
      tracking_module: $tracking_module
      application_type: $application_type
      field_state: $field_state
      field_name: $field_name
      field_value: $field_value
      avc_track_code: $avc_track_code
    ) {
      message
    }
  }
`;
