const otherOrganisationOptions = [
  {
    name: "Yes",
    value: "yes",
  },
  {
    name: "No",
    value: "no",
  },
];

export { otherOrganisationOptions };
