import {
  Box,
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const PersonalDetails = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [startDate, setStartDate] = useState();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className="PersonalDetails mt-30">
      <Typography className="Heading">
        Please fill in your details below.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="first_name"
            color="mmm"
            name="first_name"
            label="First Name"
            variant="outlined"
            className="mt-18"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="last_name"
            name="last_name"
            color="mmm"
            label="Last Name"
            variant="outlined"
            className="mt-18"
            fullWidth
          />
        </Grid>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Date of Birth"
          className="mt-18"
          sx={{ width: "100%" }}
          openTo="year"
          value={startDate}
          inputFormat="DD-MM-YYYY"
          autoComplete="off"
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              autoComplete="off"
              color="mmm"
              id="date_of_birth"
              name="date_of_birth"
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        id="email"
        name="email"
        label="Email"
        color="mmm"
        variant="outlined"
        className="mt-18"
        type="email"
        fullWidth
      />
      <FormControl variant="outlined" className="mt-18" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="Password"
          name="Password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <FormControl variant="outlined" className="mt-18" fullWidth>
        <InputLabel htmlFor="outlined-adornment-password">
          Confirm Password
        </InputLabel>
        <OutlinedInput
          id="ConfirmPassword"
          name="ConfirmPassword"
          type="password"
          label="Confirm Password"
        />
      </FormControl>
    </Box>
  );
};

export default PersonalDetails;
