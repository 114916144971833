import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import PropTypes from "prop-types";
import organisationLogo from "../../../../assets/images/logo_nf.png";

const SharedHeaderRegister = ({ heading, activeStep }) => {
  return (
    <>
      <Box className="SharedHeaderRegister">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            <Box className="SharedHeader-sideline"></Box>
            <Typography className="SharedHeader-heading">{heading}</Typography>
          </Stack>
          <Box className="organisation-logo-container">
            {activeStep == 0 ? (
              ""
            ) : (
              <Box
                component="img"
                alt="organisation-logo"
                className="organisation-logo"
                src={organisationLogo}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

SharedHeaderRegister.propTypes = {
  heading: PropTypes.string,
  activeStep: PropTypes.number,
};

export default SharedHeaderRegister;
