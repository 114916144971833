import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import Checkbox from "../checkbox";

const CheckboxGroup = ({ checkboxes, handleCustomChange, trackingDetails }) => {
  const { values, setFieldValue } = useFormikContext();
  const handleChange = () => {
    checkboxes.map((checkbox) => {
      setFieldValue(checkbox.name, !values[checkbox.name]);
    });
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
    >
      {checkboxes.map((checkbox, index) => {
        return (
          <Checkbox
            key={index}
            name={checkbox.name}
            label={checkbox.label}
            trackingDetails={trackingDetails}
            handleChange={() => {
              handleChange();
              handleCustomChange &&
                handleCustomChange(null, null, setFieldValue);
            }}
          />
        );
      })}
    </Stack>
  );
};

CheckboxGroup.propTypes = {
  checkboxes: PropTypes.array,
  handleCustomChange: PropTypes.func,
  trackingDetails: PropTypes.object,
};

export default CheckboxGroup;
