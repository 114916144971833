import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import LoggedinHeader from "./loggedin-header/LoggedinHeader";
import GlobalHeader from "./global-header/GlobalHeader";

const Header = () => {
  const [loggedin] = useState(true);
  return (
    <>
      <Box className="header">
        <Container>
          <Grid container>
            {loggedin ? <LoggedinHeader /> : <GlobalHeader />}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Header;
