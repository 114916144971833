import { gql } from "@apollo/client";

export const updateRecommendationMutation = gql`
  mutation updateFundDetails($id: Int!, $temp_plan_id: Int!) {
    update_ningi_journeys(
      where: { id: { _eq: $id } }
      _set: { recommendation_changed: true }
    ) {
      returning {
        provider_id
        id
      }
    }
    update_temp_plans(
      where: { id: { _eq: $temp_plan_id } }
      _set: { investment_advice: true }
    ) {
      returning {
        id
      }
    }
  }
`;
