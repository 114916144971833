import { Stack, Switch, FormControlLabel } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";
import { findItemBySelectedValue } from "../../../helpers";

const ToggleSwitchButton = ({
  name,
  label,
  setValue,
  trackingDetails,
  disabled = false,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  const handleChange = (event) => {
    const { checked } = event.target;
    setFieldValue(name, checked);
    handleUserEvent(event);
    setValue?.(checked);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" className="mt-18">
      <FormControlLabel
        labelPlacement="start"
        label={label}
        control={
          <Switch
            name={name}
            disabled={disabled}
            color="secondary"
            checked={values[name]}
            onChange={handleChange}
            className="switch-button"
          />
        }
      />
    </Stack>
  );
};

ToggleSwitchButton.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  trackingDetails: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default ToggleSwitchButton;
