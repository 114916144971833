import { gql } from "@apollo/client";

export const getTax = gql`
  query GetTaxQuery(
    $annual_salary: Int
    $total_avc_amount_requested: Float
    $avc_interval: String
    $scheme_id: Int
    $add_ni_savings_onto_avc: Boolean
  ) {
    plan_savings(
      annual_salary: $annual_salary
      total_avc_amount_requested: $total_avc_amount_requested
      avc_interval: $avc_interval
      add_ni_savings_onto_avc: $add_ni_savings_onto_avc
      scheme_id: $scheme_id
    ) {
      income_tax_saving_on_salary_sacrifice
      monthly_nic_saving_on_salary_sacrifice
      gross_value
    }
  }
`;
