import { gql } from "@apollo/client";

export const createOrgPayrollSetting = gql`
  mutation createOrgPayrollSetting(
    $organisation_id: Int!
    $invoice_percentage: numeric
    $payroll_date: Int
    $payroll_frequency: String
    $payroll_identifier: String
    $purchase_order_number: String
    $purchase_order_number_enabled: Boolean
    $invoice_percentage_enabled: Boolean
    $created_at: timestamp!
  ) {
    insert_organisation_payroll_settings(
      objects: {
        invoice_percentage_enabled: $invoice_percentage_enabled
        payroll_frequency: $payroll_frequency
        purchase_order_number_enabled: $purchase_order_number_enabled
        payroll_date: $payroll_date
        payroll_identifier: $payroll_identifier
        purchase_order_number: $purchase_order_number
        invoice_percentage: $invoice_percentage
        organisation_id: $organisation_id
        created_at: $created_at
      }
    ) {
      returning {
        payroll_frequency
        payroll_date
        payroll_identifier
        purchase_order_number
        invoice_percentage
      }
    }
  }
`;

export const updateOrgPayrollSetting = gql`
  mutation updateOrgPayrollSetting(
    $organisation_id: Int!
    $invoice_percentage: numeric
    $payroll_date: Int
    $payroll_frequency: String
    $payroll_identifier: String
    $purchase_order_number: String
    $purchase_order_number_enabled: Boolean
    $invoice_percentage_enabled: Boolean
    $updated_at: timestamp!
  ) {
    update_organisation_payroll_settings(
      _set: {
        invoice_percentage_enabled: $invoice_percentage_enabled
        payroll_frequency: $payroll_frequency
        purchase_order_number_enabled: $purchase_order_number_enabled
        payroll_date: $payroll_date
        payroll_identifier: $payroll_identifier
        purchase_order_number: $purchase_order_number
        invoice_percentage: $invoice_percentage
        updated_at: $updated_at
      }
      where: { organisation_id: { _eq: $organisation_id } }
    ) {
      returning {
        payroll_frequency
        payroll_date
        payroll_identifier
        purchase_order_number
        invoice_percentage
      }
    }
  }
`;
