import { Box, Stack, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { formatCurrency } from "../../../../../../helpers";
import PropTypes from "prop-types";
import Loader from "../../../../../generic-components/loader";

const AmendmentDescriptionContent = ({ savings, isLoading, avcAmount }) => {
  const incomeTaxSavingSacrifice =
    savings?.plan_savings?.income_tax_saving_on_salary_sacrifice || 0;

  const grossValue = savings?.plan_savings?.gross_value || 0;

  const nicSavingSacrifice =
    savings?.plan_savings?.monthly_nic_saving_on_salary_sacrifice || 0;

  const updatedContributionAmount =
    incomeTaxSavingSacrifice + nicSavingSacrifice;
  return (
    <Box className="amend-descripton-box mt-18">
      {isLoading ? (
        <Box className="flex-box-center full-width mt-50 mb-50">
          <Loader />
        </Box>
      ) : (
        <>
          <Stack direction="row" alignItems="center">
            <ArrowRightIcon className="arrow-right" fontSize="large" />
            <Typography className="stepper-content">
              <strong>
                You want{" "}
                <span className="success-text">
                  {formatCurrency(avcAmount)}
                </span>{" "}
                to go into your Shared Cost AVC pot each payroll.
              </strong>
            </Typography>
          </Stack>
          <Typography className="ml-36 mt-10">
            Thanks to the{" "}
            <strong>
              <span className="moderate-primary-text">
                {formatCurrency(incomeTaxSavingSacrifice)}
              </span>
            </strong>{" "}
            saving in Income Tax and added benefit of the{" "}
            <strong>
              <span className="moderate-primary-text">
                {formatCurrency(nicSavingSacrifice)}
              </span>
            </strong>{" "}
            National Insurance contribution saving this will only cost you{" "}
            <strong>
              <span className="success-text">
                {formatCurrency(
                  parseFloat(avcAmount) - updatedContributionAmount
                )}
                !
              </span>
            </strong>
          </Typography>
          <Stack direction="row" alignItems="center" className="mt-10">
            <ArrowRightIcon className="arrow-right" fontSize="large" />
            <Typography className="stepper-content">
              <strong>
                Do you want it to cost you{" "}
                <span className="moderate-primary-text">
                  {formatCurrency(avcAmount)}
                </span>{" "}
                each payroll instead?
              </strong>
            </Typography>
          </Stack>
          <Typography className="mt-10 ml-36">
            Then you need to change your contribution amount to{" "}
            <strong>
              <span className="moderate-primary-text">
                {formatCurrency(grossValue)}
              </span>
            </strong>{" "}
            each payroll.
          </Typography>
          <Typography className="mt-10 ml-36">
            This means{" "}
            <strong>
              <span className="moderate-primary-text">
                {formatCurrency(grossValue)}
              </span>
            </strong>{" "}
            will be paid into your Shared Cost AVC pot, and we estimate it will
            only cost you{" "}
            <strong>
              <span className="success-text">{formatCurrency(avcAmount)}</span>
            </strong>
            .
          </Typography>
        </>
      )}
    </Box>
  );
};

AmendmentDescriptionContent.propTypes = {
  avcAmount: PropTypes.string,
  savings: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default AmendmentDescriptionContent;
