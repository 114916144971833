import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const SelectForm = ({ name, labelId, menuItems, label, width, icon }) => {
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl fullWidth className="mt-18">
      <InputLabel id={labelId}>
        <Stack direction="row" alignItems="center">
          {icon} {label}
        </Stack>
      </InputLabel>
      <Select
        labelId={labelId}
        name={name}
        value={selectedValue || ""}
        label={label}
        onChange={handleChange}
        sx={{ width: `${width || 100}%` }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {menuItems.map((options, index) => (
          <MenuItem key={index} value={options.value}>
            {options.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
SelectForm.propTypes = {
  name: PropTypes.string,
  labelId: PropTypes.string,
  menuItems: PropTypes.array,
  label: PropTypes.string,
  width: PropTypes.number,
  index: PropTypes.number,
  subField: PropTypes.string,
  icon: PropTypes.element,
};

export default SelectForm;
