import {
  personalValidationWithSteps,
  validationWithSteps,
} from "../constants/validator";

const useValidator = () => {
  const validateApplicationForm = (form, personalDetail) => {
    const errors = [];

    Object.keys(personalValidationWithSteps).forEach((key) => {
      if (!personalDetail[key]) {
        errors.push({ key, step: personalValidationWithSteps[key] });
      }
    });

    Object.keys(validationWithSteps).forEach((key) => {
      if (!form[key]) {
        errors.push({ key, step: validationWithSteps[key] });
      }
    });

    if (errors.length) {
      return errors[0];
    }

    return null;
  };

  return { validateApplicationForm };
};

export default useValidator;
