import React from "react";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import useStepper from "../../../hooks/useStepper";
import { useNavigate } from "react-router-dom";

const NavigationLinks = ({
  styleClass,
  link,
  name,
  trackingDetails,
  newTab = false,
  navigateLink = false,
  handleClick,
}) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();
  const navigate = useNavigate();

  const handleRedirect = (url, tab) => {
    if (navigateLink) {
      navigate(url);
    } else {
      window.open(url, tab);
    }
  };

  const tab = newTab ? "_blank" : "_self";

  return (
    <>
      <Button
        className={styleClass}
        rel="noreferrer"
        variant="text"
        onClick={(e) => {
          userTrackingMutation({
            variables: {
              ...trackingDetails,
              field_name: e?.target?.type || name || "",
              field_value: e?.target?.textContent?.toString() || "",
              avc_track_code: trackingCode,
            },
          });
          link && handleRedirect(`${link}`, `${tab}`);
          handleClick && handleClick();
        }}
      >
        {name}
      </Button>
    </>
  );
};

NavigationLinks.propTypes = {
  trackingDetails: PropTypes.object,
  styleClass: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
  newTab: PropTypes.bool,
  navigateLink: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default NavigationLinks;
