import { gql } from "@apollo/client";

export const getPlanReasons = gql`
  query getPlanReasons {
    plan_reasons(
      where: {
        _and: [{ reasons_type: { _eq: 0 } }, { enabled: { _eq: true } }]
      }
    ) {
      id
      name
    }
  }
`;
