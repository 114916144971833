import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import PropTypes from "prop-types";

const ChosenInvestmentTable = ({ values, investmentOptions }) => {
  const getInvestmentName = (id) => {
    return investmentOptions?.find((option) => option.value === id)?.name;
  };

  return (
    <Table className="table mt-18">
      <TableHead className="table-head">
        <TableRow>
          <TableCell align="center" className="table-cell">
            Investment
          </TableCell>
          <TableCell align="center" className="table-cell">
            Percentage
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.investmentChoices?.map((choice, index) => (
          <TableRow key={index}>
            <TableCell align="center" className="table-cell">
              {getInvestmentName(choice.name)}
            </TableCell>
            <TableCell align="center" className="table-cell">
              {choice.percentage}%
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ChosenInvestmentTable.propTypes = {
  values: PropTypes.object,
  investmentOptions: PropTypes.array,
};

export default ChosenInvestmentTable;
