import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import ContributionCalculator from "./ContributionCalculator";

const ContributionCalculatorModal = ({
  open,
  setOpen,
  sharedCostValues,
  applyButtonTitle,
  closeButtonTitle,
  convertButtonTitle,
  showApplyButton,
  showCloseButton,
  showConvertButton,
  islargeApplyButton,
}) => {
  const handleClose = () => setOpen(false);

  const initialValues = {
    income: sharedCostValues?.income || "",
    avcAmount: "",
    hours_per_week: sharedCostValues?.hours_per_week || "",
    age: "",
    isExistingPlanConversion: false,
    salarySacrifice: "",
    PTCheckbox: false,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="contribution-modal-background modal-background"
    >
      <ContributionCalculator
        isModalCalculator
        handleClose={handleClose}
        initialValues={initialValues}
        sharedCostValues={sharedCostValues}
        applyButtonTitle={applyButtonTitle}
        closeButtonTitle={closeButtonTitle}
        convertButtonTitle={convertButtonTitle}
        showApplyButton={showApplyButton}
        showCloseButton={showCloseButton}
        showConvertButton={showConvertButton}
        islargeApplyButton={islargeApplyButton}
      />
    </Modal>
  );
};

ContributionCalculatorModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  sharedCostValues: PropTypes.object,
  applyButtonTitle: PropTypes.string,
  closeButtonTitle: PropTypes.string,
  convertButtonTitle: PropTypes.string,
  showApplyButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showConvertButton: PropTypes.bool,
  islargeApplyButton: PropTypes.bool,
};

export default ContributionCalculatorModal;
