import { createSlice } from "@reduxjs/toolkit";

const organisationReducer = createSlice({
  name: "organisation",
  initialState: {
    organisation: {},
    user_roles_organisations: [],
  },
  reducers: {
    setOrganisation: (state, action) => {
      state.organisation = { ...state.organisation, ...action.payload };
    },
    setOrganisationId: (state, action) => {
      state.organisation = { ...state.organisation, id: action.payload };
    },
    setUserRolesOrganisations: (state, action) => {
      state.user_roles_organisations = action.payload;
    },
  },
});

const { actions, reducer } = organisationReducer;

export const { setOrganisation, setOrganisationId, setUserRolesOrganisations } =
  actions;

export default reducer;
