import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TRACKING_HOME_OPTIONS } from "../../../../constants";
import usePlan from "../../../../hooks/usePlan";
import useStepper from "../../../../hooks/useStepper";
import Loader from "../../../generic-components/loader";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import StaffBenefitsWidget from "../staff-benefits-page/StaffBenefitsWidget";
import HomeDescriptionContent from "./home-page-content/HomeDescriptionContent";
import HomePageTextSection from "./home-page-content/HomePageTextSection";

const HomePage = ({ user }) => {
  const { setSaveError, setErrorToast } = useStepper();
  const { state } = useLocation();
  const { isLatestActivePlan } = usePlan();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLatestActivePlan) {
      navigate("/manage_my_shared_cost_avc");
    } else {
      setIsLoading(false);
    }
  }, [isLatestActivePlan]);

  useEffect(() => {
    if (state?.warningMessage) {
      setSaveError({
        message: state?.warningMessage,
        overRideCustom: true,
      });
      setErrorToast(true);
    }
  }, [state]);

  return (
    <Box className="application-page-container">
      {isLoading ? (
        <Box className="mt-200 mb-200">
          <Loader />
        </Box>
      ) : (
        <>
          <SharedHeader heading={`Hello ${user.first_name || ""}`} />
          <Box className="home-content mb-100">
            <HomeDescriptionContent />
          </Box>
          <Box className="home-detail-section">
            <HomePageTextSection />
          </Box>
          <StaffBenefitsWidget trackingDetails={TRACKING_HOME_OPTIONS} />
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
  };
};

HomePage.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(HomePage);
