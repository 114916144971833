import { gql } from "@apollo/client";

export const updateUserNingiConsent = gql`
  mutation updateUserNingiConsent($id: Int!) {
    update_temp_plans(
      where: { id: { _eq: $id } }
      _set: { incomplete_journey_consent: true }
    ) {
      returning {
        id
      }
    }
  }
`;
