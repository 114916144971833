import { gql } from "@apollo/client";

export const startNingiJourneyForInvestment = gql`
  mutation StartNingiJourney($redirect_url: String!, $id: Int!) {
    startNingiJourney(redirect_url: $redirect_url) {
      journey_url
      session_id
    }
    update_temp_plans(
      where: { id: { _eq: $id } }
      _set: { incomplete_journey_consent: false }
    ) {
      returning {
        id
      }
    }
  }
`;
