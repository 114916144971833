import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { checkFieldError } from "../../../helpers";
import useStepper from "../../../hooks/useStepper";
import useUserEvent from "../../../hooks/useUserEvent";

const FormSelect = ({
  name,
  labelId,
  menuItems,
  value,
  updateRedux,
  label,
  width,
  index,
  subField,
  icon,
  handleCustomChange,
  trackingDetails,
  disabled,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
  } = useFormikContext();
  const { trackingCode } = useStepper();

  const selectedValue = useMemo(() => {
    if (value) {
      return value;
    }

    if (!index && index !== 0) {
      return values[name];
    }

    return values[name][index][subField];
  }, [values, menuItems, value]);

  const menuFilterItems = useMemo(() => {
    if (!(values && values[name])) {
      return menuItems;
    }

    return menuItems.filter(
      (item) =>
        item.value === selectedValue ||
        !(
          Array.isArray(values[name]) &&
          values[name].some((addOptions) => addOptions.name === item.value)
        )
    );
  }, [values, menuItems]);

  const updateFormikDates = () => {
    if (name !== "provider") return;

    setFieldValue("triggeredTheMpaa", false);
    setFieldValue("accessedPensionBtn", false);
    setFieldValue(
      "MPAAdate",
      values.triggeredTheMpaa && values.MPAAdate ? values.MPAAdate : ""
    );
    setFieldValue(
      "flexiblyAccessedDate",
      values.accessedPensionBtn && values.flexiblyAccessedDate
        ? values.flexiblyAccessedDate
        : ""
    );
  };

  const { userTrackingMutation } = useUserEvent();

  const findItemBySelectedValue = (targetedValue) => {
    const foundItem = menuItems.find(
      ({ value }) => value === parseInt(targetedValue)
    );
    return foundItem ? foundItem.name : targetedValue;
  };

  const handleUserEvent = (event, fieldName, fieldValue) => {
    if (!fieldName && !fieldValue) {
      const { name, value } = event.target || {};
      fieldName = name;
      fieldValue = value;
    }
    const selectedName = findItemBySelectedValue(fieldValue);
    if (selectedName && typeof selectedName !== "object") {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: fieldName || "Select",
          field_value: selectedName ? selectedName?.toString() : "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  return (
    <FormControl
      fullWidth
      className="mt-18 form-control-select"
      error={checkFieldError(touched, errors, name, index, subField)}
    >
      <InputLabel
        id={labelId}
        error={checkFieldError(touched, errors, name, index, subField)}
      >
        <Stack direction="row" alignItems="center">
          {icon} {label}
        </Stack>
      </InputLabel>
      <Select
        disablePortal={true}
        disabled={disabled}
        labelId={labelId}
        name={index || index === 0 ? `${name}[${index}].${subField}` : name}
        value={selectedValue || ""}
        label={label}
        color="mmm"
        onOpen={($event) => {
          handleUserEvent($event, name, values[name]);
        }}
        onChange={($event) => {
          handleChange($event);
          updateFormikDates();
          updateRedux &&
            updateRedux(name, $event.target.value, setFieldValue, isValid);
          handleCustomChange &&
            handleCustomChange(
              name,
              $event.target.value,
              setFieldValue,
              isValid
            );
        }}
        onBlur={($event) => {
          handleUserEvent($event);
          handleBlur($event);
          updateRedux &&
            updateRedux(name, $event.target.value, setFieldValue, isValid);
        }}
        error={checkFieldError(touched, errors, name, index, subField)}
        helperText={checkFieldError(touched, errors, name, index, subField)}
        sx={{ width: `${width || 100}%` }}
        MenuProps={{
          disableScrollLock: false,
        }}
      >
        {menuFilterItems.map((options, index) => (
          <MenuItem key={index} value={options.value}>
            {options.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        error={checkFieldError(touched, errors, name, index, subField)}
      >
        {checkFieldError(touched, errors, name, index, subField)}
      </FormHelperText>
    </FormControl>
  );
};
FormSelect.propTypes = {
  name: PropTypes.string,
  labelId: PropTypes.string,
  menuItems: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  index: PropTypes.number,
  subField: PropTypes.string,
  icon: PropTypes.element,
  updateRedux: PropTypes.func,
  handleCustomChange: PropTypes.func,
  trackingDetails: PropTypes.object,
  disabled: PropTypes.bool,
};

export default FormSelect;
