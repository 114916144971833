import React from "react";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStepper from "../../../hooks/useStepper";

const ReactLinks = ({ styleClass, link, name, trackingDetails }) => {
  const navigate = useNavigate();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleRedirect = (url) => navigate(url);

  return (
    <>
      <Button
        className={styleClass}
        rel="noreferrer"
        variant="text"
        onClick={(e) => {
          userTrackingMutation({
            variables: {
              ...trackingDetails,
              field_name: e?.target?.type || "Button",
              field_value: e?.target?.textContent?.toString() || "",
              avc_track_code: trackingCode,
            },
          });
          handleRedirect(`${link}`);
        }}
      >
        {name}
      </Button>
    </>
  );
};

ReactLinks.propTypes = {
  trackingDetails: PropTypes.object,
  styleClass: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string,
};

export default ReactLinks;
