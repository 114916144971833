import React, { useEffect } from "react";
import Zendesk from "react-zendesk";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import useStepper from "../../../hooks/useStepper";
import { useMutation } from "@apollo/client";
import { logoutUser } from "../../../graphql/mutations/logoutUser";
import { legacyAppRedirection, removeCookies } from "../../../helpers";
import { getCookie, setCookie } from "../../../utils/cookies";

const ZENDESK_KEY = "11b49c5b-c889-447f-8850-950cb34365f3";

const Chatbtn = ({ trackingDetails }) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode, personalDetails } = useStepper();
  const [logout] = useMutation(logoutUser);
  let logoutTimeoutId;

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: element?.current?.textContent || "Chat",
        field_value: element?.current?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });

  const liveChatTrigger = () => {
    window?.zE && window?.zE("messenger", "show");
    window?.zE && window?.zE("messenger", "open");
  };

  const hendleAfterLoad = () => {
    window?.zE && window?.zE("messenger", "close");

    window?.zE &&
      window?.zE("messenger:on", "open", () => {
        handleUserEvent();
      });
  };

  const logoutUserOnInactivity = () => {
    clearTimeout(logoutTimeoutId);
    setCookie("is-active-on-other-platform_avc", true);
    console.log("[info]", "inf");
    logoutTimeoutId = setTimeout(() => {
      const isActiveOnOtherPlatform =
        getCookie("is-active-on-other-platform") === true;

      if (isActiveOnOtherPlatform) {
        logoutUserOnInactivity();
        setCookie("is-active-on-other-platform_avc", false);
        return;
      }

      logout({
        onCompleted: (data) => {
          removeCookies();
          window.location.href = data?.destroy_session?.logout_url;
        },
        onError: () => {
          removeCookies();
          legacyAppRedirection(personalDetails?.sub_domain, "/");
        },
      });
    }, (process.env.REACT_APP_INACTIVITY_LOGOUT_TIMEOUT || 2) * 60 * 60 * 1000);
  };

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        liveChatTrigger();
      }, process.env.REACT_APP_INACTIVITY_TIMEOUT || 180000);
      logoutUserOnInactivity();
    };

    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [personalDetails]);

  return (
    <>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => hendleAfterLoad()}
      />
    </>
  );
};

Chatbtn.propTypes = {
  trackingDetails: PropTypes.object,
};

export default Chatbtn;
