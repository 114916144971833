import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import FormSelect from "../../../../generic-components/form-select";
import PropTypes from "prop-types";
import TextInput from "../../../../generic-components/input-text";

const AmendmentReasons = ({
  total_avc_amount_requested,
  amendmentReasons,
  handleOtherReason,
}) => {
  const { values } = useFormikContext();
  const isIncreasingAmount = useMemo(() => {
    return Number(total_avc_amount_requested) < Number(values.avcAmount);
  }, [values.avcAmount]);

  const isEqual = useMemo(() => {
    return Number(total_avc_amount_requested) === Number(values.avcAmount);
  }, [values.avcAmount]);

  const filteredAmendmentReasons = useMemo(() => {
    if (isEqual) return amendmentReasons;

    return amendmentReasons.filter((reason) =>
      isIncreasingAmount ? reason.increase : !reason.increase
    );
  }, [isEqual, isIncreasingAmount, amendmentReasons]);

  return (
    <>
      <FormSelect
        name="amendmentReason"
        labelId="amendmentReason"
        label="Tell us why you're amending your contribution amount"
        menuItems={filteredAmendmentReasons}
        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
        handleCustomChange={(_, value, setFieldValue) => {
          handleOtherReason(null, value, setFieldValue);
        }}
      />
      {values.amendmentReason === "Other – please state below" && (
        <TextInput
          id="otherReason"
          name="otherReason"
          label="Please state your reason below"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          value={values.otherReason || ""}
        />
      )}
    </>
  );
};

AmendmentReasons.propTypes = {
  total_avc_amount_requested: PropTypes.number,
  amendmentReasons: PropTypes.array,
  handleOtherReason: PropTypes.func,
};

export default AmendmentReasons;
