import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { TOKEN_EXPIRES_MESSAGE } from "../constants";

export const ERROR_DEFAULT_MESSAGE =
  "Something went wrong. Please refresh screen.";
export function useToast() {
  function showToast(
    message,
    open,
    setOpen,
    type = "success",
    overRideCustom = false
  ) {
    if (
      type === "error" &&
      ["Token has expired", "Token is invalid"].includes(message)
    ) {
      message = TOKEN_EXPIRES_MESSAGE;
    } else if (type === "error" && !overRideCustom) {
      message = ERROR_DEFAULT_MESSAGE;
    }

    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Snackbar
        open={open}
        autoHideDuration={8000}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
      >
        <Alert severity={type} sx={{ width: "100%" }} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return {
    showToast,
  };
}
