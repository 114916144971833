import React, { useState } from "react";
import { startNingiJourney } from "../../../graphql/mutations/startNingiJourney";
import { useMutation } from "@apollo/client";
import LoadingButton from "../../generic-components/button";
import { TRACKING_NINGI_OPTIONS } from "../../../constants";
import useStepper from "../../../hooks/useStepper";
import { Typography, Stack, Box } from "@mui/material";

const MyInvestmentAdivceWithPlansNoNingi = () => {
  const applicationOrigin = window.location.origin;
  const [isLoading, setIsLoading] = useState(false);
  const { setSaveError, setErrorToast, setFullScreenLoading } = useStepper();

  const [redirectNingiJourney] = useMutation(startNingiJourney);

  const handleRedirectionNingiJourney = async () => {
    setIsLoading(true);
    setFullScreenLoading(true);
    redirectNingiJourney({
      variables: {
        redirect_url: `${applicationOrigin}/redirect-avc_investment_guide`,
      },
      onCompleted: (data) => {
        window.location.href = data.startNingiJourney.journey_url;
      },
      onError: (error) => {
        setFullScreenLoading(false);
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  return (
    <Stack
      direction="column"
      className="mt-18 changeInvestmentAdvice-container"
    >
      <Box className="content-container">
        <Typography className="mb-18">
          Thanks to your employer, you have access to investment advice at no
          extra cost to you. This advice is provided by our partners,
          Westminster Wealth.
        </Typography>
        <Typography className="mb-18">
          As an FCA (Financial Conduct Authority) regulated advice service, this
          journey is detailed and thorough. Please allow between 10-15 minutes
          to complete this.
        </Typography>
        <Typography className="mb-30">
          By clicking the button below, you agree to the personal data you’ve
          already given to My Money Matters (when you registered with us) now
          also being processed by Westminster Wealth to provide you with this
          service.
        </Typography>
      </Box>
      <LoadingButton
        buttonTitle="Click here to access investment advice"
        loading={isLoading}
        trackingDetails={TRACKING_NINGI_OPTIONS}
        handleClick={handleRedirectionNingiJourney}
        styleClass="ningi-journey-button"
      />
    </Stack>
  );
};

export default MyInvestmentAdivceWithPlansNoNingi;
