import { createSlice } from "@reduxjs/toolkit";

const schemeReducer = createSlice({
  name: "scheme",
  initialState: {
    scheme: {},
  },
  reducers: {
    setScheme: (state, action) => {
      state.scheme = action.payload;
    },
  },
});

const { actions, reducer } = schemeReducer;

export const { setScheme } = actions;

export default reducer;
