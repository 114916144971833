import { useMutation } from "@apollo/client";
import { userTrackingevent } from "../graphql/mutations/userEventTrack";

const useUserEvent = () => {
  const [userTrackingMutation] = useMutation(userTrackingevent);

  return { userTrackingMutation };
};

export default useUserEvent;
