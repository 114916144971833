import { Box, Typography, useMediaQuery } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import SwitchButton from "../../../../generic-components/switch-button";
import {
  pensionAgeOptions,
  TRACKING_NAF_OPTIONS,
} from "../../../../../constants";
import DateInput from "../../../../generic-components/date-input";
import useUserEvent from "../../../../../hooks/useUserEvent";
import useStepper from "../../../../../hooks/useStepper";
import { findItemBySelectedValue } from "../../../../../helpers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import FormSelect from "../../../../generic-components/form-select";

const PreviousAvcAndPensionsInfoStep1 = ({ isNHSApplication, pensionType }) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const { values, handleBlur, setFieldValue } = useFormikContext();
  const { trackingCode } = useStepper();
  const { userTrackingMutation } = useUserEvent();

  const setButtons = (value, setFieldValue) => {
    if (value) {
      setFieldValue("combiningWithThisEmployer", false);
      setFieldValue("keepSeparateWithEmployer", false);
    }
  };

  const setDate = (value, setFieldValue) => {
    if (value) {
      setFieldValue("MPAAdate", "");
    }
  };

  const handleUserEvent = (element) => {
    const { name, textContent } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: textContent?.toString() || "",
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <Box className="mb-50">
      {!isNHSApplication && (
        <>
          <Typography className="mt-30 stepper-content">
            Do you have an AVC plan with Prudential from a previous{" "}
            {pensionType}
            {""} employer?
          </Typography>
          <SwitchButton
            name="previousEmployerPlan"
            value={values.previousEmployerPlan}
            setValue={setButtons}
            trackingDetails={TRACKING_NAF_OPTIONS}
          />
          {values.previousEmployerPlan && (
            <>
              <Typography className="mt-30 stepper-content">
                Are you combining your {pensionType} from your previous employer
                {""} with this employer?
              </Typography>
              <SwitchButton
                name="combiningWithThisEmployer"
                value={values.combiningWithThisEmployer}
                trackingDetails={TRACKING_NAF_OPTIONS}
              />
              <Typography className="mt-30 stepper-content">
                Are you keeping your AVC plan with your previous employer
                separate to your AVC plan with this employer?
              </Typography>
              <SwitchButton
                name="keepSeparateWithEmployer"
                value={values.keepSeparateWithEmployer}
                trackingDetails={TRACKING_NAF_OPTIONS}
              />
            </>
          )}
        </>
      )}
      <Typography className="mt-30 stepper-content">
        Before you can submit your application, we need to know if you've taken
        benefits from other pensions. This is because you may have triggered the
        Money Purchase Annual Allowance (MPAA) when you took these benefits.
        Your pension scheme or provider will have told you if you've done this.
        To find out more, please read the 'Important information about pensions
        allowances' leaflet in your joining pack or at{" "}
        <a
          href="https://www.mandg.com/pru/workplace-pensions/employees/public-sector-avc-schemes/local-gov"
          className="web-link"
          target="_blank"
          rel="noreferrer"
          name="link_mandg"
          onClick={handleUserEvent}
        >
          www.mandg.com
        </a>{" "}
        or speak to your financial adviser.
      </Typography>
      <Typography className="mt-30 stepper-content">
        Has your pension scheme or provider told you that you’ve triggered the
        MPAA?
      </Typography>
      <SwitchButton
        name="triggeredTheMpaa"
        value={values.triggeredTheMpaa}
        setValue={setDate}
        trackingDetails={TRACKING_NAF_OPTIONS}
      />
      {values.triggeredTheMpaa && (
        <DateInput
          id="MPAAdate"
          name="MPAAdate"
          label="MPAA Date"
          minDate={moment("2015-01-01")}
          maxDate={moment()}
          trackingDetails={TRACKING_NAF_OPTIONS}
          value={values.MPAAdate}
          handleChangeValue={(value) => {
            setFieldValue("MPAAdate", moment(value).format("YYYY-MM-DD"));
          }}
          handleBlurValue={handleBlur}
          styleClass="stepper-field"
        />
      )}
      {isNHSApplication && (
        <>
          <Typography className="mt-30 stepper-content">
            Select your normal retirement age applicable to the NHS scheme*
          </Typography>
          <FormSelect
            name="pensionAge"
            labelId="pensionAge"
            menuItems={pensionAgeOptions}
            label="Pension Age *"
            width={mobile ? 100 : 70}
            trackingDetails={TRACKING_NAF_OPTIONS}
          />
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isNHSApplication: isNHSApplication(state),
    pensionType: state.multiForm.pensionType,
  };
};

PreviousAvcAndPensionsInfoStep1.propTypes = {
  isNHSApplication: PropTypes.bool,
  pensionType: PropTypes.string,
};

export default connect(mapStateToProps)(PreviousAvcAndPensionsInfoStep1);
