import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { checkFieldError, findItemBySelectedValue } from "../../../helpers";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";

const FormikCheckbox = ({
  name,
  label,
  trackingDetails,
  customHandleChange,
  styleClass,
}) => {
  const { values, errors, touched, handleChange } = useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: fieldName || "",
        field_value: checked
          ? `Selected ${fieldName}`
          : `Unselected ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <FormGroup className="checkbox-container mt-18 ml-10">
      <FormControlLabel
        className="form-control-label"
        control={
          <Checkbox
            checked={values[name]}
            className="checkbox"
            onChange={(e) => {
              handleChange(e);
              e.target.checked && customHandleChange && customHandleChange();
            }}
            onClick={handleUserEvent}
          />
        }
        name={name}
        id={name}
        type="checkbox"
        value={values[name]}
        error={checkFieldError(touched, errors, name)}
        label={
          <Typography
            className={`stepper-content ml-10 ${styleClass}`}
            dangerouslySetInnerHTML={{ __html: label }}
          ></Typography>
        }
      />
      <FormHelperText error={checkFieldError(touched, errors, name)}>
        {checkFieldError(touched, errors, name)}
      </FormHelperText>
    </FormGroup>
  );
};
FormikCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  styleClass: PropTypes.string,
  trackingDetails: PropTypes.object,
  customHandleChange: PropTypes.func,
};
export default FormikCheckbox;
