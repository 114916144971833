import { gql } from "@apollo/client";

export const updateFundDetails = gql`
  mutation updateFundDetails(
    $id: Int
    $flexibly_accessed_any_pensions: Boolean
    $lifestyling_option: Boolean
    $pension_fund_id: Int
    $step_number: Int!
    $provider_id: Int
    $retirement_age: Int
    $pension_age: String
    $updated_at: timestamp!
    $choosen_relevant_investment_option: Boolean!
    $having_previous_employer_plan: Boolean!
    $combining_with_this_employer: Boolean!
    $keep_separate_with_employer: Boolean!
    $triggered_the_mpaa: Boolean!
    $mpaa_date: date
    $flexibly_accessed_date: date
  ) {
    update_temp_plans(
      where: { id: { _eq: $id } }
      _set: {
        flexibly_accessed_any_pensions: $flexibly_accessed_any_pensions
        lifestyling_option: $lifestyling_option
        pension_fund_id: $pension_fund_id
        provider_id: $provider_id
        retirement_age: $retirement_age
        pension_age: $pension_age
        updated_at: $updated_at
        step_number: $step_number
        choosen_relevant_investment_option: $choosen_relevant_investment_option
        having_previous_employer_plan: $having_previous_employer_plan
        combining_with_this_employer: $combining_with_this_employer
        keep_separate_with_employer: $keep_separate_with_employer
        triggered_the_mpaa: $triggered_the_mpaa
        mpaa_date: $mpaa_date
        flexibly_accessed_date: $flexibly_accessed_date
      }
    ) {
      returning {
        id
        flexibly_accessed_any_pensions
        lifestyling_option
        pension_fund_id
        provider_id
        show_ningi_journey_consent
        ningi_journey {
          id
          journey_status
          journey_url
          recommendation_changed
        }
        retirement_age
        pension_age
        choosen_relevant_investment_option
        flexibly_accessed_date
        step_number
      }
    }
  }
`;
