import axios from "axios";
import { renewToken } from "../graphql/queries/renewToken";
import { removeAllCookies } from "../helpers";
import { getCookie, removeCookie, setCookie } from "../utils/cookies";
import store from "../store";
import * as Sentry from "@sentry/react";

export const refreshToken = async (uri, options) => {
  let refreshingPromise = null;

  if (!refreshingPromise) {
    refreshingPromise = await axios({
      url: process.env.REACT_APP_HASURA_API_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getCookie("refresh_token")}`,
        "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET_KEY,
      },
      data: { query: renewToken },
    });
    const { data, errors } = refreshingPromise.data;
    if (errors?.length && process.env.REACT_APP_ENVIRONMENT !== "local") {
      removeAllCookies();
      removeCookie("user_session");
      removeCookie("refresh_token");
      removeCookie("organisation_id");
      removeCookie("scheme_id");
      window.location.href = `${process.env.REACT_APP_AVCWISE_URL}/users/sign_in?redirection=${window.location.pathname}`;
    } else if (data) {
      const tokens = data.renew_token;
      setCookie("user_session", tokens.user_session);
      setCookie("refresh_token", tokens.refresh_token);
      options.headers.authorization = `Bearer ${tokens.user_session}`;
      const { data: renewedJson } = await axios({
        url: uri,
        ...options,
        data: options.body,
      });
      let result = {};
      result.text = () =>
        new Promise(function (resolve) {
          resolve(JSON.stringify(renewedJson));
        });
      return result;
    }
  }
};

export const customFetch = async (uri, options, retryCount = 0) => {
  try {
    const { data: json } = await axios({
      url: uri,
      ...options,
      data: options.body,
    });

    const errors =
      Array.isArray(json?.errors) && json.errors.length
        ? json.errors.find((error) => error)
        : {};

    if (["Token has expired", "Token is invalid"].includes(errors.message)) {
      const result = await refreshToken(uri, options);
      if (!result) {
        let result = {};
        result.text = () =>
          new Promise(function (resolve) {
            resolve(JSON.stringify(json));
          });
        return result;
      }
      return result;
    }

    let result = {};
    result.text = () =>
      new Promise(function (resolve) {
        resolve(JSON.stringify(json));
      });
    return result;
  } catch (error) {
    if (error.code === "ERR_NETWORK" && !retryCount) {
      return customFetch(uri, options, 1);
    }

    console.log(
      "Something went wrong while fetching resposne",
      error?.response ? JSON.stringify(error?.response) : error
    );

    if (error.code === "ERR_CANCELED") {
      return;
    }

    captureSentryError(error, options);
  }
};

function captureSentryError(error, options = {}) {
  const userDetail = store.getState().user?.user || {};
  const formDetails = store.getState().form?.form || {};
  const scheme = store.getState().scheme?.scheme || {};

  const customizedTags = {
    temp_plan_id: formDetails?.id,
    step_number: formDetails?.step_number,
    organisation_id: userDetail.organisation_id,
    scheme_id: scheme.scheme_id,
    user_id: userDetail.id,
    user_email: userDetail.email,
  };

  const extraDetails = {
    payload: options.body,
    user_session: getCookie("user_session"),
    refresh_token: getCookie("refresh_token"),
    customFetch: true,
    ...customizedTags,
  };

  for (const key in customizedTags) {
    const value = customizedTags[key];
    Sentry.setTag(`${key}`, value);
  }

  Sentry.captureException(error, {
    extra: extraDetails,
  });
}
