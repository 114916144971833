import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../../constants";
import { cancelMyPlan } from "../../../../../../graphql/mutations/manageMySharedCost";
import useStepper from "../../../../../../hooks/useStepper";
import usePlan from "../../../../../../hooks/usePlan";
import { planCancellationValidationSchema } from "../../../../../../schema";
import LoadingButton from "../../../../../generic-components/button";
import FormSelect from "../../../../../generic-components/form-select";
import TextInput from "../../../../../generic-components/input-text";
import Loader from "../../../../../generic-components/loader";
import { otherOrganisationOptions } from "../../../../../../constants/reasons";
import ConfirmationDialogue from "./dialogue/ConfirmationDialogue";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../../../reducers/userReducer";
import { getPlanCancellationReasons } from "../../../../../../graphql/queries/manageMySharedCost";
import { useEffect } from "react";

const INITIAL_CONSTANT_VALUES = {
  otherReason: "",
  cancellationReason: "",
  isCancellationReasons: true,
  isMovingToAnotherOrganisation: "",
  otherOrganisationName: "",
};

const CancellationReasons = ({ setCancel, plan }) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const { setErrorToast, setSaveError, setPersonalDetails, personalDetails } =
    useStepper();
  const { setLatestActivePlan, setPlans } = usePlan();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState("");
  const [cancellationReasons, setCancellationReasons] = useState([]);

  const [cancelMySharedCostAVCPlan, { loading: cancelMyPlanLoading }] =
    useMutation(cancelMyPlan);
  const [fetchCancellationReasons, { loading: reasonsLoading }] = useLazyQuery(
    getPlanCancellationReasons
  );

  const handleCancellationReasons = () => {
    fetchCancellationReasons({
      variables: {
        plan_id: parseInt(plan.id),
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const reasons =
          data?.cancellation_reasons_options?.cancellation_reasons;
        const formatReasons = reasons.map((reason) => ({
          id: reason.id,
          name: reason.description,
          value: reason.title,
        }));
        setCancellationReasons(formatReasons);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleCancelPlan = (values) => {
    const otherReason =
      values.cancellationReason === "leaving_or_left_employment" &&
      values.isMovingToAnotherOrganisation === "yes"
        ? values.otherOrganisationName
        : values.otherReason;
    const cancellationReasonId = cancellationReasons.find(
      (reason) => reason.value === values.cancellationReason
    )?.id;

    cancelMySharedCostAVCPlan({
      variables: {
        plan_id: parseInt(plan.id),
        cancellation_reason_id: parseInt(cancellationReasonId),
        cancellation_reason_other: otherReason || "",
      },
      onCompleted: () => {
        setPlans([]);
        setLatestActivePlan(null);
        setPersonalDetails({
          ...personalDetails,
          latest_active_plan: null,
          latest_temp_plan: null,
        });
        dispatch(setUser({ latest_active_plan: null, latest_temp_plan: null }));
        navigate("/manage_my_shared_cost_avc");
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleMovingToOtherOrganisation = (_, value, setFieldValue) => {
    setFieldValue("isMovingToAnotherOrganisation", value);
  };

  const handleOtherReason = (_, value, setFieldValue) => {
    setShowDialog(value);
    if (value !== "other_reason") {
      setFieldValue("otherReason", "");
      return;
    }
  };

  useEffect(() => {
    if (plan.id) {
      handleCancellationReasons();
    }
  }, [plan]);

  return (
    <>
      {reasonsLoading ? (
        <Box className="flex-box-center full-width mt-100">
          <Loader />
        </Box>
      ) : (
        <>
          <Typography className="mt-18 padding-content-heading">
            Please let us know why you're cancelling
          </Typography>
          <Typography className="mt-18 padding-content">
            We're sorry to see you go, and we hope to be able to help you again
            in the future.
          </Typography>
          <Typography className="padding-content">
            You can re-apply to join the scheme again at any time.
          </Typography>
          <Box className="padding-content full-width">
            <Formik
              initialValues={INITIAL_CONSTANT_VALUES}
              validationSchema={planCancellationValidationSchema}
              onSubmit={(values) => {
                handleCancelPlan(values);
              }}
            >
              {({ values, handleSubmit, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <FormSelect
                    name="cancellationReason"
                    labelId="cancellationReason"
                    label="Cancellation Reasons"
                    width={mobile || tablet ? 100 : 50}
                    menuItems={cancellationReasons}
                    trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                    handleCustomChange={(_, value, setFieldValue) => {
                      handleOtherReason(null, value, setFieldValue);
                    }}
                  />
                  {values.cancellationReason === "other_reason" && (
                    <TextInput
                      id="otherReason"
                      name="otherReason"
                      label="Other Reason"
                      styleClass={
                        mobile || tablet ? "full-width" : "half-width"
                      }
                      trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                      value={values.otherReason || ""}
                    />
                  )}
                  <Stack justifyContent="flex-start" direction="row">
                    <Stack
                      direction={{ md: "row", sm: "column", xs: "column" }}
                      justifyContent="space-between"
                      spacing={2}
                      className="mt-18"
                    >
                      <LoadingButton
                        buttonTitle="Do not cancel my Shared Cost AVC plan"
                        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                        handleClick={() => {
                          navigate("/manage_my_shared_cost_avc");
                          setCancel(false);
                        }}
                        styleClass="service-button disable-text-transform service-border large-service-button"
                        disabled={cancelMyPlanLoading}
                      />
                      <LoadingButton
                        buttonTitle="Cancel my Shared Cost AVC plan"
                        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                        handleClick={handleSubmit}
                        styleClass="service-button disable-text-transform confirmation-warning"
                        disabled={cancelMyPlanLoading}
                        loading={cancelMyPlanLoading}
                      />
                    </Stack>
                  </Stack>
                  <ConfirmationDialogue
                    open={showDialog === "leaving_or_left_employment"}
                    cancelButtonTitle={"Select a different cancellation reason"}
                    acceptButtonTitle={"I still want to cancel"}
                    showAction={values.isMovingToAnotherOrganisation !== ""}
                    onCloseDialog={() => {
                      resetForm(INITIAL_CONSTANT_VALUES);
                      setShowDialog("");
                    }}
                    onClose={() => {
                      resetForm(INITIAL_CONSTANT_VALUES);
                      setShowDialog("");
                    }}
                    handleOnAccept={handleSubmit}
                    loading={cancelMyPlanLoading}
                  >
                    <Typography>
                      Will you be moving to another Local Government Pension
                      Scheme organisation?
                    </Typography>
                    <FormSelect
                      name="isMovingToAnotherOrganisation"
                      labelId="isMovingToAnotherOrganisation"
                      label="Please choose..."
                      width={50}
                      menuItems={otherOrganisationOptions}
                      trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                      handleCustomChange={(_, value, setFieldValue) => {
                        handleMovingToOtherOrganisation(
                          null,
                          value,
                          setFieldValue
                        );
                      }}
                    />
                    {values.isMovingToAnotherOrganisation === "yes" && (
                      <>
                        <Typography className="mt-18">
                          If you wish to remain a member of a Shared Cost AVC
                          scheme at your new place of employment, please tell us
                          the name of the organisation you are joining and we
                          will explore the opportunity of launching a Shared
                          Cost AVC scheme with them too.
                        </Typography>
                        <TextInput
                          id="otherOrganisationName"
                          name="otherOrganisationName"
                          label="Enter name of the organisation"
                          trackingDetails={
                            TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                          }
                          value={values.otherOrganisationName || ""}
                        />
                      </>
                    )}
                  </ConfirmationDialogue>
                  <ConfirmationDialogue
                    open={
                      showDialog === "absent_from_work_with_insufficient_pay"
                    }
                    cancelButtonTitle={"I want to amend my amount"}
                    acceptButtonTitle={"I still want to cancel"}
                    onCloseDialog={() => {
                      resetForm(INITIAL_CONSTANT_VALUES);
                      setShowDialog("");
                    }}
                    onClose={() => navigate("/amend_my_shared_cost_avc")}
                    handleOnAccept={handleSubmit}
                    loading={cancelMyPlanLoading}
                  >
                    <Typography>
                      It may be more efficient for you to amend your
                      contribution to a lower amount instead.
                      <br />
                      You can contribute as little as £2 to the scheme, which
                      will then save you having to go through the application
                      process again if you wish to carry on paying into your
                      Shared Cost AVC pot in the future.
                      <br />
                      To amend your Shared Cost AVC amount, please click the{" "}
                      <strong>I want to amend my amount</strong> button.
                    </Typography>
                  </ConfirmationDialogue>

                  <ConfirmationDialogue
                    open={showDialog === "cannot_afford"}
                    cancelButtonTitle={"I want to amend my amount"}
                    acceptButtonTitle={"I still want to cancel"}
                    onCloseDialog={() => {
                      resetForm(INITIAL_CONSTANT_VALUES);
                      setShowDialog("");
                    }}
                    onClose={() => navigate("/amend_my_shared_cost_avc")}
                    handleOnAccept={handleSubmit}
                    loading={cancelMyPlanLoading}
                  >
                    <Typography>
                      It may be more efficient for you to amend your
                      contribution to a lower amount instead.
                    </Typography>
                    <br />
                    <Typography>
                      You can contribute as little as £2 to the scheme, which
                      will then save you having to go through the application
                      process again if you wish to carry on paying into your
                      Shared Cost AVC pot in the future.
                    </Typography>
                    <br />
                    <Typography>
                      To amend your Shared Cost AVC amount, please click the{" "}
                      <strong>I want to amend my amount</strong> button.
                    </Typography>
                  </ConfirmationDialogue>

                  <ConfirmationDialogue
                    open={showDialog === "dont_want_to"}
                    maxWidthAllowed
                    cancelButtonTitle={
                      "I want to remain in the Shared Cost AVC scheme and benefit from the cost-efficient retirement savings"
                    }
                    acceptButtonTitle={"I still want to cancel"}
                    onCloseDialog={() => {
                      resetForm(INITIAL_CONSTANT_VALUES);
                      setShowDialog("");
                    }}
                    onClose={() => navigate("/amend_my_shared_cost_avc")}
                    handleOnAccept={handleSubmit}
                    loading={cancelMyPlanLoading}
                  >
                    <Typography>
                      By cancelling your Shared Cost AVC you will be missing out
                      on valuable, cost-efficient savings towards your
                      retirement. Every contribution you make receives Income
                      Tax National Insurance Contributions (NICs) and relief,
                      meaning you are making substantial savings on the way in
                      to your Shared Cost AVC pot.
                    </Typography>
                    <br />
                    <Typography>
                      A £100 contribution only costs a basic rate taxpayer in
                      England or Wales £72.08!
                    </Typography>
                  </ConfirmationDialogue>
                </Form>
              )}
            </Formik>
          </Box>
        </>
      )}
    </>
  );
};

CancellationReasons.propTypes = {
  setCancel: PropTypes.array,
  plan: PropTypes.object,
};

export default CancellationReasons;
