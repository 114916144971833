import isEmpty from "lodash.isempty";
import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { userRoles } from "../../../constants";
import useStepper from "../../../hooks/useStepper";
import Loader from "../../generic-components/loader";

const EmployeeRoutes = () => {
  const { personalDetails } = useStepper();
  const [isAuthorisedUser, setIsAuthorisedUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleAuthorisation = () => {
    if (
      !isEmpty(personalDetails) &&
      !personalDetails.backend_user &&
      !personalDetails?.current_organisation_roles?.includes(
        userRoles.ORGANISATION_EMPLOYEE
      )
    ) {
      setIsAuthorisedUser(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      handleAuthorisation();
    }
  }, [personalDetails]);

  return (
    <>
      {isLoading ? (
        <Loader className="mt-250 mb-250" />
      ) : isAuthorisedUser ? (
        <Outlet />
      ) : (
        <Navigate to="/404" />
      )}
    </>
  );
};

export default EmployeeRoutes;
