import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import HomePageButtons from "./HomePageButtons";

const HomePageApplyButtonModal = ({ open, setOpen, showCloseButton }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="home-modal-background modal-button-background"
    >
      <HomePageButtons
        handleClose={handleClose}
        showCloseButton={showCloseButton}
      />
    </Modal>
  );
};

HomePageApplyButtonModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

export default HomePageApplyButtonModal;
