import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import PlanDetailsComponent from "./PlanDetails";
import PlanGuidanceComponent from "./PlanGuidance";
import planStatusBg from "../../../../../assets/images/bg.png";
import PropTypes from "prop-types";
import CustomerServiceFooter from "./CustomerServiceFooter";
import usePlan from "../../../../../hooks/usePlan";
import StaffBenefitsWidget from "../../staff-benefits-page/StaffBenefitsWidget";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import { connect } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";
import { getSchemeDetails } from "../../../../../graphql/queries/manageMySharedCost";
import { useLazyQuery } from "@apollo/client";
import TermsAndFaqs from "./TermsAndFaqs";

const ManageMySharedCostWithPlans = ({ plans, scheme }) => {
  const { isLatestActivePlan } = usePlan();
  const { setErrorToast, setSaveError } = useStepper();
  const [schemeDocs, setSchemeDocs] = useState(null);
  const [fetchSchemeDocs] = useLazyQuery(getSchemeDetails);

  const getSchemeDocs = async () => {
    fetchSchemeDocs({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        scheme_id: scheme.scheme_id,
      },
      onCompleted: (data) => {
        setSchemeDocs(data?.scheme_details);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError(error);
      },
    });
  };

  useEffect(() => {
    if (scheme?.scheme_id) getSchemeDocs();
  }, [scheme]);

  return (
    <Container>
      <>
        <Box className="plan-detail-container space-from-top">
          <PlanDetailsComponent
            planDetails={
              plans?.length > 1
                ? plans.find((plan) => plan?.plan_status?.name === "Accepted")
                : plans[0]
            }
            planStatusBg={planStatusBg}
            amendedPlan={false}
            planLength={plans?.length}
          />
        </Box>
        <Box className="plan-detail-container mt-30">
          <TermsAndFaqs
            faq={schemeDocs?.faq}
            termsAndConditions={schemeDocs?.terms_and_conditions}
          />
        </Box>
        {plans?.length > 1 ? (
          <Box className="plan-detail-container mt-30">
            <PlanDetailsComponent
              planDetails={
                plans.find((plan) => plan?.plan_status?.name !== "Accepted") ||
                plans[1]
              }
              planStatusBg={planStatusBg}
              amendedPlan={true}
              planLength={plans?.length}
            />
          </Box>
        ) : (
          <Box className="plan-guidance-container mt-18">
            <PlanGuidanceComponent planDetails={plans[0]} />
          </Box>
        )}
        <CustomerServiceFooter />
        {isLatestActivePlan ? (
          <StaffBenefitsWidget
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          />
        ) : null}
      </>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.scheme,
  };
};

ManageMySharedCostWithPlans.propTypes = {
  scheme: PropTypes.object,
  plans: PropTypes.array,
};

export default connect(mapStateToProps)(ManageMySharedCostWithPlans);
