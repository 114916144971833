import { gql } from "@apollo/client";

export const confirmInvestmentAdvice = gql`
  mutation confirmInvestmentAdvice(
    $id: Int!
    $investment_advice: Boolean!
    $step_number: Int!
    $ningi_journey_id: Int
  ) {
    update_temp_plans(
      _set: { investment_advice: $investment_advice, step_number: $step_number }
      where: { id: { _eq: $id } }
    ) {
      returning {
        investment_advice
        step_number
      }
    }
    delete_ningi_journeys(where: { id: { _eq: $ningi_journey_id } }) {
      returning {
        id
        journey_status
      }
    }
  }
`;
