import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getFieldErrorNames } from "../../../helpers";

const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
  customError,
  isCustomError,
  setExpanded,
  forceSroll = false,
}) => {
  const { submitCount, isValid, errors, dirty } = useFormikContext();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (!forceSroll && (!hasSubmitted || !dirty || (isValid && !isCustomError)))
      return;
    const fieldErrorNames = getFieldErrorNames({ ...errors, ...customError });
    if (fieldErrorNames.length <= 0) return;

    if (fieldErrorNames[0] == "RnUCheckbox") setExpanded(true);

    let element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);

    if (fieldErrorNames[0].includes("investmentChoices")) {
      const splitInvestmentChoice = fieldErrorNames[0].split(".");

      element = document.querySelector(
        `input[name='${splitInvestmentChoice[0]}[${splitInvestmentChoice[1]}].${splitInvestmentChoice[2]}']`
      );
    }

    if (!element) return;

    element.scrollIntoView(scrollBehavior);
  }, [dirty, isValid, submitCount, hasSubmitted, customError]);

  useEffect(() => {
    if (submitCount || forceSroll) {
      setHasSubmitted(true);
    }
  }, [submitCount, forceSroll]);

  return null;
};

ScrollToFieldError.propTypes = {
  scrollBehavior: PropTypes.object,
  customError: PropTypes.object,
  isCustomError: PropTypes.bool,
  setExpanded: PropTypes.bool,
  forceSroll: PropTypes.bool,
};

export default ScrollToFieldError;
