import { Box, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import FormSelect from "../../../../generic-components/form-select";
import Loader from "../../../../generic-components/loader";
import {
  pensionFundSchema,
  pensionFundWithProviderSchema,
} from "../../../../../schema";
import useStepper from "../../../../../hooks/useStepper";
import { useLazyQuery, useMutation } from "@apollo/client";
import FooterButtons from "../footer/FooterButtons";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { getPensionFunds } from "../../../../../graphql/queries/pensionFunds";
import FormHeader from "../header/FormHeader";
import { updateFundDetailOnly } from "../../../../../graphql/mutations/updateFundDetailOnly";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import SavePlan from "../../../../generic-components/save-plan";
import { getProviders } from "../../../../../graphql/queries/providers";

const PensionFund = ({ form, formSharedTitle, pensionName }) => {
  const {
    setSaveError,
    handleNext,
    btnClicked,
    setIsLoadingNext,
    activeStep,
    setFormValues,
    formValues,
    setIsLoadingSave,
    setSavedToast,
    personalDetails,
    setErrorToast,
  } = useStepper();

  const mobileView = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const initialValues = {
    pensionFund: formValues.pensionFund,
    provider: formValues.provider,
  };

  const [pensionFunds, setPensionFunds] = useState([]);
  const [providers, setProviders] = useState([]);
  const [updateFunds] = useMutation(updateFundDetailOnly);

  const handleError = (error) => {
    setSaveError(error);
    setErrorToast(true);
  };

  const handleCompleteAction = (data, setData) => {
    const modifiedData =
      data?.map((entry) => ({
        name: entry.name,
        value: entry.id,
      })) || [];

    setData(modifiedData);
  };

  const [getPensionFundList, { loading: fundsLoading }] = useLazyQuery(
    getPensionFunds,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        handleCompleteAction(data.pension_funds, setPensionFunds);
      },
      onError: handleError,
    }
  );

  const [getProvidersList, { loading: providersLoading }] = useLazyQuery(
    getProviders,
    {
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        handleCompleteAction(data.providers, setProviders);
      },
      onError: handleError,
    }
  );

  useEffect(() => {
    getPensionFundList();
    getProvidersList();
  }, []);

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else {
      setIsLoadingSave(true);
    }
    updateFunds({
      variables: {
        id: form.id,
        pension_fund_id: values.pensionFund,
        provider_id: values.provider,
        step_number: btnClicked === "save" ? activeStep + 3 : activeStep + 4,
        updated_at: moment().format(),
      },
      onCompleted: (data) => {
        if (btnClicked === "save_and_next") {
          setIsLoadingNext(false);
          handleNext();
        } else {
          setIsLoadingSave(false);
          setSavedToast(true);
        }

        dispatch(setForm(data.update_temp_plans.returning[0]));
      },
      onError: (error) => {
        setIsLoadingSave(false);
        setSaveError(error);
        setIsLoadingNext(false);
        setErrorToast(true);
      },
    });
  };

  const avcAmount = useMemo(
    () =>
      formValues.additional_avc_amount
        ? Number(formValues.avcAmount || 0) + formValues.additional_avc_amount
        : Number(formValues.avcAmount || 0),
    [formValues.avcAmount]
  );

  return (
    <Formik
      validationSchema={
        providers?.length > 1
          ? pensionFundWithProviderSchema
          : pensionFundSchema
      }
      initialValues={initialValues}
      onSubmit={handleUpdate}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box className="investment-choices-step-2">
            {fundsLoading || providersLoading ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <ScrollToFieldError />
                <SavePlan />
                <Box className="application-page-container">
                  <FormHeader heading="Investment Choices" amount={avcAmount} />
                  <Typography className="mt-30 heading primary-text">
                    <strong>
                      The important decision on where to invest your
                      {""} {formSharedTitle} pot.
                    </strong>
                  </Typography>
                  <Typography className="mt-30 stepper-content">
                    <strong>
                      Your employer offers the {pensionName}
                      {""} and it is administered by different Pension Funds.
                    </strong>
                  </Typography>
                  <Typography className="mt-30 stepper-content">
                    Please select which pension fund your {pensionName}
                    {""} is administered by:
                  </Typography>
                  <FormSelect
                    name="pensionFund"
                    labelId="pensionFundList"
                    menuItems={pensionFunds}
                    label={`${pensionName}*`}
                    width={mobileView ? 100 : 70}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />
                  {values.pensionFund && providers?.length > 1 && (
                    <>
                      <Typography className="mt-30 stepper-content">
                        <strong>
                          Your employer offers you the choice of providers that
                          can hold and invest your {formSharedTitle}s.
                        </strong>
                      </Typography>
                      <Typography className="mt-30 stepper-content">
                        Please select which provider you want to use
                      </Typography>
                      <FormSelect
                        name="provider"
                        labelId="providerData"
                        menuItems={providers}
                        label="Providers *"
                        width={mobileView ? 100 : 70}
                        trackingDetails={TRACKING_NAF_OPTIONS}
                      />
                    </>
                  )}
                </Box>
                <FooterButtons />
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    formSharedTitle: state.multiForm.formSharedTitle,
    pensionName: state.multiForm.pensionName,
  };
};

PensionFund.propTypes = {
  form: PropTypes.object,
  formSharedTitle: PropTypes.string,
  pensionName: PropTypes.string,
};

export default connect(mapStateToProps)(PensionFund);
