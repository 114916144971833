import { createSlice } from "@reduxjs/toolkit";
import {
  formType,
  pensionName,
  pensionType,
  sharedTitle,
  formSharedTitle,
} from "../constants/multiForm";
const multiFormReducer = createSlice({
  name: "multiForm",
  initialState: {
    formType: "",
    formSharedTitle: "",
    sharedTitle: "",
    pensionName: "",
    pensionType: "",
  },
  reducers: {
    setApplicationType: (state, action) => {
      const isNHSType = action.payload;
      switch (isNHSType) {
        case true:
          state = {
            ...state,
            formType: formType.NHS_APPLICATION_FORM,
            formSharedTitle: formSharedTitle.NHS_SHARED_TITLE,
            sharedTitle: sharedTitle.NHS_SHARED_TITLE,
            pensionName: pensionName.NHS,
            pensionType: pensionType.NHS,
          };
          return state;
        case false:
          state = {
            ...state,
            formType: formType.NEW_APPLICATION_FORM,
            formSharedTitle: formSharedTitle.NAF_SHARED_TITLE,
            sharedTitle: sharedTitle.NAF_SHARED_TITLE,
            pensionName: pensionName.LOCAL_GOVT,
            pensionType: pensionType.LGPS,
          };
          return state;
      }
    },
  },
});

const { actions, reducer } = multiFormReducer;

export const isNHSApplication = (state) => {
  return state.multiForm.formType === formType.NHS_APPLICATION_FORM
    ? true
    : false;
};

export const { setApplicationType } = actions;

export default reducer;
