import Cookies from "universal-cookie";

const cookies = new Cookies();

const options = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
};

export const getCookie = (name) => {
  return cookies.get(name, options);
};

export const getAllCookies = () => {
  return cookies.getAll(options);
};

export const removeCookie = (name) => {
  cookies.remove(name, options);
};

export const setCookie = (name, value) => {
  cookies.set(name, value, options);
};
