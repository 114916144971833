import { gql } from "@apollo/client";

export const getPensionFunds = gql`
  query getPensionFunds($id: Int!) {
    pension_funds(
      where: { organisation_pension_funds: { organisation_id: { _eq: $id } } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;
export const getPensionFundById = gql`
  query getPensionFundById($id: Int!) {
    pension_funds(where: { id: { _eq: $id } }) {
      id
      legal_and_general_fund_guide_link
      prudential_pension_fund_url
    }
  }
`;
