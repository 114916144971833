import { createSlice } from "@reduxjs/toolkit";

const tempPlanReducer = createSlice({
  name: "tempPlan",
  initialState: {
    tempPlan: {},
    isValid: false,
    submitForm: () => {},
  },
  reducers: {
    setTempPlan: (state, action) => {
      state.tempPlan = { ...state.tempPlan, ...action.payload };
    },
    setPlanStatus: (state, action) => {
      state.isValid = action.payload;
    },
    setSubmitForm: (state, action) => {
      state.submitForm = action.payload;
    },
    resetTempPlan: (state) => {
      state.tempPlan = {};
      state.isValid = false;
      state.submitForm = () => {};
    },
  },
});

const { actions, reducer } = tempPlanReducer;

export const { setTempPlan, setPlanStatus, setSubmitForm, resetTempPlan } =
  actions;

export default reducer;
