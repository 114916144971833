import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React from "react";
import frontImage from "../../../../assets/images/front-image.png";
import avcwiseLogoDark from "../../../../assets/images/avc-logo-dark.png";
import { Link } from "react-router-dom";

const VerifiedScreen = () => {
  return (
    <>
      <Box
        className="front-screen-container container-wrapper"
        sx={{ backgroundImage: `url(${frontImage})` }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} className="content-wrapper">
              <Grid item xs={12} sm={10} md={7} sx={{ margin: "0 auto" }}>
                <Box className="content-container">
                  <Box className="inner-content-container">
                    <Box className="img-container mb-50">
                      <Box
                        component="img"
                        className="logo-img"
                        src={avcwiseLogoDark}
                        alt="logo-dark"
                      />
                    </Box>
                    <Typography className="content">
                      You already have an My Money Matters profile <br />
                      Click here to sign in
                    </Typography>
                    <Button
                      component={Link}
                      to="/sign_in"
                      variant="contained"
                      className="signinflow-btn mt-30"
                    >
                      Sign in
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default VerifiedScreen;
