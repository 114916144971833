import { createSlice } from "@reduxjs/toolkit";
const formReducer = createSlice({
  name: "form",
  initialState: {
    form: {},
  },
  reducers: {
    setForm: (state, action) => {
      state.form = { ...state.form, ...action.payload };
    },
    resetForm: (state) => {
      state.form = {};
    },
  },
});

const { actions, reducer } = formReducer;

export const { setForm, resetForm } = actions;

export default reducer;
