import { Stack } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useStepper from "../../../../../hooks/useStepper";
import PropTypes from "prop-types";
import LoadingButton from "../../../../generic-components/button";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import IconButton from "../../../../generic-components/icon-btn";

const FooterButtons = ({ handleClick, isValid, isCustomLoading }) => {
  const {
    activeStep,
    submitFormIndex,
    handleBack,
    isLoadingNext,
    isLoadingSave,
    setBtnClicked,
    setCompletedStep,
    completedStep,
  } = useStepper();

  const handleSave = () => {
    setBtnClicked("save");
    handleClick && handleClick("save");
    setCompletedStep([...completedStep, activeStep]);
  };

  const handleSaveAndNext = () => {
    setBtnClicked("save_and_next");
    handleClick && handleClick("save_and_next");
    setCompletedStep([...completedStep, activeStep]);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      className="mt-50"
    >
      <IconButton
        trackingDetails={TRACKING_NAF_OPTIONS}
        disabled={activeStep === 0 || isLoadingSave || isLoadingNext}
        buttonTitle="Back"
        styleClass={activeStep === 0 ? "disable-backbtn" : "backbtn"}
        handleClick={handleBack}
        link="#"
        icon={<KeyboardArrowLeftIcon />}
      />

      <Stack direction="row" spacing={2}>
        <LoadingButton
          buttonTitle="Save my application"
          loading={isLoadingSave}
          disabled={isLoadingNext || isCustomLoading}
          trackingDetails={TRACKING_NAF_OPTIONS}
          handleClick={handleSave}
          styleClass="btn outlined-btn"
          type="submit"
        />
        <LoadingButton
          buttonTitle={
            submitFormIndex === 2 && activeStep == 3
              ? "Submit"
              : "Take me to the next step"
          }
          loading={isLoadingNext}
          disabled={isLoadingSave || isCustomLoading}
          trackingDetails={TRACKING_NAF_OPTIONS}
          handleClick={handleSaveAndNext}
          styleClass="btn contained-btn"
          type="submit"
          submitValid={isValid}
        />
      </Stack>
    </Stack>
  );
};

FooterButtons.propTypes = {
  handleClick: PropTypes.func,
  isValid: PropTypes.bool,
  isCustomLoading: PropTypes.bool,
};

export default FooterButtons;
