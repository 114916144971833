import { Box, Container, Grid } from "@mui/material";
import { connect } from "react-redux";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import ContactDescription from "./contact-page-content/ContactDescription";
import SideNavigationLinks from "./contact-page-content/SideNavigationLinks";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Loader from "../../../generic-components/loader";
import isEmpty from "lodash.isempty";

const ContactPage = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isEmpty(user) && user.latest_plan) {
      setIsLoading(false);
    } else if (!isEmpty(user) && !user.latest_plan) {
      window.location.href = process.env.REACT_APP_CONTACT_US_URL;
    }
  }, [user]);

  return (
    <Box className="application-page-container mt-150 mb-110">
      {isLoading ? (
        <Loader className="mt-100" />
      ) : (
        <>
          <SharedHeader heading="My Plans" />
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3.5}>
                <SideNavigationLinks />
              </Grid>
              <Grid item xs={12} md={8.5}>
                <ContactDescription />
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

ContactPage.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(ContactPage);
