import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import Loader from "../../../generic-components/loader";
import ManageMySharedCostWithNoPlans from "./manage-my-shared-cost-content/ManageMySharedCostWithNoPlans";
import ManageMySharedCostWithPlans from "./manage-my-shared-cost-content/ManageMySharedCostWithPlans";
import usePlan from "../../../../hooks/usePlan";
import usePlanDetails from "../../../../hooks/usePlanDetails";
import { checkActiveSchemes } from "../../../../helpers";
import useStepper from "../../../../hooks/useStepper";
import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";

const ManageMySharedCostAVCPage = ({ user }) => {
  const { plans } = usePlan();
  const navigate = useNavigate();
  const { personalDetails } = useStepper();
  const { fetchCompletePlanDetails, isPlanLoading } = usePlanDetails();

  useEffect(() => {
    if (user.id && user.organisation_id && !isEmpty(personalDetails)) {
      const requiredActiveScheme = checkActiveSchemes(personalDetails);
      if (!requiredActiveScheme) navigate("/scheme_not_found");
      fetchCompletePlanDetails(user.id, user.organisation_id, true);
    }
  }, [user.organisation_id, user.id]);

  return (
    <Box className="application-page-container manage-my-shared-cost space-from-top">
      <SharedHeader heading="My Shared Cost AVC" />
      {isPlanLoading ? (
        <Box className="mt-200">
          <Loader />
        </Box>
      ) : plans?.length ? (
        <>
          <ManageMySharedCostWithPlans plans={plans} />
          <Box className="home-detail-section back-img-sm"></Box>
        </>
      ) : (
        <>
          <ManageMySharedCostWithNoPlans />
          <Box className="home-detail-section back-img-sm"></Box>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
  };
};

ManageMySharedCostAVCPage.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(ManageMySharedCostAVCPage);
