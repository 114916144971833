import { gql } from "@apollo/client";

export const getTempPlan = gql`
  query getTempPlanDetails($id: Int) {
    temp_plans(where: { id: { _eq: $id } }) {
      id
      organisation_id
      additional_avc_amount
      first_name
      delivery_address1
      title
      employee_number
      referral_code
      delivery_address1
      delivery_address2
      delivery_county
      date_of_birth
      email
      hours_per_week
      first_name
      last_name
      mobile_number
      ni_number
      delivery_postcode
      delivery_town
      telephone_number
      total_avc_amount_requested
      annual_salary
      avc_interval
      max_contribution_amount
      investment_advice
      incomplete_journey_consent
      signatures
      flexibly_accessed_any_pensions
      lifestyling_option
      pension_fund_id
      provider_id
      retirement_age
      pension_age
      terms_acknowledged
      previous_amount_added
      contribution_amount_updated
      signatures
      prudential_declaration
      nhs_pension_scheme_benefits
      step_number
      show_ningi_journey_consent
      choosen_relevant_investment_option
      having_previous_employer_plan
      combining_with_this_employer
      keep_separate_with_employer
      triggered_the_mpaa
      mpaa_date
      flexibly_accessed_date
      creation_reason_id
      creation_reason_other
      ningi_journey {
        id
        journey_status
        journey_url
        recommendation_changed
      }
      temp_plan_investments {
        id
        specialist_value
        investment_option {
          display_name
          id
          lifestyle
        }
      }
    }
  }
`;
