import { gql } from "@apollo/client";

export const updateUserInfo = gql`
  mutation updateUserDetails(
    $employee_number: String
    $title: String
    $gender: String
    $date_of_birth: String
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_town: String
    $telephone_number: String
    $delivery_postcode: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $annual_salary: Int
    $salary_bracket: String
    $age_bracket: String
    $department: String
    $referral_email: String
    $incomplete_application_at: String
  ) {
    updateUserDetails(
      employee_number: $employee_number
      title: $title
      gender: $gender
      date_of_birth: $date_of_birth
      email: $email
      first_name: $first_name
      last_name: $last_name
      mobile_number: $mobile_number
      ni_number: $ni_number
      telephone_number: $telephone_number
      town: $delivery_town
      postcode: $delivery_postcode
      address_1: $delivery_address1
      address_2: $delivery_address2
      county: $delivery_county
      annual_salary: $annual_salary
      age_bracket: $age_bracket
      salary_bracket: $salary_bracket
      department: $department
      referral_email: $referral_email
      incomplete_application_at: $incomplete_application_at
    ) {
      title
      gender
      employee_number
      date_of_birth
      email
      first_name
      last_name
      mobile_number
      ni_number
      telephone_number
      town
      postcode
      address_1
      address_2
      county
      annual_salary
      age_bracket
      salary_bracket
      department
      referral_email
      incomplete_application_at
    }
  }
`;

export const createPersonalDetails = gql`
  mutation CreatPlan(
    $scheme_id: Int!
    $scheme_type_id: Int!
    $user_id: Int!
    $organisation_id: Int!
    $employee_number: String
    $title: String
    $gender: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_postcode: String
    $delivery_town: String
    $telephone_number: String
    $step_number: Int
    $created_at: timestamp!
    $updated_at: timestamp!
    $tracking_code: String!
  ) {
    insert_temp_plans(
      objects: {
        scheme_id: $scheme_id
        scheme_type_id: $scheme_type_id
        user_id: $user_id
        organisation_id: $organisation_id
        employee_number: $employee_number
        title: $title
        gender: $gender
        created_at: $created_at
        updated_at: $updated_at
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_county: $delivery_county
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        last_name: $last_name
        mobile_number: $mobile_number
        ni_number: $ni_number
        delivery_postcode: $delivery_postcode
        delivery_town: $delivery_town
        step_number: $step_number
        telephone_number: $telephone_number
        tracking_code: $tracking_code
      }
    ) {
      returning {
        id
        scheme_id
        scheme_type_id
        user_id
        organisation_id
        title
        gender
        employee_number
        created_at
        updated_at
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        first_name
        last_name
        mobile_number
        ni_number
        delivery_postcode
        delivery_town
        telephone_number
        step_number
        tracking_code
      }
    }
  }
`;

export const upadatePersonalDetails = gql`
  mutation updatePersonalDetails(
    $id: Int!
    $employee_number: String
    $title: String
    $gender: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_postcode: String
    $delivery_town: String
    $telephone_number: String
    $updated_at: timestamp
    $step_number: Int!
  ) {
    update_temp_plans(
      _set: {
        employee_number: $employee_number
        title: $title
        gender: $gender
        mobile_number: $mobile_number
        telephone_number: $telephone_number
        date_of_birth: $date_of_birth
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_county: $delivery_county
        delivery_postcode: $delivery_postcode
        delivery_town: $delivery_town
        email: $email
        ni_number: $ni_number
        last_name: $last_name
        first_name: $first_name
        step_number: $step_number
        updated_at: $updated_at
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        title
        gender
        employee_number
        created_at
        updated_at
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        first_name
        last_name
        mobile_number
        ni_number
        delivery_postcode
        delivery_town
        telephone_number
        step_number
      }
    }
  }
`;

export const upadatePersonalDetailsForProfile = gql`
  mutation upadatePersonalDetailsForProfile(
    $id: Int!
    $title: String
    $gender: String
    $first_name: String
    $last_name: String
    $employee_number: String
    $mobile_number: String
    $telephone_number: String
    $date_of_birth: date
    $updated_at: timestamp
  ) {
    update_temp_plans(
      _set: {
        title: $title
        gender: $gender
        last_name: $last_name
        first_name: $first_name
        employee_number: $employee_number
        mobile_number: $mobile_number
        telephone_number: $telephone_number
        date_of_birth: $date_of_birth
        updated_at: $updated_at
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        title
        gender
        first_name
        last_name
        employee_number
        mobile_number
        telephone_number
        date_of_birth
        updated_at
      }
    }
  }
`;

export const upadateAddressDetailsForProfile = gql`
  mutation upadateAddressDetailsForProfile(
    $id: Int!
    $delivery_postcode: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_town: String
    $delivery_county: String
    $updated_at: timestamp
  ) {
    update_temp_plans(
      _set: {
        delivery_postcode: $delivery_postcode
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_town: $delivery_town
        delivery_county: $delivery_county
        updated_at: $updated_at
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        delivery_postcode
        delivery_address1
        delivery_address2
        delivery_town
        delivery_county
        updated_at
      }
    }
  }
`;

export const UpdateUserCurrentOrganisation = gql`
  mutation UpdateUserCurrentOrganisation(
    $user_id: Int!
    $current_organisation_id: Int!
    $current_role_id: Int!
  ) {
    update_users(
      where: { id: { _eq: $user_id } }
      _set: {
        current_organisation_id: $current_organisation_id
        current_role_id: $current_role_id
      }
    ) {
      returning {
        current_role_id
        current_organisation_id
      }
    }
  }
`;

export const createAvcAmountPersonalDetails = gql`
  mutation CreatPlan(
    $scheme_id: Int!
    $scheme_type_id: Int!
    $user_id: Int!
    $organisation_id: Int!
    $employee_number: String
    $referral_code: String
    $title: String
    $gender: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_postcode: String
    $delivery_town: String
    $telephone_number: String
    $step_number: Int
    $created_at: timestamp!
    $updated_at: timestamp!
    $tracking_code: String!
    $total_avc_amount_requested: float8!
    $avc_interval: String
    $annual_salary: Int!
    $hours_per_week: float8!
    $max_contribution_amount: float8!
    $additional_avc_amount: Int
    $previous_amount_added: float8!
    $contribution_amount_updated: Boolean!
  ) {
    insert_temp_plans(
      objects: {
        scheme_id: $scheme_id
        scheme_type_id: $scheme_type_id
        avc_interval: $avc_interval
        user_id: $user_id
        organisation_id: $organisation_id
        employee_number: $employee_number
        referral_code: $referral_code
        title: $title
        gender: $gender
        created_at: $created_at
        updated_at: $updated_at
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_county: $delivery_county
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        last_name: $last_name
        mobile_number: $mobile_number
        ni_number: $ni_number
        delivery_postcode: $delivery_postcode
        delivery_town: $delivery_town
        step_number: $step_number
        telephone_number: $telephone_number
        tracking_code: $tracking_code
        total_avc_amount_requested: $total_avc_amount_requested
        annual_salary: $annual_salary
        hours_per_week: $hours_per_week
        previous_amount_added: $previous_amount_added
        contribution_amount_updated: $contribution_amount_updated
        max_contribution_amount: $max_contribution_amount
      }
    ) {
      returning {
        id
        scheme_id
        scheme_type_id
        user_id
        organisation_id
        title
        gender
        employee_number
        referral_code
        created_at
        updated_at
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        first_name
        last_name
        mobile_number
        ni_number
        total_avc_amount_requested
        annual_salary
        hours_per_week
        avc_interval
        max_contribution_amount
        previous_amount_added
        contribution_amount_updated
        delivery_postcode
        delivery_town
        telephone_number
        step_number
        tracking_code
      }
    }
    update_users(
      where: { id: { _eq: $user_id } }
      _set: { incomplete_application_at: $updated_at }
    ) {
      returning {
        id
        incomplete_application_at
      }
    }
  }
`;
