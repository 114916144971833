import { gql } from "@apollo/client";

export const logoutUser = gql`
  mutation logoutUser {
    destroy_session {
      message
      logout_url
    }
  }
`;
