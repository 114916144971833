import axios from "axios";

const Service = (domainURL, url) =>
  axios.create({
    baseURL: domainURL + url,
  });

export const AuthorizedService = (url) => {
  const authorizedService = Service(process.env.REACT_APP_ADDRESS_API_URL, url);
  return authorizedService;
};

export const Auth0Service = (url) => {
  const auth0Service = Service(process.env.REACT_APP_AUTH0_DOMAIN_URL, url);
  return auth0Service;
};

export const SSOService = (url) => {
  const ssoService = Service(process.env.REACT_APP_AVCWISE_URL, url);
  return ssoService;
};
