import { Box, Dialog, DialogTitle, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { TRACKING_HOME_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import IconButton from "../../../../generic-components/icon-btn";

const NoPlanDialog = ({ open, onClose, organisation, scrollToApply }) => {
  const handleApplyNow = () => {
    onClose();
    scrollToApply();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="no-plan-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">My Shared Cost AVC</Typography>
      </DialogTitle>
      <IconButton
        trackingDetails={TRACKING_HOME_OPTIONS}
        extraStyles={{
          position: "absolute",
          right: 4,
          top: 18,
          color: (theme) => theme.palette.grey[500],
        }}
        handleClick={onClose}
        icon={<CloseIcon />}
      />
      <Box className="dialog-box">
        <Box
          component="img"
          src={organisation?.logo}
          alt="organisation-logo"
          className="organisation-logo"
        />
        <Typography className="mt-18">
          <strong>
            You don't have a Shared Cost AVC application or plan yet.
          </strong>
        </Typography>
        <Typography className="mt-12">
          Please click the button below to apply for Shared Cost AVC plan.
        </Typography>
        <LoadingButton
          buttonTitle="Apply Now"
          trackingDetails={TRACKING_HOME_OPTIONS}
          styleClass="btn primary-clr-btn apply-now-btn mediumbtn mt-30 mb-30"
          handleClick={handleApplyNow}
        />
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

NoPlanDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  scrollToApply: PropTypes.func,
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(NoPlanDialog);
