import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import mmmLogo from "../../../assets/images/mmm-white-logo.png";
import {
  footerLinks,
  footerSocialMediaLinks,
  TRACKING_FOOTER_OPTIONS,
} from "../../../constants";
import NavigationLinks from "../../generic-components/navigation-links";
import SocialMediaLinks from "../../generic-components/socialmedia-links";
import LogoButton from "../../generic-components/logo-btn";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Footer = ({ organisation }) => {
  return (
    <>
      <Box className="footer">
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Box className="footer-content">
                <Stack
                  direction={{ md: "row", sm: "column" }}
                  justifyContent="space-between"
                  className="mb-18"
                >
                  <Box className="footer-logo-container">
                    <LogoButton
                      name="MMM"
                      logoPath={mmmLogo}
                      trackingDetails={TRACKING_FOOTER_OPTIONS}
                      styleClass="footer-logo"
                    />
                  </Box>
                  <Stack direction="column">
                    <Typography variant="h4" color={"white"} fontWeight={"700"}>
                      Your platform{" "}
                      <span className="lighter-purple-v2">for prosperity</span>
                    </Typography>
                    <Stack
                      direction="row"
                      flexWrap={"wrap"}
                      alignItems="center"
                    >
                      {footerLinks.map((val, index) => (
                        <>
                          {val.name === "Privacy Policy" &&
                          organisation?.privacy_policy_pdf ? (
                            <NavigationLinks
                              styleClass="footerlinks nav-links"
                              link={organisation?.privacy_policy_pdf}
                              name={val.name}
                              trackingDetails={TRACKING_FOOTER_OPTIONS}
                              newTab={true}
                              key={index}
                            />
                          ) : (
                            <NavigationLinks
                              styleClass="footerlinks nav-links"
                              link={val.link}
                              name={val.name}
                              trackingDetails={TRACKING_FOOTER_OPTIONS}
                              newTab={true}
                              key={index}
                            />
                          )}

                          <Typography variant="body1" className="divider">
                            |
                          </Typography>
                        </>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>

                <Divider style={{ background: "#4a3c7f" }} />

                <Stack
                  className="mt-18"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" color={"white"}>
                    © {new Date().getFullYear()}, AVC Wise Ltd (t/a My Money
                    Matters). All Rights reserved. Company Number 11406613
                  </Typography>
                  <Box className="socialmedia" gap={"10px"} mt={2}>
                    {footerSocialMediaLinks.map((val, index) => (
                      <SocialMediaLinks
                        link={val.link}
                        name={val.name}
                        iconPath={val.iconPath}
                        trackingDetails={TRACKING_FOOTER_OPTIONS}
                        key={index}
                      />
                    ))}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

Footer.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(Footer);
