import React from "react";
import { Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";

const ButtonSection = () => {
  return (
    <>
      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <Button
          component={Link}
          to="/sign_in"
          variant="outlined"
          className="outlined-btn"
        >
          Sign in
        </Button>
        <Button
          component={Link}
          to="/register"
          variant="outlined"
          className="outlined-btn"
        >
          Register
        </Button>
      </Stack>
    </>
  );
};

export default ButtonSection;
