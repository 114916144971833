import { default as MuiDialog } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import LoadingButton from "../button";
import { TRACKING_NAF_OPTIONS } from "../../../constants";

const Dialog = ({
  title,
  classes,
  showLoading,
  showDialog,
  handleSave,
  handleClose,
  description,
  saveButtonText,
  closeButtonText,
  showCloseBtn = true,
  trackingDetails,
  showCloseLoading,
  disableEscapeKeyDown = false,
}) => {
  return (
    <Box>
      <MuiDialog
        open={showDialog}
        onClose={handleClose}
        disableEscapeKeyDown={disableEscapeKeyDown}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className={`dialog-modal-background ${classes}`}
        sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      >
        <DialogTitle className="dialog-heading">{title}</DialogTitle>
        <Box className="dialog-message-alignment">
          <DialogContent className="dialog-message_container">
            <DialogContentText className="dialog-message">
              {description}
            </DialogContentText>
          </DialogContent>
        </Box>
        <DialogActions className="dialog-btn-container">
          <LoadingButton
            buttonTitle={saveButtonText || "Save"}
            trackingDetails={trackingDetails}
            handleClick={handleSave}
            loading={showLoading}
            styleClass="contained-btn btn fs-14"
          />

          {showCloseBtn && (
            <LoadingButton
              buttonTitle={closeButtonText || "Close"}
              trackingDetails={TRACKING_NAF_OPTIONS}
              loading={showCloseLoading}
              handleClick={handleClose}
              styleClass="outlined-btn btn fs-14"
            />
          )}
        </DialogActions>
      </MuiDialog>
    </Box>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  showDialog: PropTypes.bool,
  showCloseLoading: PropTypes.bool,
  showLoading: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  handleSave: PropTypes.func,
  trackingDetails: PropTypes.func,
  handleClose: PropTypes.func,
  description: PropTypes.string,
  saveButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  disableEscapeKeyDown: PropTypes.bool,
};

export default Dialog;
