import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "../../../../../helpers";

const FormHeader = ({ heading, amount }) => {
  return (
    <>
      <Box className="shared-cost-application-header">
        <Box> {heading}</Box>
        {amount && (
          <Box className="choosenAmount">
            Chosen AVC amount:
            <span> {formatCurrency(amount)}</span>
          </Box>
        )}
      </Box>
    </>
  );
};

FormHeader.propTypes = {
  heading: PropTypes.string,
  amount: PropTypes.number,
};

export default FormHeader;
