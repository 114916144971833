import React from "react";
import errorImage from "../../assets/404-error.jpg";
import statusImage from "../../assets/404.png";
import { Box, Container, Stack, Typography } from "@mui/material";
import LoadingButton from "../generic-components/button";
import { useNavigate } from "react-router-dom";
import { TRACKING_NAF_OPTIONS } from "../../constants";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box className="mt-100">
      <Container>
        <Box className="status-box">
          <Typography className="error-status mt-30">404 Error</Typography>
        </Box>
        <Stack
          className="mt-30"
          direction={{ md: "row", sm: "column" }}
          justifyContent="space-between"
          spacing={15}
        >
          <Box
            src={statusImage}
            alt="404-error"
            component="img"
            className="status-img"
          />
          <Box className="mt-30">
            <Box className="status-box">
              <Typography className="error-status">
                <strong>Oops!</strong>
              </Typography>
            </Box>
            <Typography className="error-description">
              Looks like we cannot find the page you requested, head back to the
              homepage or go back and try again. Our systems team have been
              notified of this error.
            </Typography>
            <LoadingButton
              buttonTitle="TAKE ME THERE"
              trackingDetails={TRACKING_NAF_OPTIONS}
              handleClick={() => navigate("/home")}
              styleClass="btn contained-btn mt-12"
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Box
            component="img"
            src={errorImage}
            alt="alt-server-error"
            className="error-img mt-50"
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default PageNotFound;
