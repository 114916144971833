import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import calculatorIcon from "../../../../../assets/images/calculator.png";
import amendIcon from "../../../../../assets/images/amend.png";
import LoadingButton from "../../../../generic-components/button";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import ContributionCalculatorModal from "../../shared-cost-application/modal/ContributionCalculatorModal";

const PlanGuidance = ({ planDetails }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const navigate = useNavigate();

  const handleCalculatorModal = () => {
    const planStatus = planDetails?.plan_status?.name;
    if (planStatus === "Accepted") {
      setModalInfo({
        applyButtonTitle: "Amend",
        showCloseButton: false,
      });
    } else if (
      [
        "Awaiting Authorisation",
        "Opt Out Pending",
        "Awaiting Provider Match",
        "On Hold – Existing AVC",
        "On Hold - NMW issue",
        "On Hold – ID issue",
        "On Hold – Low Earnings Limit",
        "On Hold - Provider Match Issue",
        "On Hold - Awaiting Employer Action",
        "On Hold – Awaiting Employee Acceptance",
      ].includes(planStatus)
    ) {
      setModalInfo({
        closeButtonTitle: "Close",
        showApplyButton: false,
      });
    } else if (["Cancelled", "Opted-out"].includes(planStatus)) {
      setModalInfo({
        applyButtonTitle: "Apply",
        showCloseButton: false,
      });
    }
  };

  const ServiceCard = ({ iconSrc, content, buttonText, handleClick }) => (
    <Box className="service-card">
      <Box className="service-icon">
        <Box
          className="service-icon"
          component="img"
          src={iconSrc}
          alt="service-icon"
        />
      </Box>
      <Typography className="service-content mt-18">{content}</Typography>
      <LoadingButton
        buttonTitle={buttonText}
        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
        handleClick={handleClick}
        styleClass="service-button mt-18"
      />
    </Box>
  );

  ServiceCard.propTypes = {
    iconSrc: PropTypes.string,
    content: PropTypes.string,
    buttonText: PropTypes.string,
    handleClick: PropTypes.func,
  };

  useEffect(() => {
    handleCalculatorModal();
  }, [planDetails]);

  return (
    <>
      <Box>
        <Grid container>
          <Grid items xs={12} md={8}>
            <Typography className="plan-guidance-heading">
              How much more could you contribute?
            </Typography>
            <Typography className="plan-guidance-content mt-18">
              Amending your plan and increasing your contributions could make a
              difference to your final pot at retirement.
            </Typography>
          </Grid>
        </Grid>
        <Box className="mt-30">
          <Box className="service-container">
            <ServiceCard
              iconSrc={calculatorIcon}
              content="Find out the maximum amount you can contribute"
              buttonText="Maximum Contribution calculator"
              handleClick={() => setOpenModal(true)}
            />
            <Box className="mt-50" />
            {planDetails?.plan_status?.name === "Accepted" ? (
              <ServiceCard
                iconSrc={amendIcon}
                content="To amend your Shared Cost AVC amount"
                buttonText="Amend my Shared Cost AVC"
                handleClick={() =>
                  navigate("/amend_my_shared_cost_avc", {
                    state: {
                      backUrl: "/manage_my_shared_cost_avc",
                    },
                  })
                }
              />
            ) : null}
          </Box>
          <ContributionCalculatorModal
            open={openModal}
            setOpen={setOpenModal}
            applyButtonTitle={modalInfo?.applyButtonTitle}
            closeButtonTitle={modalInfo?.closeButtonTitle}
            showApplyButton={modalInfo?.showApplyButton}
            showCloseButton={modalInfo?.showCloseButton}
            islargeApplyButton={modalInfo?.islargeApplyButton}
          />
        </Box>
      </Box>
    </>
  );
};

PlanGuidance.propTypes = {
  planDetails: PropTypes.object,
};

export default PlanGuidance;
