import { gql } from "@apollo/client";

export const deletePlan = gql`
  mutation DeletePlan($id: Int!) {
    delete_temp_plan_investments(where: { temp_plan_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_temp_plans(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
