import isEmpty from "lodash.isempty";
import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import useStepper from "../../../hooks/useStepper";
import Loader from "../../generic-components/loader";

const BackendUserRoutes = () => {
  const { personalDetails } = useStepper();
  const [isAuthorisedUser, setIsAuthorisedUser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleCheckIsBackendUser = () => {
    if (!personalDetails?.backend_user) {
      setIsAuthorisedUser(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      handleCheckIsBackendUser();
    }
  }, [personalDetails]);

  return (
    <>
      {isLoading ? (
        <Loader className="mt-250 mb-250" />
      ) : isAuthorisedUser ? (
        <Outlet />
      ) : (
        <Navigate to="/404" />
      )}
    </>
  );
};

export default BackendUserRoutes;
