import React, { useContext, useMemo, useState } from "react";
import { createContext } from "react";
import PropTypes from "prop-types";
import { applicationFormInitialValues } from "../constants";
const StepperContext = createContext({});
import store from "../store";
import { transformTempInvestmentChoices } from "../helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTempPlan } from "../reducers/tempPlan";
import { formType } from "../constants/multiForm";

export function StepperProvider({ children }) {
  const dispatch = useDispatch();
  const [personalDetails, setPersonalDetails] = useState({});

  let initialValues = applicationFormInitialValues;

  const [activeStep, setActiveStep] = useState(0);
  const [trackingCode, setTrackingCode] = useState("");
  const [completedStep, setCompletedStep] = useState([]);
  const [sharedFormIndex, setSharedFormIndex] = useState(1);
  const [investmentFormIndex, setInvestmentFormIndex] = useState(1);
  const [submitFormIndex, setSubmitFormIndex] = useState(1);
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(true);
  const [btnClicked, setBtnClicked] = useState("");
  const [savedToast, setSavedToast] = useState(false);
  const [saveError, setSaveError] = useState();
  const [successToast, setSuccessToast] = useState(null);
  const [errorToast, setErrorToast] = useState(true);
  const [webinarAttendance, setWebinarAttendance] = useState(false);
  const [enableFetchUser, setEnableFetchUser] = useState(false);
  const [showJourneyCompleteDialog, setJourneyCompleteDialog] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setInitialValues = (tempPlan) => {
    if (tempPlan) {
      initialValues = {
        ...initialValues,
        ...personalDetails,
        referral_code: tempPlan.referral_code,
        scheme_id: tempPlan.scheme_id,
        hours_per_week: tempPlan.hours_per_week,
        id: tempPlan.id,
        previous_amount_added: tempPlan.previous_amount_added,
        contribution_amount_updated: tempPlan.contribution_amount_updated,
        max_contribution_amount: tempPlan.max_contribution_amount,
        incomplete_journey_consent: tempPlan.incomplete_journey_consent,
        additional_avc_amount: tempPlan.additional_avc_amount,
        avcAmount:
          tempPlan.total_avc_amount_requested ||
          Number(searchParams.get("avc_amount")) ||
          0,
        income: tempPlan.annual_salary,
        incomeFrequency: tempPlan.avc_interval,
        previousEmployerPlan: tempPlan.having_previous_employer_plan,
        combiningWithThisEmployer: tempPlan.combining_with_this_employer,
        keepSeparateWithEmployer: tempPlan.keep_separate_with_employer,
        triggeredTheMpaa: tempPlan.triggered_the_mpaa,
        MPAAdate: tempPlan.mpaa_date,
        flexiblyAccessedDate: tempPlan.flexibly_accessed_date,
        confirmCheckbox: tempPlan.investment_advice,
        accessedPensionBtn: tempPlan.flexibly_accessed_any_pensions,
        lifestyleCheckbox: tempPlan.lifestyling_option,
        pensionFund: tempPlan.pension_fund_id,
        provider: tempPlan.provider_id,
        pensionAge: tempPlan.pension_age,
        retirementAge: tempPlan.retirement_age,
        step_number: tempPlan.step_number,
        RnUCheckbox: tempPlan.choosen_relevant_investment_option,
        investmentChoices: transformTempInvestmentChoices(
          tempPlan.temp_plan_investments
        ),
        termCondition: tempPlan.terms_acknowledged,
        ningi_journey: tempPlan.ningi_journey,
        signature: tempPlan.signatures,
        prudentialConsent: tempPlan.prudential_declaration,
        pensionSchemeBenefits: tempPlan.nhs_pension_scheme_benefits,
        planReason: tempPlan.creation_reason_id,
        otherReason: tempPlan.creation_reason_other,
      };
    } else if (personalDetails) {
      initialValues = {
        ...initialValues,
        ...personalDetails,
        avcAmount: Number(searchParams.get("avc_amount")) || 0,
        total_avc_amount_requested: Number(searchParams.get("avc_amount")) || 0,
      };
    }
    dispatch(setTempPlan(initialValues));
    setFormValues(initialValues);
  };

  const calculateAndSetActiveStep = (stepNumber) => {
    const isNHSApplication =
      store.getState().multiForm?.formType === formType.NHS_APPLICATION_FORM
        ? true
        : false;

    if (isNHSApplication) {
      navigate("/nhs-application-form");
    } else {
      navigate("/new-application-form");
    }

    if (stepNumber === 0 || !stepNumber) {
      setActiveStep(stepNumber || 0);
      setCompletedStep([stepNumber || 0]);
    } else if ([1, 2, 3, 4].includes(stepNumber)) {
      setActiveStep(1);
      setCompletedStep([1]);
      setSharedFormIndex(stepNumber === 1 ? stepNumber : stepNumber - 1);
    } else if ([5, 6, 7].includes(stepNumber)) {
      setActiveStep(2);
      setCompletedStep([2]);
      setInvestmentFormIndex(stepNumber - 4);
    } else if ([8, 9].includes(stepNumber)) {
      setActiveStep(3);
      setCompletedStep([3]);
      setSubmitFormIndex(stepNumber - 7);
    } else {
      setActiveStep(5);
      setCompletedStep([5]);
    }
  };

  const handleNext = () => {
    const ningiJourneyStore =
      store.getState().organisation.organisation?.ningi_journey;
    const isNHSApplication =
      store.getState().multiForm?.formType === formType.NHS_APPLICATION_FORM
        ? true
        : false;
    if ((activeStep === 1 && sharedFormIndex === 1) || sharedFormIndex === 2) {
      setSharedFormIndex(sharedFormIndex + 1);
    } else if (activeStep === 3 && submitFormIndex === 1) {
      setSubmitFormIndex(submitFormIndex + 1);
    } else if (
      activeStep === 2 &&
      investmentFormIndex === 1 &&
      ningiJourneyStore
    ) {
      if (isNHSApplication) {
        setInvestmentFormIndex(investmentFormIndex + 2);
      } else {
        setInvestmentFormIndex(investmentFormIndex + 1);
      }
    } else if (
      activeStep === 2 &&
      investmentFormIndex === 2 &&
      ningiJourneyStore
    ) {
      setInvestmentFormIndex(investmentFormIndex + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSaveError("");
  };

  const handleBack = () => {
    const ningiJourneyStore =
      store.getState().organisation.organisation?.ningi_journey;
    const isNHSApplication =
      store.getState().multiForm?.formType === formType.NHS_APPLICATION_FORM
        ? true
        : false;
    if (activeStep === 3 && submitFormIndex === 2) {
      setSubmitFormIndex(submitFormIndex - 1);
    } else if (activeStep === 3) {
      setActiveStep(2);
      setInvestmentFormIndex(3);
    } else if (
      activeStep == 2 &&
      ningiJourneyStore &&
      investmentFormIndex == 2
    ) {
      setInvestmentFormIndex(investmentFormIndex - 1);
    } else if (
      activeStep == 2 &&
      ningiJourneyStore &&
      investmentFormIndex == 3
    ) {
      if (isNHSApplication) {
        setInvestmentFormIndex(investmentFormIndex - 2);
      } else {
        setInvestmentFormIndex(investmentFormIndex - 1);
      }
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSharedFormIndex(3);
    } else {
      if (activeStep === 1) {
        if (sharedFormIndex === 1) {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else if (sharedFormIndex === 2 || sharedFormIndex === 3) {
          setSharedFormIndex(sharedFormIndex - 1);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
    setSaveError("");
  };

  const handleReset = () => {
    setActiveStep(0);
    setSharedFormIndex(1);
    setInvestmentFormIndex(1);
    setSubmitFormIndex(1);
    setFormValues(initialValues);
  };

  const memoizedValue = useMemo(
    () => ({
      loading: false,
      isLoading,
      activeStep,
      setIsLoading,
      setActiveStep,
      setInitialValues,
      sharedFormIndex,
      setSharedFormIndex,
      investmentFormIndex,
      personalDetails,
      setInvestmentFormIndex,
      showJourneyCompleteDialog,
      setJourneyCompleteDialog,
      calculateAndSetActiveStep,
      setPersonalDetails,
      submitFormIndex,
      setSubmitFormIndex,
      handleNext,
      handleBack,
      handleReset,
      formValues,
      setFormValues,
      isLoadingSave,
      setIsLoadingSave,
      isLoadingNext,
      setIsLoadingNext,
      setBtnClicked,
      btnClicked,
      savedToast,
      setSavedToast,
      saveError,
      setSaveError,
      errorToast,
      setErrorToast,
      successToast,
      setSuccessToast,
      completedStep,
      setCompletedStep,
      trackingCode,
      setTrackingCode,
      fullScreenLoading,
      setFullScreenLoading,
      webinarAttendance,
      setWebinarAttendance,
      enableFetchUser,
      setEnableFetchUser,
    }),
    [
      isLoading,
      activeStep,
      sharedFormIndex,
      investmentFormIndex,
      submitFormIndex,
      personalDetails,
      showJourneyCompleteDialog,
      formValues,
      isLoadingSave,
      isLoadingNext,
      btnClicked,
      savedToast,
      saveError,
      errorToast,
      successToast,
      fullScreenLoading,
      webinarAttendance,
      enableFetchUser,
    ]
  );

  return (
    <StepperContext.Provider value={memoizedValue}>
      {children}
    </StepperContext.Provider>
  );
}

StepperProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useStepper() {
  return useContext(StepperContext);
}
