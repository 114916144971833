import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import SwitchButton from "../../../../generic-components/switch-button";
import DateInput from "../../../../generic-components/date-input";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ClericalOptions = ({ pensionType }) => {
  const { values, handleBlur, setFieldValue } = useFormikContext();
  const setDate = (value, setFieldValue) => {
    if (value) {
      setFieldValue("flexiblyAccessedDate", "");
    }
  };
  return (
    <>
      <Typography className="mt-30 stepper-content">
        <strong>{pensionType} AVC plans</strong>
      </Typography>
      <Typography className="mt-18 stepper-content">
        Have you flexibly accessed any pensions with us or any other provider?
      </Typography>
      <SwitchButton
        name="accessedPensionBtn"
        value={values.accessedPensionBtn}
        setValue={setDate}
        trackingDetails={TRACKING_NAF_OPTIONS}
      />
      {values.accessedPensionBtn && (
        <DateInput
          id="flexiblyAccessedDate"
          name="flexiblyAccessedDate"
          label="First payment date"
          trackingDetails={TRACKING_NAF_OPTIONS}
          value={values.flexiblyAccessedDate}
          handleChangeValue={(value) => {
            setFieldValue(
              "flexiblyAccessedDate",
              moment(value).format("YYYY-MM-DD")
            );
          }}
          handleBlurValue={handleBlur}
          styleClass="stepper-field"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pensionType: state.multiForm.pensionType,
  };
};

ClericalOptions.propTypes = {
  pensionType: PropTypes.string,
};

export default connect(mapStateToProps)(ClericalOptions);
