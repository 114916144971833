import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import PropTypes from "prop-types";

const PersonalDetailsTable = ({ tempPlan }) => {
  return (
    <Table className="table mt-18">
      <TableHead className="table-head">
        <TableRow>
          <TableCell align="center" colSpan={2} className="table-cell">
            Personal Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>Name</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {tempPlan.title} {tempPlan.first_name} {tempPlan.last_name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>Salary</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {tempPlan.annual_salary}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>NI Number</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {tempPlan.ni_number}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>Employee Number</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {tempPlan?.employee_number?.trim() || "N/A"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>Address</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {[
              tempPlan.delivery_address1,
              tempPlan.delivery_address2,
              tempPlan.delivery_town,
              tempPlan.delivery_county,
            ]
              .filter(Boolean)
              .join(", ")}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className="table-cell">
            <strong>Email</strong>
          </TableCell>
          <TableCell align="center" className="table-cell">
            {tempPlan.email}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

PersonalDetailsTable.propTypes = {
  tempPlan: PropTypes.object,
};

export default PersonalDetailsTable;
