import React, { useEffect, useRef } from "react";
import { TextField as MuiTextField } from "@mui/material";
import PropTypes from "prop-types";

function TextField({ id, name, label, value, ...props }) {
  const inputRef = useRef();
  useEffect(() => {
    if (!value && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [value]);

  return (
    <MuiTextField
      {...props}
      id={id}
      name={name}
      inputRef={inputRef}
      label={label}
      color="mmm"
      value={value}
      variant="outlined"
      className="mt-18 stepper-field"
      type="number"
    />
  );
}

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default TextField;
