import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import LoadingButton from "../button";

const ConfirmationDialog = ({
  open,
  onClose,
  isLoading,
  headerText,
  confirmationText,
  confirmBtnText,
  cancelBtnText,
  trackingDetails,
  onConfirmation,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog generic-confirmation-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">{headerText || ""}</Typography>
      </DialogTitle>
      <Box className="detail-heading-box">
        <Typography>{confirmationText || ""}</Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding={"1rem"}
        flexWrap="wrap"
      >
        <LoadingButton
          buttonTitle={cancelBtnText}
          trackingDetails={trackingDetails}
          disabled={isLoading}
          styleClass={"btn primary-clr-btn smallbtn confirmation-warning mt-10"}
          handleClick={onClose}
        />
        <LoadingButton
          buttonTitle={confirmBtnText}
          disabled={isLoading}
          loading={isLoading}
          trackingDetails={trackingDetails}
          styleClass={"btn primary-clr-btn mt-10"}
          handleClick={onConfirmation}
        />
      </Stack>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  headerText: PropTypes.string,
  confirmationText: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  trackingDetails: PropTypes.object,
  onConfirmation: PropTypes.func,
};

export default ConfirmationDialog;
