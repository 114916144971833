import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TRACKING_CONTACT_PAGE_OPTIONS } from "../../../../../constants";
import useStepper from "../../../../../hooks/useStepper";
import useUserEvent from "../../../../../hooks/useUserEvent";

const SideNavigator = ({
  heading,
  isHighlighted = false,
  handleClick,
  isLoading,
}) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  return (
    <Typography
      className={
        isHighlighted ? "side-navigation-highlighted " : "side-navigation-text"
      }
      onClick={() => {
        if (isLoading) {
          return;
        }
        userTrackingMutation({
          variables: {
            ...TRACKING_CONTACT_PAGE_OPTIONS,
            field_name: heading || "",
            field_value: "Clicked",
            avc_track_code: trackingCode,
          },
        });
        handleClick && handleClick();
      }}
    >
      {heading}
    </Typography>
  );
};

SideNavigator.propTypes = {
  heading: PropTypes.string,
  isHighlighted: PropTypes.bool,
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SideNavigator;
