import { gql } from "@apollo/client";

export const updateUserConsents = gql`
  mutation updateFundDetails($id: Int!) {
    update_temp_plans(
      where: { id: { _eq: $id } }
      _set: { show_ningi_journey_consent: false }
    ) {
      returning {
        id
      }
    }
  }
`;
