import React, { useRef } from "react";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import { Button } from "@mui/material";
import useStepper from "../../../hooks/useStepper";

const IconButton = ({
  buttonTitle,
  icon,
  extraStyles,
  trackingDetails,
  styleClass,
  link = "",
  handleClick,
  disabled,
  addButtonContent,
}) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();
  const iconButtonRef = useRef();

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: addButtonContent
          ? `${addButtonContent} ${element?.current?.textContent || ""}`
          : element.current?.textContent || "",
        field_value: element.current?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });

  return (
    <>
      <Button
        ref={iconButtonRef}
        className={styleClass}
        disabled={disabled}
        href={link}
        sx={extraStyles}
        startIcon={icon}
        onClick={(e) => {
          handleClick && handleClick(e);
          handleUserEvent(iconButtonRef);
        }}
      >
        {buttonTitle}
      </Button>
    </>
  );
};

IconButton.propTypes = {
  buttonTitle: PropTypes.string,
  trackingDetails: PropTypes.object,
  extraStyles: PropTypes.object,
  styleClass: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  addButtonContent: PropTypes.string,
};

export default IconButton;
