import { Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavigationLinks from "../../../generic-components/navigation-links";
import {
  mmmThemes,
  TRACKING_HEADER_OPTIONS,
  userRoles,
} from "../../../../constants";
import ReactLinks from "../../../generic-components/navigation-links/ReactLinks";
import { isNHSApplication } from "../../../../reducers/multiFormReducer";
import usePlan from "../../../../hooks/usePlan";
import useStepper from "../../../../hooks/useStepper";
import {
  getLegacyAppURL,
  mmmUniversalRedirection,
  parameterizedDetail,
} from "../../../../helpers";
import { useLocation } from "react-router-dom";
import { newApplicationFormPath } from "../../../../constants/path";

const HeaderLinksection = ({
  scheme,
  user,
  isNHS,
  organisation,
  sharedTitle,
}) => {
  const { pathname } = useLocation();
  const { personalDetails } = useStepper();
  const { isStandardAVCPlan, isOptOutPendingPlan } = usePlan();

  const isPayrollPath = pathname.includes(
    newApplicationFormPath.PAYROLL_REPORT
  );

  const legacyAppURL = getLegacyAppURL(personalDetails?.sub_domain);
  const adminBaseURL = `${legacyAppURL}/organisations/${parameterizedDetail(
    organisation?.id,
    organisation?.name
  )}`;

  const handleRedirectionURL = (defaultURL) => {
    const baseURL = `${legacyAppURL}/users/${user.id}/plans`;
    const isHomeURL = defaultURL === "/home";
    const incomingPlan = isOptOutPendingPlan || isStandardAVCPlan;
    if (isNHS) {
      return isHomeURL
        ? `${legacyAppURL}/schemes/my_salary_sacrifice_avc`
        : `${baseURL}?scheme_id=${scheme?.scheme_id}`;
    } else if (incomingPlan) {
      return isOptOutPendingPlan
        ? `${baseURL}/${incomingPlan?.id}/edit_my_avcwise`
        : `${baseURL}/${incomingPlan?.id}/show_my_avcwise`;
    } else {
      return defaultURL;
    }
  };

  return (
    <Box className="header-links-layout">
      <Box className="header-links-container">
        {!isPayrollPath ? (
          <>
            {personalDetails.role_name === userRoles.ORGANISATION_EMPLOYEE ? (
              <>
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={handleRedirectionURL("/home")}
                  name="Home"
                  navigateLink={
                    !(isNHS || isOptOutPendingPlan || isStandardAVCPlan)
                  }
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
                <NavigationLinks
                  styleClass="header-links nav-links"
                  name="Webinars"
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                  handleClick={() => {
                    mmmUniversalRedirection(
                      personalDetails?.sub_domain,
                      `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
                    );
                  }}
                />
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={handleRedirectionURL("/manage_my_shared_cost_avc")}
                  navigateLink={
                    !(isNHS || isOptOutPendingPlan || isStandardAVCPlan)
                  }
                  name={`Manage my ${sharedTitle}`}
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
                {user?.investment_guidance && (
                  <NavigationLinks
                    styleClass="header-links nav-links"
                    link={`${legacyAppURL}/schemes/${scheme.scheme_id}/avc_investment_guidance`}
                    name="My Investment Guidance"
                    trackingDetails={TRACKING_HEADER_OPTIONS}
                  />
                )}{" "}
                {organisation?.ningi_journey && (
                  <ReactLinks
                    styleClass="header-links nav-links"
                    link={`/my_investment_advice`}
                    name="My Investment Advice"
                    trackingDetails={TRACKING_HEADER_OPTIONS}
                  />
                )}{" "}
              </>
            ) : (
              <>
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={`${adminBaseURL}/dashboard`}
                  name="Dashboard"
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={`${adminBaseURL}/plans_management`}
                  name="Application Management"
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={`${adminBaseURL}/audit_history`}
                  name="Audit History"
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
                <NavigationLinks
                  styleClass="header-links nav-links"
                  link={`${adminBaseURL}/settings`}
                  name="Settings"
                  trackingDetails={TRACKING_HEADER_OPTIONS}
                />
              </>
            )}{" "}
          </>
        ) : null}
        {user?.backend_user && (
          <NavigationLinks
            styleClass="header-links nav-links"
            link="/change_portal"
            name="Change Organisation"
            navigateLink={true}
            trackingDetails={TRACKING_HEADER_OPTIONS}
          />
        )}
        {!isPayrollPath ? (
          <NavigationLinks
            styleClass="header-links nav-links"
            link={
              user?.latest_plan &&
              personalDetails.role_name === userRoles.ORGANISATION_EMPLOYEE
                ? "/contact"
                : process.env.REACT_APP_CONTACT_US_URL
            }
            navigateLink={
              user?.latest_plan &&
              personalDetails.role_name === userRoles.ORGANISATION_EMPLOYEE
            }
            name="Contact"
            trackingDetails={TRACKING_HEADER_OPTIONS}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    scheme: state.scheme.scheme,
    organisation: state.organisation.organisation,
    sharedTitle: state.multiForm.sharedTitle,
    isNHS: isNHSApplication(state),
  };
};

HeaderLinksection.propTypes = {
  user: PropTypes.object,
  organisation: PropTypes.object,
  scheme: PropTypes.object,
  sharedTitle: PropTypes.string,
  isNHS: PropTypes.bool,
};

export default connect(mapStateToProps)(HeaderLinksection);
