import { gql } from "@apollo/client";

export const updateSignature = gql`
  mutation UpdateSignature(
    $id: Int!
    $scheme_id: Int!
    $scheme_type_id: Int!
    $terms_acknowledged: Boolean!
    $signatures: String
    $prudential_declaration: Boolean
    $nhs_pension_scheme_benefits: Boolean
    $step_number: Int!
    $creation_reason_id: Int!
    $creation_reason_other: String
    $updated_at: timestamp
    $employee_number: String
    $title: String
    $gender: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_postcode: String
    $delivery_town: String
    $telephone_number: String
  ) {
    update_temp_plans(
      _set: {
        scheme_id: $scheme_id
        scheme_type_id: $scheme_type_id
        terms_acknowledged: $terms_acknowledged
        signatures: $signatures
        prudential_declaration: $prudential_declaration
        nhs_pension_scheme_benefits: $nhs_pension_scheme_benefits
        step_number: $step_number
        creation_reason_id: $creation_reason_id
        creation_reason_other: $creation_reason_other
        updated_at: $updated_at
        employee_number: $employee_number
        title: $title
        gender: $gender
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_county: $delivery_county
        date_of_birth: $date_of_birth
        email: $email
        first_name: $first_name
        last_name: $last_name
        mobile_number: $mobile_number
        ni_number: $ni_number
        delivery_postcode: $delivery_postcode
        delivery_town: $delivery_town
        telephone_number: $telephone_number
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        scheme_id
        scheme_type_id
        creation_reason_id
        creation_reason_other
        terms_acknowledged
        signatures
        prudential_declaration
        nhs_pension_scheme_benefits
        employee_number
        title
        gender
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        first_name
        last_name
        mobile_number
        ni_number
        delivery_postcode
        delivery_town
        telephone_number
      }
    }
  }
`;
