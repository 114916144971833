import { Box, Typography, Stack, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FormHeader from "../header/FormHeader";
import { useEffect, useMemo, useState } from "react";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import { getTax } from "../../../../../graphql/queries/getTax";
import Loader from "../../../../generic-components/loader";
import { connect, useDispatch } from "react-redux";
import { updatedAvcCost } from "../../../../../graphql/mutations/updatedAvcCost";
import { setForm } from "../../../../../reducers/formReducer";

import moment from "moment";
import { useToast } from "../../../../../hooks/useToast";
import { formatCurrency } from "../../../../../helpers";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import { setTempPlan } from "../../../../../reducers/tempPlan";

const SharedCostAVCAmountStep2 = ({ form, scheme, formSharedTitle }) => {
  const {
    handleNext,
    sharedFormIndex,
    activeStep,
    formValues,
    setFormValues,
    setIsLoadingSave,
    setIsLoadingNext,
    setSavedToast,
    setSaveError,
    setSuccessToast,
    setErrorToast,
    setActiveStep,
    setSharedFormIndex,
    loading,
  } = useStepper();
  const [open, setOpen] = useState(true);

  const [isUpdateAvc, setIsUpdateAvc] = useState(true);

  const contributedAvcAmount = useMemo(() => {
    if (!formValues.additional_avc_amount) {
      return Number(formValues.avcAmount || 0);
    }

    return Number(formValues.avcAmount || 0) + formValues.additional_avc_amount;
  }, [formValues.avcAmount, formValues.additional_avc_amount]);

  const [getSavings, { data, loading: taxLoading, error }] = useLazyQuery(
    getTax,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { showToast } = useToast();

  const incomeTaxSavingSacrifice =
    data?.plan_savings?.income_tax_saving_on_salary_sacrifice || 0;

  const grossValue = data?.plan_savings?.gross_value || 0;

  const nicSavingSacrifice =
    data?.plan_savings?.monthly_nic_saving_on_salary_sacrifice || 0;

  const updatedContributionAmount = useMemo(
    () => incomeTaxSavingSacrifice + nicSavingSacrifice,
    [data]
  );
  const [updateAvcCost] = useMutation(updatedAvcCost);
  const dispatch = useDispatch();

  const handleUpdateAvc = () => {
    const contributedActualAmount = parseFloat(grossValue);
    const updatedAVC = {
      avcAmount: contributedActualAmount,
      additional_avc_amount: 0,
      contribution_amount_updated: true,
      previous_amount_added: formValues.avcAmount,
    };

    setFormValues({ ...formValues, ...updatedAVC });
    dispatch(setTempPlan(updatedAVC));
    dispatch(setForm(updatedAVC));

    setSuccessToast(
      `Great we have updated your contribution amount to ${formatCurrency(
        contributedActualAmount
      )}`
    );
  };

  const handleResetAmount = () => {
    const resetData = {
      avcAmount: formValues.previous_amount_added,
      additional_avc_amount: 0,
      contribution_amount_updated: false,
      previous_amount_added: 0,
    };

    setFormValues({ ...formValues, ...resetData });
    dispatch(setTempPlan(resetData));
    dispatch(setForm(resetData));
  };

  const handleClick = (condition) => {
    setSaveError("");
    if (condition === "save_and_next") {
      setIsLoadingNext(true);
    } else if (condition === "save") {
      setIsLoadingSave(true);
    }
    updateAvcCost({
      variables: {
        id: form.id,
        updated_at: moment().format(),
        total_avc_amount_requested: parseFloat(contributedAvcAmount) || 0,
        additional_avc_amount: formValues.additional_avc_amount,
        contribution_amount_updated: !!formValues.contribution_amount_updated,
        previous_amount_added:
          parseFloat(formValues.previous_amount_added) || 0,
        step_number:
          condition === "save"
            ? activeStep + sharedFormIndex
            : activeStep + sharedFormIndex + 1,
      },
      onCompleted: (data) => {
        if (condition === "save") {
          setSavedToast(true);
        }
        dispatch(setForm(data.update_temp_plans.returning[0]));
        if (condition === "save_and_next") {
          handleNext();
        }
        setIsLoadingNext(false);
        setIsLoadingSave(false);
      },
      onError: (error) => {
        setIsLoadingNext(false);
        setIsLoadingSave(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      grossValue > formValues.income ||
      grossValue > Number(formValues.max_contribution_amount)
    ) {
      setIsUpdateAvc(false);
    } else {
      setIsUpdateAvc(true);
    }
  }, [data]);

  useEffect(() => {
    if (
      form &&
      form.annual_salary &&
      form.avc_interval &&
      contributedAvcAmount &&
      scheme.scheme_id
    ) {
      getSavings({
        variables: {
          annual_salary: form.annual_salary,
          total_avc_amount_requested: contributedAvcAmount,
          avc_interval: form.avc_interval,
          scheme_id: scheme.scheme_id,
          add_ni_savings_onto_avc: false,
        },
      });
    } else {
      setActiveStep(1);
      setSharedFormIndex(1);
      setSaveError({
        message: `Please provide the complete required details.`,
        overRideCustom: true,
      });
      setErrorToast(true);
    }
  }, [
    form,
    form.annual_salary,
    form.avc_interval,
    form.avcAmount,
    scheme,
    contributedAvcAmount,
  ]);

  const queryLoaders = useMemo(
    () => loading || taxLoading[(taxLoading, loading)]
  );

  const avcAmount = useMemo(
    () =>
      formValues.additional_avc_amount
        ? Number(formValues.avcAmount || 0) + formValues.additional_avc_amount
        : Number(formValues.avcAmount || 0),
    [formValues.avcAmount]
  );

  return (
    <Box className="shared-cost-avc-amount-step-2">
      {queryLoaders ? (
        <Box className="mt-30">
          <Loader />
        </Box>
      ) : (
        <>
          <Box className="application-page-container">
            {error && showToast(error.message, open, setOpen, "error")}
            <FormHeader
              heading={`${formSharedTitle} Amount`}
              amount={avcAmount}
            />
            <Stack direction="row">
              <ArrowRightIcon className="arrow-right" fontSize="large" />
              <Typography className="stepper-content">
                <strong>Ok –</strong> from the numbers you’ve told us; you want
                <strong className="success-text mr-5 ">
                  {" "}
                  {formatCurrency(contributedAvcAmount)}
                </strong>
                to go into your {formSharedTitle} each payroll.
              </Typography>
            </Stack>
            {taxLoading ? (
              <Loader />
            ) : (
              <Box className="info-container mt-30">
                <Typography className="primary-text">
                  <strong>Did you know?</strong>
                </Typography>
                <Typography className="info-text mt-12">
                  Thanks to the{" "}
                  <strong className="primary-text">
                    {formatCurrency(incomeTaxSavingSacrifice)}
                  </strong>{" "}
                  saving in Income Tax and the added benefit of the{" "}
                  <strong className="primary-text">
                    {formatCurrency(nicSavingSacrifice)}
                  </strong>{" "}
                  National Insurance contribution saving, we estimate this will
                  only cost you
                  <strong className="success-text">
                    {" "}
                    {formatCurrency(
                      parseFloat(contributedAvcAmount) -
                        updatedContributionAmount
                    )}
                    !
                  </strong>
                </Typography>
              </Box>
            )}

            {formValues.contribution_amount_updated ? (
              <>
                <Collapse in={!taxLoading}>
                  <LoadingButton
                    buttonTitle="Return to Previous Amount"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    handleClick={handleResetAmount}
                    styleClass="success-btn grey-color mt-18"
                  />
                </Collapse>
              </>
            ) : (
              !taxLoading &&
              isUpdateAvc && (
                <Collapse in={!taxLoading && isUpdateAvc}>
                  <Stack direction="row" alignItems="center" className="mt-30">
                    <ArrowRightIcon className="arrow-right" fontSize="large" />
                    <Typography className="stepper-content">
                      <strong>
                        Do you want it to cost you{" "}
                        {formatCurrency(contributedAvcAmount)} each payroll
                        instead?
                      </strong>
                    </Typography>
                  </Stack>
                  <Typography className="mt-18 stepper-content">
                    Then you need to change your contribution amount to
                    <strong className="primary-text">
                      {" "}
                      {formatCurrency(grossValue)}
                    </strong>{" "}
                    each payroll.
                  </Typography>
                  <Typography className="mt-30 stepper-content">
                    Click the button below and we’ll update the numbers in your
                    application accordingly. This means
                    <strong> {formatCurrency(grossValue)}</strong> will be paid
                    into your {formSharedTitle} pot, and we estimate it will
                    only cost you{" "}
                    <strong>{formatCurrency(contributedAvcAmount)}.*</strong>
                  </Typography>
                  <LoadingButton
                    buttonTitle="Update my contribution amount"
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    handleClick={handleUpdateAvc}
                    styleClass="success-btn mt-18"
                  />
                </Collapse>
              )
            )}
            <Typography className="grey-text mt-30 stepper-content">
              *The estimated cost to you from your take home pay each payroll is
              not guaranteed and could vary depending on your circumstances and
              the rate at which you pay Income Tax. These calculations are based
              on Income Tax at 20% (basic rate) and 40% (higher rate), and
              National Insurance contributions at 8% (basic rate) and 2% (higher
              rate).
            </Typography>
          </Box>
          <FooterButtons handleClick={handleClick} />
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form.form,
    scheme: state.scheme.scheme,
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

SharedCostAVCAmountStep2.propTypes = {
  form: PropTypes.object,
  scheme: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(SharedCostAVCAmountStep2);
