import { combineReducers } from "redux";
import userReducer from "./userReducer";
import organisationReducer from "./organisationReducer";
import formReducer from "./formReducer";
import investmentReducer from "./investmentReducer";
import schemeReducer from "./schemeReducer";
import tempPlanReducer from "./tempPlan";
import multiFormReducer from "./multiFormReducer";

const rootReducer = combineReducers({
  user: userReducer,
  organisation: organisationReducer,
  form: formReducer,
  investment: investmentReducer,
  scheme: schemeReducer,
  tempPlan: tempPlanReducer,
  multiForm: multiFormReducer,
});

export default rootReducer;
