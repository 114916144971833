import { Box, Container, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  mmmThemes,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../constants";
import { mmmUniversalRedirection } from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";
import LoadingButton from "../../../../generic-components/button";

const ManageMySharedCostWithNoPlans = ({ organisation }) => {
  const navigate = useNavigate();
  const { personalDetails } = useStepper();

  return (
    <Container>
      {organisation && (
        <Stack
          direction="row"
          justifyContent="center"
          className="organisation-large-logo"
        >
          <Box
            component="img"
            alt={organisation.name}
            className="organisation-logo"
            src={organisation.logo}
          />
        </Stack>
      )}
      <Box className="no-plan-description">
        <Typography className="mt-10">
          <strong>
            You don't have a Shared Cost AVC application or plan yet.
          </strong>
        </Typography>
        <Typography className="mt-10">
          Please click the button below to learn more about Shared Cost AVCs.
        </Typography>
        <LoadingButton
          buttonTitle="Click to learn more"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={() => {
            navigate("/home");
          }}
          styleClass="btn primary-clr-btn mt-30"
        />
      </Box>
      <Box className="no-plan-description mt-100 mb-30">
        <Typography className="strong-heading mt-50">
          <strong>Discover more using the below resources</strong>
        </Typography>
        <Stack
          className="bottom-header mt-12"
          direction={{ xs: "column", md: "row", sm: "row" }}
          justifyContent={{ xs: "center", md: "space-around" }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <LoadingButton
            buttonTitle="Book your place on a webinar"
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            handleClick={() => {
              mmmUniversalRedirection(
                personalDetails?.sub_domain,
                `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
              );
            }}
            styleClass="btn primary-clr-btn mt-30"
          />
        </Stack>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state.organisation.organisation,
  };
};

ManageMySharedCostWithNoPlans.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(ManageMySharedCostWithNoPlans);
