import React from "react";
import { Button, Typography, Box } from "@mui/material";
import customerServiceIcon from "../../../assets/images/customer-service-icon.png";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { useRef } from "react";
import useStepper from "../../../hooks/useStepper";

const IMOverlayButton = ({ trackingDetails }) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const IMBtnRef = useRef();

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: element?.current?.textContent || "IM Overlay button",
        field_value: element?.current?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });

  const handleRedirection = () => {
    if (process.env.REACT_APP_INDIVIDUAL_MEETING_URL) {
      window.location.href = process.env.REACT_APP_INDIVIDUAL_MEETING_URL;
    }
  };

  return (
    <>
      <Button
        ref={IMBtnRef}
        className="chatbtn imbtn"
        aria-label="IM Button"
        data-type="IM Button"
        onClick={() => {
          handleUserEvent(IMBtnRef);
          handleRedirection();
        }}
      >
        <Box
          component="img"
          src={customerServiceIcon}
          alt="im-support-icon"
          className="chat-icon"
        />
        <Typography className="chat-content ml-5">Book a 1-2-1</Typography>
      </Button>
    </>
  );
};

IMOverlayButton.propTypes = {
  trackingDetails: PropTypes.object,
};

export default IMOverlayButton;
