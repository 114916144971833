import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setTempPlan,
  setPlanStatus,
  setSubmitForm,
} from "../../../reducers/tempPlan";
import isEmpty from "lodash.isempty";
import PropTypes from "prop-types";
import useStepper from "../../../hooks/useStepper";

const SavePlan = ({ isCustomError }) => {
  const dispatch = useDispatch();
  const { activeStep } = useStepper();
  const { values, validateForm, isValid, submitForm } = useFormikContext();

  useEffect(() => {
    checkValidForm();
  }, [values, isValid, isCustomError]);

  const checkValidForm = async () => {
    try {
      if (isCustomError || !isValid) {
        dispatch(setPlanStatus(false));
      }

      if (isValid && !isCustomError) {
        const isValidForm = await validateForm();

        if (!isEmpty(isValidForm)) {
          dispatch(setPlanStatus(false));
          dispatch(setSubmitForm(submitForm));
        } else dispatch(setPlanStatus(true));
      }
    } catch (error) {
      dispatch(setPlanStatus(true));
      console.log("Error while validating form ", error);
    }

    if (activeStep === 1 && values.avcAmount && values.additional_avc_amount) {
      const avcAmount = values.additional_avc_amount
        ? Number(values.avcAmount) - values.additional_avc_amount
        : values.avcAmount;
      dispatch(setTempPlan({ ...values, avcAmount }));
      return;
    }

    dispatch(setTempPlan(values));
  };

  return <></>;
};

SavePlan.propTypes = {
  isCustomError: PropTypes.bool,
};

export default SavePlan;
