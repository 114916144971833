import { SSOService } from "..";

const ssoService = SSOService("/");

export default {
  getSSOActionURL(organisationId, token) {
    return ssoService({
      method: "POST",
      url: `api/v1/organisations/saml_sso?organisation_id=${organisationId}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
};
