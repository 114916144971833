import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TablePagination,
  Box,
  Container,
  TableSortLabel,
  Stack,
} from "@mui/material";
import LoadingButton from "../../generic-components/button";
import { TRACKING_PAYROLL_PAGE_OPTIONS } from "../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "../../generic-components/icon-btn";
import { useLazyQuery } from "@apollo/client";
import { getAllPayrollReports } from "../../../graphql/queries/payrollReport";
import Loader from "../../generic-components/loader";
import useStepper from "../../../hooks/useStepper";
import { useEffect, useState } from "react";

const columns = [
  {
    id: "generation_date",
    label: "Payroll generation date",
    align: "center",
  },
  {
    id: "month",
    label: "Payroll month",
    align: "center",
  },
  {
    id: "year",
    label: "Payroll year",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    align: "center",
  },
  {
    id: "version",
    label: "Version",
    align: "center",
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#391474",
    border: "1px solid #391474",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "0.625rem",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const PayrollReportList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setErrorToast, setSaveError } = useStepper();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [isLoading, setIsLoading] = useState(true);
  const [payrollReports, setPayrollReports] = useState([]);

  const [fetchPayrollReports] = useLazyQuery(getAllPayrollReports);

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  const getPayrollReports = async () => {
    setIsLoading(true);
    fetchPayrollReports({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1),
        organisationId: Number(params?.organisationId) || 0,
        orderBy: { [orderBy]: order },
      },
      onCompleted: (data) => {
        setPayrollReports(data?.payroll_reports || []);
        setCount(data?.payroll_reports_aggregate?.aggregate?.count || 0);
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    getPayrollReports();
  }, [page, rowsPerPage, orderBy, order]);

  return (
    <Box className="application-page-container mt-150 mb-100">
      <SharedHeader heading="Payroll Reports" displayLogo={false} />
      <Container>
        <IconButton
          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
          buttonTitle="Back to Details"
          styleClass="light-backbtn mb-10"
          handleClick={() =>
            navigate(
              `/payroll_report/organisation/edit/${encodeURIComponent(
                params?.organisationName
              )}/${params?.organisationId}`
            )
          }
          icon={<KeyboardArrowLeftIcon />}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleSortChange(column.id)}
                        sx={{
                          "&.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                            color: "white !important",
                          },
                          "&.MuiTableSortLabel-root": {
                            color: "white !important",
                          },
                          "&.Mui-active": {
                            color: "white !important",
                          },
                        }}
                      >
                        {column?.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell align="center" width="35%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    <Loader size={35} />
                  </StyledTableCell>
                </StyledTableRow>
              ) : payrollReports?.length ? (
                payrollReports?.map((payroll) => (
                  <StyledTableRow key={payroll?.id}>
                    {columns.map((column) => {
                      return (
                        <StyledTableCell key={column?.id} align="center">
                          {payroll[column?.id]}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell align="center">
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                      >
                        <LoadingButton
                          buttonTitle="Details"
                          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                          handleClick={() =>
                            navigate(
                              `/payroll_report/details/${encodeURIComponent(
                                params?.organisationName
                              )}/${params?.organisationId}/${payroll?.id}`
                            )
                          }
                          styleClass={"btn primary-clr-btn smallbtn"}
                        />
                        <LoadingButton
                          buttonTitle="Edit"
                          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                          handleClick={() =>
                            navigate(
                              `/payroll_report/edit/${encodeURIComponent(
                                params?.organisationName
                              )}/${params?.organisationId}/${payroll?.id}`
                            )
                          }
                          styleClass={"btn primary-clr-btn smallbtn"}
                        />
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    No records found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[4, 8, 12]}
          count={count}
          labelRowsPerPage="Records per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Container>
    </Box>
  );
};

export default PayrollReportList;
