import { AuthorizedService } from "..";

const addressService = AuthorizedService("/");

export default {
  getAddress(term) {
    return addressService({
      method: "GET",
      url: `autocomplete/${term}?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}&all=true`,
    });
  },
  getAddressById(id) {
    return addressService({
      method: "GET",
      url: `get/${id}?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}`,
    });
  },
};
