import { Box, Container } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SharedHeader from "../../../../generic-components/shared-header/SharedHeader";
import CustomerServiceFooter from "../manage-my-shared-cost-content/CustomerServiceFooter";
import { useEffect } from "react";
import Loader from "../../../../generic-components/loader";
import AmendMySharedCostContent from "./amend-my-shared-cost-content/AmendMySharedCostContent";
import usePlan from "../../../../../hooks/usePlan";
import usePlanDetails from "../../../../../hooks/usePlanDetails";

const AmendMySharedCostAVC = ({ user }) => {
  const { plans } = usePlan();
  const { fetchCompletePlanDetails, isPlanLoading } = usePlanDetails();

  useEffect(() => {
    if (user.id && user.organisation_id) {
      fetchCompletePlanDetails(user.id, user.organisation_id);
    }
  }, [user.organisation_id, user.id]);

  return (
    <Box className="application-page-container space-from-top">
      <SharedHeader heading="My Shared Cost AVC" />
      <Container>
        {isPlanLoading ? (
          <Box className="mt-200">
            <Loader />
          </Box>
        ) : (
          <>
            <AmendMySharedCostContent plan={plans.find((plan) => plan)} />
            <CustomerServiceFooter />
          </>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
  };
};

AmendMySharedCostAVC.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(AmendMySharedCostAVC);
