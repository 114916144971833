import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const ProviderNote = ({ providerName }) => {
  const { values } = useFormikContext();
  const getProviderNote = () => {
    switch (providerName) {
      case "Prudential":
        return (
          values.lifestyleCheckbox && (
            <Typography className="stepper-content mt-18">
              Lifestyling Option (please indicate below which profile
              name/number you would like to choose):
            </Typography>
          )
        );
      case "Standard Life":
        return (
          <Typography className="stepper-content mt-18">
            <strong>Please note:</strong> If you select a Lifestyle Fund (please
            see fund guide) and you do not wish to invest the full 100% of your
            contributions in that fund, the only other fund available is the
            With Profits Fund.
          </Typography>
        );

      case "Clerical Medical":
        return (
          <Typography className="stepper-content mt-18">
            <strong>Please note:</strong> If you select a Lifestyle Fund and you
            do not wish to invest the full 100% of your contributions in that
            fund, you can only select fund(s) from Clerical Medical’s range of
            performance pension funds (please see fund guide)
          </Typography>
        );
      case "Legal & General":
        return (
          <Typography className="stepper-content mt-18">
            <strong>Please note:</strong> If you select the Lifestyle Fund
            (please see fund guide) you cannot select any other funds.
          </Typography>
        );
    }
  };
  return getProviderNote();
};

ProviderNote.propTypes = {
  providerName: PropTypes.string,
};

export default ProviderNote;
