import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import OrganisationSelect from "./Forms/OrganisationSelect";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PersonalDetails from "./Forms/PersonalDetails";
import { RegistrationTerms } from "./Forms/RegistrationTerms";
import SharedHeaderRegister from "../register-screen/SharedHeaderRegister";
import { useNavigate } from "react-router-dom";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(14,194,14) 0%,rgb(14,194,14) 50%,rgb(14,194,14) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(14,194,14) 0%,rgb(14,194,14) 50%,rgb(14,194,14) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? "green" : "white",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "white",
  zIndex: 1,
  color: "#fff",
  width: "27px",
  height: "27px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(14,194,14) 0%, rgb(14,194,14) 50%, rgb(14,194,14) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(14,194,14) 0%, rgb(14,194,14) 50%, rgb(14,194,14) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Check />,
    2: <Check />,
    3: <Check />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Step 1", "Step 2", "Step 3"];

export default function RegisterationStepper({ setsignInFooter }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setsignInFooter(false);
  };

  const handleBack = () => {
    activeStep == 1 ? setsignInFooter(true) : setsignInFooter(false);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [organisation, setOrganisation] = React.useState("");

  const handleChange = (event) => {
    setOrganisation(event.target.value);
  };

  const navigate = useNavigate();
  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <OrganisationSelect
            handleChange={handleChange}
            organisation={organisation}
          />
        );
      case 1:
        return <PersonalDetails />;

      case 2:
        return <RegistrationTerms />;

      default:
        break;
    }
  };

  return (
    <>
      <SharedHeaderRegister
        heading="Registration"
        organisation={organisation}
        activeStep={activeStep}
      />
      <Box className="registration-stepper mt-30">
        <Box className="registration-stepper-container">
          <Stepper
            className="Stepper"
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <Typography className="StepperLable">{label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === steps.length ? (
          navigate("/")
        ) : (
          <>
            <Box className="mb-30" sx={{ mt: 2, mb: 1 }}>
              {renderSteps()}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep === 0 ? null : (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: "#236AA3" }}
                  className="backbtn"
                >
                  <KeyboardArrowLeftIcon />
                  Back
                </Button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                variant="contained"
                className="contained-btn btn"
              >
                {activeStep === steps.length - 1 ? "Register" : "Next"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

RegisterationStepper.propTypes = {
  heading: PropTypes.string,
  setsignInFooter: PropTypes.bool,
};
