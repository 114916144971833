import FormikCheckbox from "../../../../generic-components/formik-checkbox";
import { Box, FormControl, Typography, FormHelperText } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { checkFieldError, getLegacyAppURL } from "../../../../../helpers";
import FormHeader from "../header/FormHeader";
import { Formik } from "formik";
import {
  AcknowledgementAndSignatureValidationSchema,
  AcknowledgementAndSignatureValidationSchemaWithoutPrudential,
} from "../../../../../schema";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation, useLazyQuery } from "@apollo/client";
import { updateSignature } from "../../../../../graphql/mutations/updateSignature";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { convertToPlan } from "../../../../../graphql/queries/convertToPlan";
import { fetchLinks } from "../../../../../graphql/queries/fetchLinks";
import Loader from "../../../../generic-components/loader";
import { useNavigate } from "react-router-dom";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import moment from "moment";
import { getProviders } from "../../../../../graphql/queries/providers";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";
import useUserEvent from "../../../../../hooks/useUserEvent";
import LoadingButton from "../../../../generic-components/button";
import SavePlan from "../../../../generic-components/save-plan";
import { getPlanReasons } from "../../../../../graphql/queries/getPlanReasons";
import FormSelect from "../../../../generic-components/form-select";
import TextInput from "../../../../generic-components/input-text";
import MaxContributionCalculationForm from "../../../../screens/registered-user/shared-cost-application/forms/MaxContributionCalculationForm";
import { getUserDetails } from "../../../../../graphql/queries/user";
import useValidator from "../../../../../hooks/useValidator";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";

const AcknowledgementAndSignature = ({
  form,
  scheme,
  formSharedTitle,
  isNHSApplication,
}) => {
  const {
    setFormValues,
    formValues,
    activeStep,
    setSavedToast,
    setSaveError,
    personalDetails,
    btnClicked,
    setIsLoadingNext,
    setIsLoadingSave,
    setErrorToast,
    loading,
    setActiveStep,
    setIsLoading,
    trackingCode,
    setInvestmentFormIndex,
    setSubmitFormIndex,
    setSharedFormIndex,
  } = useStepper();

  const { validateApplicationForm } = useValidator();

  const [providerName, setProviderName] = useState("");
  const [faqLink, setFaqLink] = useState("");
  const [termAndConditionLink, setTermAndConditionLink] = useState("");
  const [providers, setProviders] = useState([]);
  const [planReasons, setPlanReasons] = useState([]);
  const navigate = useNavigate();

  const [getPlanReasonList, { loading: planReasonsLoading }] = useLazyQuery(
    getPlanReasons,
    {
      onCompleted: (data) => {
        const planSubmitReason =
          data?.plan_reasons?.map((reason) => ({
            name: reason.name,
            value: reason.id,
          })) || [];

        if (planSubmitReason.length)
          planSubmitReason.push({ name: "Other", value: -1 });
        setPlanReasons(planSubmitReason);
      },
      onError: (error) => {
        console.log(error);
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const avcAmount = useMemo(
    () =>
      formValues.additional_avc_amount
        ? Number(formValues.avcAmount || 0) + formValues.additional_avc_amount
        : Number(formValues.avcAmount || 0),
    [formValues.avcAmount]
  );

  const [getLinks, { loading: linksLoading }] = useLazyQuery(fetchLinks, {
    variables: {
      scheme_id: scheme.scheme_id,
    },
    onCompleted: (data) => {
      setFaqLink(
        data.scheme_details.faq.startsWith("https") ||
          data.scheme_details.faq.startsWith("http")
          ? data.scheme_details.faq
          : `${getLegacyAppURL(personalDetails?.sub_domain)}${
              data.scheme_details.faq
            }`
      );
      setTermAndConditionLink(
        data.scheme_details.terms_and_conditions.startsWith("https") ||
          data.scheme_details.terms_and_conditions.startsWith("http")
          ? data.scheme_details.terms_and_conditions
          : `${getLegacyAppURL(personalDetails?.sub_domain)}${
              data.scheme_details.terms_and_conditions
            }`
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [setUpdateSignature] = useMutation(updateSignature);

  const [getProvidersList, { loading: providersLoading }] = useLazyQuery(
    getProviders,
    {
      variables: {
        id: personalDetails.organisation_id,
      },
      onCompleted: (data) => {
        const providers =
          data.providers?.map((provider) => ({
            name: provider.name,
            value: provider.id,
          })) || [];

        setProviders(providers);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const [createPlan] = useMutation(convertToPlan);
  const initialValues = {
    termCondition: formValues.termCondition,
    pensionSchemeBenefits: formValues.pensionSchemeBenefits || false,
    signature: formValues.signature,
    prudentialConsent: formValues.prudentialConsent,
    planReason: formValues.planReason === 0 ? -1 : formValues.planReason,
    otherReason: formValues.otherReason || "",
    isPlanReasons: !!planReasons?.length,
    income: formValues.income,
    hours_per_week: formValues.hours_per_week,
    age: formValues.age,
    max_contribution_amount: formValues.max_contribution_amount,
  };

  const sigCanvas = useRef();

  const handleClear = (setFieldValue) => {
    setFieldValue("signature", "");
    sigCanvas.current.clear();
  };

  const [fetchUserDetails] = useLazyQuery(getUserDetails, {
    fetchPolicy: "no-cache",
  });

  const handleUpdate = (values) => {
    setSaveError("");
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    if (btnClicked === "save_and_next") {
      setIsLoadingNext(true);
    } else if (btnClicked === "save") {
      setIsLoadingSave(true);
    }

    fetchUserDetails({
      onCompleted: (data) => {
        const personalDetail = data?.me || {};

        if (data?.me?.latest_temp_plan) {
          setUpdateSignature({
            variables: {
              id: form.id,
              terms_acknowledged: values.termCondition,
              signatures: values.signature,
              step_number: activeStep + 6,
              prudential_declaration: values.prudentialConsent,
              nhs_pension_scheme_benefits: values.pensionSchemeBenefits,
              updated_at: moment().format(),
              employee_number: personalDetail?.employee_number?.trim(),
              title: personalDetail.title,
              gender: personalDetail.gender,
              delivery_address1: personalDetail.address1,
              creation_reason_id:
                values.planReason === -1 ? 0 : values.planReason,
              creation_reason_other: values.otherReason,
              delivery_address2: personalDetail.address2,
              delivery_county: personalDetail.county,
              date_of_birth: personalDetail.date_of_birth,
              email: personalDetail.email,
              first_name: personalDetail.first_name,
              last_name: personalDetail.last_name,
              mobile_number: personalDetail.mobile_number,
              ni_number: personalDetail.ni_number,
              delivery_postcode: personalDetail.postcode,
              delivery_town: personalDetail.town,
              telephone_number: personalDetail.telephone_number,
              scheme_id: scheme.scheme_id,
              scheme_type_id: scheme.scheme_type_id,
            },
            onCompleted: () => {
              const validationErrors = validateApplicationForm(
                form,
                personalDetail
              );

              if (validationErrors) {
                if (validationErrors.step === 2) {
                  setInvestmentFormIndex(3);
                  setSubmitFormIndex(1);
                }

                if (validationErrors.step === 1) {
                  setSharedFormIndex(1);
                  setInvestmentFormIndex(1);
                  setSubmitFormIndex(1);
                }

                setActiveStep(validationErrors.step);
                setIsLoadingNext(false);
                setIsLoadingSave(false);
                setSaveError({
                  message:
                    "There is some information missing. Please re-validate your application before moving further",
                  overRideCustom: true,
                });
                setErrorToast(true);
                return;
              }

              if (avcAmount > values.max_contribution_amount) {
                setIsLoading(true);
                setActiveStep(1);
                setSharedFormIndex(1);
                setIsLoadingNext(false);
                setIsLoadingSave(false);
                setSaveError({
                  message: `Please check requested AVC amount.`,
                  overRideCustom: true,
                });
                setErrorToast(true);
                setIsLoading(false);

                return;
              }

              if (btnClicked === "save_and_next") {
                createPlan({
                  variables: {
                    temp_plan_id: form.id,
                  },
                  onCompleted: (data) => {
                    navigate("/thankyou", {
                      state: { id: data?.convert_to_plan?.id },
                    });
                    setActiveStep(0);
                    setInvestmentFormIndex(1);
                    setSharedFormIndex(1);
                    setSubmitFormIndex(1);
                  },
                  onError: (error) => {
                    setIsLoadingNext(false);
                    setIsLoadingSave(false);
                    setSaveError(error);
                    setErrorToast(true);
                  },
                });
              } else {
                setSavedToast(true);
                setIsLoadingNext(false);
                setIsLoadingSave(false);
              }
            },
            onError: (error) => {
              setIsLoadingNext(false);
              setIsLoadingSave(false);
              setSaveError(error);
              setErrorToast(true);
            },
          });
        } else {
          setSaveError({
            message: "Plan already had been created.",
            overRideCustom: true,
          });
          setErrorToast(true);
          setIsLoadingNext(false);
          setIsLoadingSave(false);
          navigate("/manage_my_shared_cost_avc");
          return;
        }
      },
      onError: (error) => {
        setIsLoadingNext(false);
        setIsLoadingSave(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProvidersList();
  }, []);

  useEffect(() => {
    setProviderName(
      providers.find((provider) => provider.value === formValues.provider)?.name
    );
  }, [formValues, providers]);

  useEffect(() => {
    getLinks();
    getPlanReasonList();
  }, []);

  const queryLoaders = useMemo(
    () =>
      loading ||
      linksLoading[(loading, linksLoading)] ||
      providersLoading ||
      planReasonsLoading
  );

  useEffect(() => {
    if (formValues.signature && sigCanvas.current) {
      sigCanvas.current.fromDataURL(formValues.signature);
    }
  }, [formValues.signature, sigCanvas.current]);

  const { userTrackingMutation } = useUserEvent();

  const handleUserEvent = (element) =>
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: element?.current?._sigPad?.name || "signature",
        field_value: element?.current?._sigPad?.name || "signature",
        avc_track_code: trackingCode,
      },
    });

  const handleOtherReason = (_, value, setFieldValue) => {
    if (value !== -1) {
      setFieldValue("otherReason", "");
    }
  };

  return (
    <Formik
      validationSchema={
        providerName === "Prudential"
          ? AcknowledgementAndSignatureValidationSchema
          : AcknowledgementAndSignatureValidationSchemaWithoutPrudential
      }
      enableReinitialize
      validateOnMount
      initialValues={initialValues}
      onSubmit={(values) => handleUpdate(values)}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue, isValid }) => (
        <form onSubmit={handleSubmit}>
          {queryLoaders ? (
            <Box className="mt-30">
              <Loader />
            </Box>
          ) : (
            <Box className="Acknowledgement-and-signature">
              <ScrollToFieldError />
              <MaxContributionCalculationForm />
              <SavePlan />
              <Box className="application-page-container">
                <FormHeader
                  heading="Acknowledgement and signature"
                  amount={avcAmount}
                />
                <FormikCheckbox
                  trackingDetails={TRACKING_NAF_OPTIONS}
                  name="termCondition"
                  label={`By ticking this box I confirm that I have read and understand the <a class="web-link" href=${termAndConditionLink} target="_blank"
                rel="noreferrer">Terms and Conditions</a> and <a class="web-link" href=${faqLink} target="_blank"
                rel="noreferrer">Frequently Asked Questions</a> information and accept the change to the terms and conditions of my employment`}
                />

                {providerName === "Prudential" && (
                  <FormikCheckbox
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    name="prudentialConsent"
                    label={`I confirm that I have read and understand the <a class="web-link" href=${getLegacyAppURL(
                      personalDetails?.sub_domain
                    )}/Prudential_AVC_Declaration.pdf target="_blank"
                    rel="noreferrer">Prudential Declaration</a> and I give consent for My Money Matters to submit my application to Prudential on my behalf, using only the information I have provided in my ${formSharedTitle} application.`}
                  />
                )}
                {isNHSApplication && (
                  <FormikCheckbox
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    name="pensionSchemeBenefits"
                    label={
                      "I acknowledge the impacts of Salary sacrifice on my NHS Pension Scheme benefits. I have fully considered these impacts on my participation in the scheme and agree to proceed"
                    }
                  />
                )}
                {providerName === "Prudential" ? (
                  <Box
                    className={`signature-container mt-30 ${
                      providerName === "Prudential"
                        ? "visible-sign"
                        : "hide-sign"
                    }`}
                  >
                    <FormControl className="form-control">
                      <Box className="signature mb-18">
                        <Typography className="signature-heading">
                          Signature*
                        </Typography>
                        <LoadingButton
                          buttonTitle="Clear"
                          trackingDetails={TRACKING_NAF_OPTIONS}
                          handleClick={() => handleClear(setFieldValue)}
                          styleClass="clear-btn"
                        />
                      </Box>
                      <SignaturePad
                        ref={sigCanvas}
                        penColor="black"
                        id="signature"
                        name="signature"
                        clearOnResize={false}
                        canvasProps={{
                          style: {
                            backgroundColor: "#F8F8F8",
                            height: "19.5rem",
                            width: "100%",
                          },
                        }}
                        onEnd={() => {
                          handleUserEvent(sigCanvas);
                          if (sigCanvas.current) {
                            setFieldValue(
                              "signature",
                              sigCanvas.current.toDataURL("image/png")
                            );
                          }
                        }}
                      />
                      <FormHelperText
                        error={checkFieldError(touched, errors, "signature")}
                      >
                        {checkFieldError(touched, errors, "signature")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                ) : null}
                {!!planReasons?.length && (
                  <>
                    <FormSelect
                      name="planReason"
                      labelId="planReason"
                      label="Why have you decided to apply?"
                      menuItems={planReasons}
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      handleCustomChange={(_, value, setFieldValue) => {
                        handleOtherReason(null, value, setFieldValue);
                      }}
                    />
                    {values.planReason === -1 && (
                      <TextInput
                        id="otherReason"
                        name="otherReason"
                        label="Other Reason"
                        trackingDetails={TRACKING_NAF_OPTIONS}
                        value={values.otherReason || ""}
                      />
                    )}
                  </>
                )}
              </Box>
              <FooterButtons isValid={isValid} />
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    form: state.form.form,
    scheme: state.scheme.scheme,
    formSharedTitle: state.multiForm.formSharedTitle,
    isNHSApplication: isNHSApplication(state),
  };
};

AcknowledgementAndSignature.propTypes = {
  organisation: PropTypes.object,
  form: PropTypes.object,
  scheme: PropTypes.object,
  formSharedTitle: PropTypes.string,
  isNHSApplication: PropTypes.bool,
};

export default connect(mapStateToProps)(AcknowledgementAndSignature);
