import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../../constants";
import LoadingButton from "../../../../../generic-components/button";

const CancelPlanConfirmationDialog = ({ open, onClose, setCancel }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog"
    >
      <DialogTitle className="dialog-header cancel-header">
        <Typography className="dialog-heading">Cancel Application</Typography>
      </DialogTitle>
      <Box className="detail-heading-box">
        <Typography>
          By cancelling my Shared Cost AVC plan, I understand I will have to go
          through the new application process again if I change my mind in the
          future.
        </Typography>
      </Box>
      <Stack
        className="mt-18 mb-30 p-lr-50"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between", xs: "center" }}
        alignItems="center"
        spacing={2}
      >
        <LoadingButton
          buttonTitle="Do not cancel"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={() => {
            onClose();
            navigate("/manage_my_shared_cost_avc");
          }}
          styleClass="`btn cancelbtn disable-text-transform thinbtn "
        />
        <LoadingButton
          buttonTitle="I want to cancel"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={() => {
            onClose();
            setCancel(true);
          }}
          styleClass="service-button disable-text-transform confirmation-warning thinbtn"
        />
      </Stack>
    </Dialog>
  );
};

CancelPlanConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setCancel: PropTypes.func,
};

export default CancelPlanConfirmationDialog;
