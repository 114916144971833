import { gql } from "@apollo/client";

export const updatedStepNumber = gql`
  mutation updatedStepNumber(
    $id: Int!
    $step_number: Int!
    $updated_at: timestamp
  ) {
    update_temp_plans(
      _set: { step_number: $step_number, updated_at: $updated_at }
      where: { id: { _eq: $id } }
    ) {
      returning {
        step_number
      }
    }
  }
`;
