import { gql } from "@apollo/client";

export const getDistinctEvents = gql`
  query getDistinctEvents(
    $organisation_id: Int!
    $limit: Int
    $offset: Int
    $current_date: date
    $current_time: time
    $extra_filter: presentations_bool_exp!
    $distinct_on: [presentations_select_column!]
  ) {
    presentations(
      distinct_on: $distinct_on
      order_by: { title: asc }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $current_time } }
                  { presentation_date: { _eq: $current_date } }
                ]
              }
              { presentation_date: { _gt: $current_date } }
            ]
          }
          {
            _or: [
              { upload_start_date: { _lte: $current_date } }
              { upload_start_date: { _is_null: true } }
            ]
          }
          $extra_filter
        ]
      }
    ) {
      id
      title
      target_audience
      presentation_date
      presentation_time
      booked_places
      number_of_places
      presentation_end_time
      upload_start_date
    }
    presentations_aggregate(
      distinct_on: $distinct_on
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $current_time } }
                  { presentation_date: { _eq: $current_date } }
                ]
              }
              { presentation_date: { _gt: $current_date } }
            ]
          }
          {
            _or: [
              { upload_start_date: { _lte: $current_date } }
              { upload_start_date: { _is_null: true } }
            ]
          }
          $extra_filter
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getRelatedEvents = gql`
  query getRelatedEvents(
    $organisation_id: Int!
    $user_id: Int!
    $title: String
    $limit: Int
    $offset: Int
    $current_date: date
    $current_time: time
  ) {
    presentations(
      order_by: { presentation_date: asc }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          { title: { _eq: $title } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $current_time } }
                  { presentation_date: { _eq: $current_date } }
                ]
              }
              { presentation_date: { _gt: $current_date } }
            ]
          }
          {
            _or: [
              { upload_start_date: { _lte: $current_date } }
              { upload_start_date: { _is_null: true } }
            ]
          }
        ]
      }
    ) {
      id
      title
      target_audience
      presentation_date
      number_of_places
      booked_places
      presentation_time
      presentation_end_time
      presentation_type
      webinar_link
      upload_start_date
      booked_user_presentations(
        where: {
          _and: [
            { user_id: { _eq: $user_id } }
            { organisation_id: { _eq: $organisation_id } }
          ]
        }
      ) {
        id
        email
        user_id
        cancelled_at
        attended
      }
    }
    presentations_aggregate(
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          { title: { _eq: $title } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $current_time } }
                  { presentation_date: { _eq: $current_date } }
                ]
              }
              { presentation_date: { _gt: $current_date } }
            ]
          }
          {
            _or: [
              { upload_start_date: { _lte: $current_date } }
              { upload_start_date: { _is_null: true } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getPeronalBookings = gql`
  query getPeronalBookings(
    $organisation_id: Int!
    $user_id: Int!
    $limit: Int
    $offset: Int
    $current_date: date
    $current_time: time
  ) {
    booked_user_presentations(
      order_by: { presentation: { presentation_date: asc } }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          { organisation_id: { _eq: $organisation_id } }
          { cancelled_at: { _is_null: true } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _gt: $current_time } }
                        { presentation_date: { _eq: $current_date } }
                      ]
                    }
                    { presentation_date: { _gt: $current_date } }
                  ]
                }
                {
                  _or: [
                    { upload_start_date: { _lte: $current_date } }
                    { upload_start_date: { _is_null: true } }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      id
      user_id
      attended
      cancelled_at
      presentation_id
      organisation_id
      five_years_to_retirement
      presentation {
        id
        title
        webinar_link
        target_audience
        presentation_date
        presentation_time
        presentation_end_time
        presentation_type
        upload_start_date
      }
    }
    booked_user_presentations_aggregate(
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          { organisation_id: { _eq: $organisation_id } }
          { cancelled_at: { _is_null: true } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _gt: $current_time } }
                        { presentation_date: { _eq: $current_date } }
                      ]
                    }
                    { presentation_date: { _gt: $current_date } }
                  ]
                }
                {
                  _or: [
                    { upload_start_date: { _lte: $current_date } }
                    { upload_start_date: { _is_null: true } }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getWebinarAttendance = gql`
  query getWebinarAttendance($organisation_id: Int!, $user_id: Int!) {
    booked_user_presentations_aggregate(
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          { organisation_id: { _eq: $organisation_id } }
          { attended: { _eq: true } }
          { presentation: { presentation_type: { _eq: "Webinar" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getPastWebinarsForPartners = gql`
  query getPastWebinars(
    $organisation_id: Int!
    $limit: Int
    $offset: Int
    $presentation_date: date
    $presentation_time: time
    $presentation_filters: presentations_bool_exp!
  ) {
    presentations(
      order_by: { presentation_date: desc }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          { presentation_type: { _eq: "Webinar" } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _lt: $presentation_time } }
                  { presentation_date: { _eq: $presentation_date } }
                ]
              }
              { presentation_date: { _lt: $presentation_date } }
            ]
          }
          $presentation_filters
        ]
      }
    ) {
      id
      title
      enabled
      presentation_type
      presentation_time
      presentation_end_time
      presentation_date
      target_audience
      booked_places
      number_of_places
      bookings: booked_user_presentations_aggregate(
        where: {
          _and: [
            { organisation_id: { _eq: $organisation_id } }
            { cancelled_at: { _is_null: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      attendees: booked_user_presentations_aggregate(
        where: {
          _and: [
            { attended: { _eq: true } }
            { cancelled_at: { _is_null: true } }
            { organisation_id: { _eq: $organisation_id } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      newPlans: total_webinar_conversions_aggregate(
        where: {
          _and: [
            { plan: { plan_type: { _eq: "New Plan" } } }
            { organisation_id: { _eq: $organisation_id } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      amendedPlans: total_webinar_conversions_aggregate(
        where: {
          _and: [
            { plan: { plan_type: { _eq: "Amended Plan" } } }
            { organisation_id: { _eq: $organisation_id } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      plansCount: total_webinar_conversions(
        where: {
          _and: [
            { plan: { plan_type: { _in: ["Amended Plan", "New Plan"] } } }
            { organisation_id: { _eq: $organisation_id } }
          ]
        }
      ) {
        plan {
          total_avc_amount_requested
        }
      }
    }
    presentations_aggregate(
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { enabled: { _eq: true } }
          { presentation_type: { _eq: "Webinar" } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _lt: $presentation_time } }
                  { presentation_date: { _eq: $presentation_date } }
                ]
              }
              { presentation_date: { _lt: $presentation_date } }
            ]
          }
          $presentation_filters
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    bookings: booked_user_presentations_aggregate(
      where: {
        _and: [
          { cancelled_at: { _is_null: true } }
          { organisation_id: { _eq: $organisation_id } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                { presentation_type: { _eq: "Webinar" } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _lt: $presentation_time } }
                        { presentation_date: { _eq: $presentation_date } }
                      ]
                    }
                    { presentation_date: { _lt: $presentation_date } }
                  ]
                }
                $presentation_filters
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    attendees: booked_user_presentations_aggregate(
      where: {
        _and: [
          { attended: { _eq: true } }
          { cancelled_at: { _is_null: true } }
          { organisation_id: { _eq: $organisation_id } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                { presentation_type: { _eq: "Webinar" } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _lt: $presentation_time } }
                        { presentation_date: { _eq: $presentation_date } }
                      ]
                    }
                    { presentation_date: { _lt: $presentation_date } }
                  ]
                }
                $presentation_filters
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    newPlans: total_webinar_conversions_aggregate(
      where: {
        _and: [
          { presentation_id: { _is_null: false } }
          { organisation_id: { _eq: $organisation_id } }
          { plan: { plan_type: { _eq: "New Plan" } } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                { presentation_type: { _eq: "Webinar" } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _lt: $presentation_time } }
                        { presentation_date: { _eq: $presentation_date } }
                      ]
                    }
                    { presentation_date: { _lt: $presentation_date } }
                  ]
                }
                $presentation_filters
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    amendedPlans: total_webinar_conversions_aggregate(
      where: {
        _and: [
          { presentation_id: { _is_null: false } }
          { organisation_id: { _eq: $organisation_id } }
          { plan: { plan_type: { _eq: "Amended Plan" } } }
          {
            presentation: {
              _and: [
                { enabled: { _eq: true } }
                { presentation_type: { _eq: "Webinar" } }
                {
                  _or: [
                    {
                      _and: [
                        { presentation_end_time: { _lt: $presentation_time } }
                        { presentation_date: { _eq: $presentation_date } }
                      ]
                    }
                    { presentation_date: { _lt: $presentation_date } }
                  ]
                }
                $presentation_filters
              ]
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getFutureWebinars = gql`
  query getFutureWebinars(
    $organisation_id: Int!
    $limit: Int
    $offset: Int
    $presentation_date: date
    $presentation_time: time
    $presentation_filters: presentations_bool_exp!
  ) {
    presentations(
      order_by: { presentation_date: asc }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { presentation_type: { _eq: "Webinar" } }
          { enabled: { _eq: true } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $presentation_time } }
                  { presentation_date: { _eq: $presentation_date } }
                ]
              }
              { presentation_date: { _gt: $presentation_date } }
            ]
          }
          $presentation_filters
        ]
      }
    ) {
      id
      title
      target_audience
      presentation_date
      presentation_end_time
      presentation_time
      booked_places
      number_of_places
      booked_user_presentations_aggregate(
        where: {
          _and: [
            { organisation_id: { _eq: $organisation_id } }
            { cancelled_at: { _is_null: true } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
    presentations_aggregate(
      where: {
        _and: [
          {
            organisation_presentations: {
              organisation_id: { _eq: $organisation_id }
            }
          }
          { presentation_type: { _eq: "Webinar" } }
          { enabled: { _eq: true } }
          {
            _or: [
              {
                _and: [
                  { presentation_end_time: { _gt: $presentation_time } }
                  { presentation_date: { _eq: $presentation_date } }
                ]
              }
              { presentation_date: { _gt: $presentation_date } }
            ]
          }
          $presentation_filters
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
