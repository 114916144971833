import { gql } from "@apollo/client";

export const fetchLinks = gql`
  query fetchLinks($scheme_id: Int!) {
    scheme_details(scheme_id: $scheme_id) {
      faq
      terms_and_conditions
      be_spoke_guide
    }
  }
`;
