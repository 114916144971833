import React from "react";
import useUserEvent from "../../../hooks/useUserEvent";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import useStepper from "../../../hooks/useStepper";

const SocialMediaLinks = ({ name, link, iconPath, trackingDetails }) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleRedirect = (url) => window.open(url, "_blank");

  return (
    <>
      <Box
        component="img"
        className="socialmedia-icons"
        src={iconPath}
        alt={`${name}-icon`}
        data-type="socialmedia-image"
        data-value={name}
        onClick={(e) => {
          userTrackingMutation({
            variables: {
              ...trackingDetails,
              field_name: e.target?.dataset?.type || name || "",
              field_value: e.target?.dataset?.value?.toString() || "",
              avc_track_code: trackingCode,
            },
          });
          handleRedirect(`${link}`);
        }}
      />
    </>
  );
};

SocialMediaLinks.propTypes = {
  trackingDetails: PropTypes.object,
  link: PropTypes.string,
  name: PropTypes.string,
  iconPath: PropTypes.string,
};

export default SocialMediaLinks;
