import { gql } from "@apollo/client";

export const downloadApplicationForm = gql`
  query downloadApplicationForm($plan_id: Int!) {
    plan_details(plan_id: $plan_id) {
      plans
    }
  }
`;

export const getLatestActivePlans = gql`
  query getLatestActivePlan($user_id: Int!, $organisation_id: Int!) {
    plans(
      order_by: { created_at: desc }
      limit: 2
      where: {
        _and: [
          {
            plan_status: {
              _or: [
                { name: { _eq: "Awaiting Authorisation" } }
                { name: { _eq: "Accepted" } }
                { name: { _eq: "Opt Out Pending" } }
                { name: { _eq: "Awaiting Provider Match" } }
                { name: { _eq: "On Hold – Existing AVC" } }
                { name: { _eq: "On Hold - NMW issue" } }
                { name: { _eq: "On Hold – ID issue" } }
                { name: { _eq: "On Hold – Low Earnings Limit" } }
                { name: { _eq: "On Hold - Provider Match Issue" } }
                { name: { _eq: "On Hold - Awaiting Employer Action" } }
                { name: { _eq: "On Hold – Awaiting Employee Acceptance" } }
              ]
            }
            user_id: { _eq: $user_id }
            organisation_id: { _eq: $organisation_id }
          }
        ]
      }
    ) {
      id
      plan_type
      annual_salary
      avc_interval
      created_at
      hours_per_week
      monthly_equivalent_salsac
      total_avc_amount_requested
      last_cancelled_at
      scheme_types {
        id
        name
      }
      plan_status {
        id
        name
      }
      provider {
        id
        name
      }
    }
  }
`;

export const getCancelledPlan = gql`
  query getCancelledPlan($user_id: Int!, $organisation_id: Int!) {
    plans(
      order_by: { last_cancelled_at: desc }
      limit: 2
      where: {
        _and: [
          {
            _or: [
              {
                plan_status: { name: { _eq: "Cancelled" } }
                last_cancelled_at: { _is_null: false }
              }
              { plan_status: { name: { _eq: "Opted-out" } } }
            ]
            user_id: { _eq: $user_id }
            organisation_id: { _eq: $organisation_id }
          }
        ]
      }
    ) {
      id
      plan_type
      annual_salary
      avc_interval
      created_at
      hours_per_week
      monthly_equivalent_salsac
      total_avc_amount_requested
      last_cancelled_at
      scheme_types {
        id
        name
      }
      plan_status {
        id
        name
      }
      provider {
        id
        name
      }
    }
  }
`;

export const getSchemeDetails = gql`
  query getSchemeDetails($scheme_id: Int!) {
    scheme_details(scheme_id: $scheme_id) {
      faq
      terms_and_conditions
    }
  }
`;

export const getPlanCancellationReasons = gql`
  query getPlanCancellationReason($plan_id: Int!) {
    cancellation_reasons_options(plan_id: $plan_id) {
      cancellation_reasons {
        id
        title
        description
      }
    }
  }
`;

export const getPlanAmendmentReasons = gql`
  query getPlanAmendmentReasons {
    plan_reasons(
      where: {
        _and: [{ reasons_type: { _eq: 1 } }, { enabled: { _eq: true } }]
      }
    ) {
      id
      name
      increase
    }
  }
`;

export const getStandardAVCPlan = gql`
  query getStandardAVCPlan($user_id: Int!) {
    cs_prior_user_details_aggregate(where: { user_id: { _eq: $user_id } }) {
      aggregate {
        count
      }
    }
  }
`;
