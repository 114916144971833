import { useState } from "react";
import { Box, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderLinksection from "./HeaderLinksection";
import Logoutsection from "./Logoutsection";
import mmmLogo from "../../../../assets/images/mmm-white-logo.png";
import LogoButton from "../../../generic-components/logo-btn";
import useUserEvent from "../../../../hooks/useUserEvent";
import { TRACKING_HEADER_OPTIONS } from "../../../../constants";
import useStepper from "../../../../hooks/useStepper";

export default function HeaderMobile() {
  const [state, setState] = useState(false);
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (e) =>
    userTrackingMutation({
      variables: {
        ...TRACKING_HEADER_OPTIONS,
        field_name: e?.target?.nodeName || "",
        field_value: e?.target?.textContent || "Menu Bar",
        avc_track_code: trackingCode,
      },
    });

  return (
    <Box className="headerMobile">
      <MenuIcon
        onClick={(e) => {
          handleUserEvent(e);
          setState(true);
        }}
        className="menuIcon"
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={() => setState(false)}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "80%" },
        }}
        className="headerMobile-drower"
      >
        <Box className="header">
          <Box className="mobile-header-logo-container">
            <LogoButton
              name="MMM"
              logoPath={mmmLogo}
              trackingDetails={TRACKING_HEADER_OPTIONS}
              styleClass="header-logo"
            />
          </Box>
        </Box>
        <HeaderLinksection />
        <Box className="logout-container">
          <Logoutsection />
        </Box>
      </Drawer>
    </Box>
  );
}
