import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import PreviousAvcAndPensionsInfoStep1 from "./PreviousAvcAndPensionsInfoStep1";

const PreviousAvcAndPensionsInfo = ({ isNHSApplication }) => {
  return (
    <>
      {!isNHSApplication && (
        <Typography className="sub-heading mb-18 mt-18">
          Now we need to ask you some questions about any previous AVC or
          pension information you may have received
        </Typography>
      )}
      <PreviousAvcAndPensionsInfoStep1 />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isNHSApplication: isNHSApplication(state),
  };
};

PreviousAvcAndPensionsInfo.propTypes = {
  isNHSApplication: PropTypes.bool,
};

export default connect(mapStateToProps)(PreviousAvcAndPensionsInfo);
