import { createSlice } from "@reduxjs/toolkit";
const investmentReducer = createSlice({
  name: "investment",
  initialState: {
    investment: {},
  },
  reducers: {
    setInvestment: (state, action) => {
      state.investment = { ...state.investment, ...action.payload };
    },
  },
});

const { actions, reducer } = investmentReducer;

export const { setInvestment } = actions;

export default reducer;
