import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useLazyQuery } from "@apollo/client";
import { getNingiDetails } from "../../../graphql/queries/getningijourneyDetails";
import PropTypes from "prop-types";
import { startNingiJourney } from "../../../graphql/mutations/startNingiJourney";
import { useMutation } from "@apollo/client";
import useStepper from "../../../hooks/useStepper";
import moment from "moment-timezone";
import LoadingButton from "../../generic-components/button";
import { TRACKING_NINGI_OPTIONS } from "../../../constants";
import Loader from "../../generic-components/loader";

const MyInvestmentAdvicewithPlans = ({ AVCamount }) => {
  const [ningiDetails, setNingiDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { personalDetails, setSaveError, setErrorToast, setFullScreenLoading } =
    useStepper();

  const applicationOrigin = window.location.origin;

  const [fetchNingiDetails] = useLazyQuery(getNingiDetails, {
    variables: {
      id: personalDetails.id,
      organisation_id: personalDetails.organisation_id,
    },
    onCompleted: (data) => {
      setNingiDetails(data?.ningi_journeys[0]);
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [redirectNingiJourney] = useMutation(startNingiJourney, {
    variables: {
      redirect_url: `${applicationOrigin}/redirect-avc_investment_guide`,
    },
    onCompleted: (data) => {
      setIsLoading(true);
      window.location.href = data.startNingiJourney.journey_url;
    },
    onError: (error) => {
      setIsLoad(false);
      setIsLoading(false);
      setFullScreenLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    fetchNingiDetails();
  }, []);

  const UpdateInvestmentAdvice = () => (
    <>
      <Stack
        direction={{ sm: "column", md: "row" }}
        className="changeInvestmentAdvice-container mt-50 mb-30"
      >
        <Typography>Do you want to change Investment Advice?</Typography>
        <LoadingButton
          buttonTitle="Start your Investment Advice journey again"
          loading={isLoad}
          trackingDetails={TRACKING_NINGI_OPTIONS}
          handleClick={() => {
            setIsLoad(true);
            setFullScreenLoading(true);
            redirectNingiJourney();
          }}
          styleClass="ningi-journey-button"
        />
      </Stack>
    </>
  );

  let content, Url;

  switch (ningiDetails?.provider?.name) {
    case "Standard Life":
      content =
        "If you need further help with your investment fund options, you can telephone Standard Life’s direct dedicated helpline on 0345 60 60 047. The telephone line is open between 9am and 5pm.";
      break;
    case "Legal & General":
      content =
        "To make changes to your investment fund options, you can telephone Legal & General on 0345 0708686. The telephone line is open between 9am and 9pm Monday – Friday and 7am and 12pm Saturday. ";
      Url =
        "https://identity.landg.com/auth/realms/My-Account/protocol/openid-connect/auth?client_id=my-account-frontend&redirect_uri=https%3A%2F%2Fmyaccount.landg.com%2F%3Fcid%3DWP-MYA-Flyer01&state=b73ac1a9-9523-4237-8aa0-bc456d252a8b&response_mode=fragment&response_type=code&scope=openid&nonce=7a01baa1-9a08-436c-a9ed-582301bd89f0&cid=WP-MYA-Flyer01&s=p";
      break;
    case "Clerical Medical":
      content =
        "If you need further help with your investment fund options, you can telephone Clerical Medical’s direct helpline on 0345 603 6770 during the following times: Monday to Friday 8.30am to 6pm.";
      break;
    case "Prudential":
      content =
        "To make changes to your investment fund options, you must telephone Prudential’s dedicated helpline on 0345 600 0343. This telephone line is open Monday – Friday, 8.30am – 6.00pm.";
      Url = "https://login.pru.co.uk/Login/dialog/customer/Login/EnterUsername";
      break;
    default:
      content = " ";
      break;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Table className="MyInvestmentAdvice-container mt-18">
            <TableHead className="MyInvestmentAdvice-head">
              <TableRow>
                <TableCell
                  align="center"
                  className="MyInvestmentAdviceheader-cell-lg"
                >
                  Fund name
                </TableCell>
                <TableCell
                  align="center"
                  className="MyInvestmentAdviceheader-cell-sm"
                >
                  Weightage
                </TableCell>
                <TableCell
                  align="center"
                  className="MyInvestmentAdviceheader-cell-sm"
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            {ningiDetails?.ningi_recommendations?.map((data, index) => (
              <TableBody key={index}>
                <TableRow>
                  <TableCell
                    align="center"
                    className="MyInvestmentAdviceRow-cell-lg"
                  >
                    {data.display_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    className="MyInvestmentAdviceRow-cell-sm"
                  >
                    {data.weightage}%
                  </TableCell>
                  <TableCell
                    align="center"
                    className="MyInvestmentAdviceRow-cell-sm"
                  >
                    £{parseFloat((data.weightage / 100) * AVCamount).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
          <Stack direction={{ xs: "column", sm: "row" }} className="mt-30">
            <Typography className="mr-10 align-center">
              <ArrowCircleRightIcon
                sx={{ color: "#0ec20e" }}
                className="mr-10"
              />
              <strong> Journey Date & Time: </strong>
            </Typography>
            <Typography className="">
              {"  "}
              {moment
                .utc(ningiDetails?.end_time)
                .tz("Europe/London")
                .format("DD-MM-YYYY | hh:mm A ")}
            </Typography>
          </Stack>

          <Stack direction="column" className="mt-30">
            <Stack direction={{ xs: "column", sm: "row" }}>
              <Typography className="align-center">
                <ArrowCircleRightIcon
                  sx={{ color: "#0ec20e" }}
                  className="mr-10"
                />
                <strong>
                  Please visit your provider
                  <Link
                    underline="none"
                    href={Url}
                    target="_blank"
                    className="success-text"
                  >
                    {" "}
                    {ningiDetails?.provider?.name}
                  </Link>{" "}
                  to make your changes.
                </strong>
              </Typography>
            </Stack>
            <Typography className="mt-18 mr-10">{content}</Typography>
          </Stack>

          <UpdateInvestmentAdvice />
        </>
      )}
    </>
  );
};

MyInvestmentAdvicewithPlans.propTypes = {
  AVCamount: PropTypes.number,
};

export default MyInvestmentAdvicewithPlans;
