import { gql } from "@apollo/client";

export const startNingiJourney = gql`
  mutation StartNingiJourney($redirect_url: String!) {
    startNingiJourney(redirect_url: $redirect_url) {
      journey_url
      session_id
    }
  }
`;
