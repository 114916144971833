import { Typography, Stack, Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../generic-components/button";
import { TRACKING_NINGI_OPTIONS } from "../../../constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useStepper from "../../../hooks/useStepper";
import { useLazyQuery } from "@apollo/client";
import { getTempPlan } from "../../../graphql/queries/tempPlan";
import { transformTempInvestmentChoices } from "../../../helpers";
import { useDispatch } from "react-redux";
import { setForm } from "../../../reducers/formReducer";

const MyInvestmentAdivceWithNoPlans = ({ formSharedTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    setInitialValues,
    calculateAndSetActiveStep,
    setActiveStep,
    setSaveError,
    personalDetails,
    setErrorToast,
  } = useStepper();

  const [isLoading, setLoading] = useState(false);

  const [fetchTempPlan] = useLazyQuery(getTempPlan, {
    onCompleted: (data) => {
      const tempPlan = data.temp_plans?.find((plan) => plan);

      setInitialValues(tempPlan);

      if (tempPlan) {
        calculateAndSetActiveStep(tempPlan.step_number);
        dispatch(
          setForm({
            ...tempPlan,
            investmentChoices: transformTempInvestmentChoices(
              tempPlan.temp_plan_investments
            ),
          })
        );
      } else setActiveStep(0);

      setLoading(false);
      navigate("/new-application-form");
    },
    onError: (error) => {
      setActiveStep(0);
      setLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const redirectToApplicationForm = () => {
    if (personalDetails?.latest_temp_plan) {
      setLoading(true);
      fetchTempPlan({
        variables: {
          id: personalDetails.latest_temp_plan,
        },
      });
    } else {
      navigate("/new-application-form");
    }
  };

  return (
    <>
      <Stack
        direction="column"
        className="mt-18 changeInvestmentAdvice-container"
      >
        <Box className="content-container">
          <Typography className="">
            This investment advice service is offered to you by your employer at
            no cost to you and is provided by My Money Matters partners,
            Westminster Wealth. This service can give you advice on where you
            should invest your {formSharedTitle} pot.{" "}
          </Typography>
          <Typography className="mt-18">
            You can’t currently use this service as you don’t have a live Shared
            Cost AVC plan or application.
          </Typography>
          <Typography className="mt-18 mb-30">
            To make use of this service, get started with your {formSharedTitle}{" "}
            application now by clicking the button below. You can then access
            the service within the application form.
          </Typography>
        </Box>
        <LoadingButton
          loading={isLoading}
          buttonTitle={`Start your ${formSharedTitle} application`}
          trackingDetails={TRACKING_NINGI_OPTIONS}
          handleClick={redirectToApplicationForm}
          styleClass="ningi-journey-button"
        />
      </Stack>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

MyInvestmentAdivceWithNoPlans.propTypes = {
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(MyInvestmentAdivceWithNoPlans);
