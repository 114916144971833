import { gql } from "@apollo/client";

export const createInvestmentChoice = gql`
  mutation createInvestmentChoice(
    $investmentOptions: [temp_plan_investments_insert_input!]!
  ) {
    insert_temp_plan_investments(objects: $investmentOptions) {
      returning {
        id
        default_option
        investment_option_id
        temp_plan_id
        specialist_value
        investment_option {
          display_name
          id
          lifestyle
        }
      }
    }
  }
`;
export const deleteInvestmentChoice = gql`
  mutation deleteInvestmentChoice($temp_plan_id: Int!) {
    delete_temp_plan_investments(
      where: { temp_plan_id: { _eq: $temp_plan_id } }
    ) {
      returning {
        id
        default_option
        investment_option_id
        temp_plan_id
        specialist_value
      }
    }
  }
`;
