import {
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import PropTypes from "prop-types";

const Accordion = ({
  expanded,
  setExpanded,
  summary,
  children,
  summaryClass,
  summaryTextClass,
}) => {
  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange}
      square
      className="accordion mt-18"
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "18px" }} />}
        className={`accordion-summary ${summaryClass}`}
      >
        <Typography className={`accordion-summary-text ${summaryTextClass}`}>
          {summary}
        </Typography>
      </AccordionSummary>
      {children}
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  summary: PropTypes.string,
  children: PropTypes.node,
  summaryClass: PropTypes.string,
  summaryTextClass: PropTypes.string,
};

export default Accordion;
