// eslint-disable-next-line no-undef
const emarsysQueue = window.ScarabQueue || ScarabQueue;

const sendUserLandingPageEvent = (pathname, personalDetails) => {
  if (!emarsysQueue) {
    return;
  }

  emarsysQueue.push(["setEmail", personalDetails.email]);
  emarsysQueue.push(["tag", "landed_page", { pathname }]);
  emarsysQueue.push([
    "tag",
    "user_details",
    {
      email: personalDetails.email,
      user_id: personalDetails.id,
    },
  ]);
  emarsysQueue.push(["setCustomerId", personalDetails.id.toString()]);
  emarsysQueue.push(["go"]);
};

const sendUserExitedEvent = (formValues) => {
  if (!emarsysQueue) {
    return;
  }

  emarsysQueue.push([
    "cart",
    [{ quantity: 1, item: formValues.id, price: formValues.avcAmount }],
  ]);
  emarsysQueue.push(["tag", "predict last abandoned date"]);
  emarsysQueue.push(["go"]);
};

export { sendUserExitedEvent, sendUserLandingPageEvent };
