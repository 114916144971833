import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { checkFieldError } from "../../../helpers";
import useStepper from "../../../hooks/useStepper";
import useUserEvent from "../../../hooks/useUserEvent";

const FileInput = ({
  id,
  name,
  label,
  accept,
  styleClass,
  trackingDetails,
  handleCustomChange,
  disabled = false,
}) => {
  const { touched, errors, handleBlur } = useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    if (element?.target?.files[0]) {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: element?.target?.name || name || "",
          field_value: element?.target?.files[0]?.name?.toString() || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  return (
    <>
      <TextField
        id={id}
        name={name}
        label={label}
        accept={accept}
        disabled={disabled}
        type="file"
        variant="outlined"
        className={`${styleClass} mt-18`}
        fullWidth
        color="mmm"
        onFocus={handleUserEvent}
        onChange={(e) => {
          handleCustomChange && handleCustomChange(e);
        }}
        onBlur={(e) => {
          handleUserEvent(e);
          handleBlur(e);
        }}
        error={checkFieldError(touched, errors, name)}
        helperText={checkFieldError(touched, errors, name)}
      />
    </>
  );
};

FileInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  trackingCode: PropTypes.string,
  styleClass: PropTypes.string,
  trackingDetails: PropTypes.object,
  disabled: PropTypes.bool,
  handleCustomChange: PropTypes.func,
};

export default FileInput;
