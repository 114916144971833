import {
  Box,
  Stack,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteIcon from "@mui/icons-material/Delete";
import FormSelect from "../../../../generic-components/form-select";
import useStepper from "../../../../../hooks/useStepper";
import PropTypes from "prop-types";
import { useToast } from "../../../../../hooks/useToast";
import { useMemo, useState } from "react";
import { checkFieldError, fixedAmountToDecimal } from "../../../../../helpers";
import { TRACKING_NAF_OPTIONS, integerRegex } from "../../../../../constants";
import TextField from "../../../../generic-components/text-field";
import useUserEvent from "../../../../../hooks/useUserEvent";

const InvestmentChoicesStep2Options = ({
  investmentChoicesOptions,
  handleNingiChange,
}) => {
  const { showToast } = useToast();
  const { formValues, trackingCode } = useStepper();
  const [open, setOpen] = useState(false);

  const { values, handleChange, touched, errors, setFieldValue } =
    useFormikContext();

  const handlePush = (arrayHelpers) => {
    if (
      investmentChoicesOptions.length > 1 &&
      values.investmentChoices.length < investmentChoicesOptions.length
    ) {
      arrayHelpers.push({ name: "", value: "", percentage: "" });
    } else setOpen(true);
  };

  const calculateTotalPercentage = (value, index) => {
    return values.investmentChoices.reduce(
      (accumulator, currentValue, currentIndex) =>
        accumulator +
        Number(currentIndex === index ? value : currentValue.percentage),
      0
    );
  };

  const handlePercentage = (e, setFieldValue, index) => {
    const sum = calculateTotalPercentage(e.target.value, index);

    if (e.target.value < 1 || e.target.value > 100 || sum > 100) {
      setFieldValue(`investmentChoices[${index}].percentage`, "");
    } else if (integerRegex.test(e.target.value)) {
      setFieldValue(`investmentChoices[${index}].percentage`, e.target.value);
    }
  };

  const contributedAvcAmount = useMemo(() => {
    if (!formValues.additional_avc_amount) {
      return formValues.avcAmount;
    }

    return Number(formValues.avcAmount) + formValues.additional_avc_amount;
  }, [formValues.avcAmount]);

  const { userTrackingMutation } = useUserEvent();

  const handlePercentageEvent = (element, index) => {
    const value = values.investmentChoices[index]?.name;
    const data = investmentChoicesOptions?.find((item) => item.value === value);
    const targetValue = element?.target?.value || "";
    const fieldName = data?.name
      ? `${data?.name} - ${String(targetValue)}%`
      : "percentage";
    if (targetValue) {
      userTrackingMutation({
        variables: {
          ...TRACKING_NAF_OPTIONS,
          field_name: fieldName,
          field_value: targetValue ? `${String(targetValue)}%` : `0%`,
          avc_track_code: trackingCode,
        },
      });
    }
  };

  const handleUserEvent = (name) => {
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: name,
        field_value: "Clicked",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleUserEventDelete = (index) => {
    const value = values.investmentChoices[index]?.value;
    const data = investmentChoicesOptions?.find((item) => item.value === value);
    const fieldName = data?.name ? `${data?.name} - Delete` : "Delete";
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName,
        field_value: "Delete",
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <Box>
      {investmentChoicesOptions?.length && values.investmentChoices?.length ? (
        <>
          {open &&
            showToast(
              "There are no more options available to add...",
              open,
              setOpen,
              "error",
              true
            )}
          <Typography className="mt-18 stepper-content">
            <strong>
              {values.listCheckbox
                ? `Ok, here’s a list of the investment choices available to you chosen by
          your Employer’s pension fund. You can choose as many as you like, as
          long as the total allocation adds up to 100%’`
                : `Ok, only one lifestyling option can be chosen, please pick which fund you want`}
            </strong>
          </Typography>
          <FieldArray
            name="investmentChoices"
            render={(arrayHelpers, index) => (
              <Box key={index}>
                {values?.investmentChoices?.map((field, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="mt-18"
                        alignItems="start"
                        key={index}
                      >
                        <FormSelect
                          name="investmentChoices"
                          labelId="providerList"
                          menuItems={[...investmentChoicesOptions]}
                          label="Investment choices *"
                          index={index}
                          subField="name"
                          handleCustomChange={handleNingiChange}
                          trackingDetails={TRACKING_NAF_OPTIONS}
                        />
                        <TextField
                          id={`investmentChoices[${index}].percentage`}
                          name={`investmentChoices[${index}].percentage`}
                          value={values.investmentChoices[index].percentage}
                          variant="outlined"
                          className="mt-18"
                          color="mmm"
                          sx={{
                            width: {
                              xs: "50% !important",
                              sm: "40% !important",
                            },
                          }}
                          onChange={(e) => {
                            handlePercentage(e, setFieldValue, index);
                            handleNingiChange();
                          }}
                          onFocus={(e) => {
                            handlePercentageEvent(e, index);
                          }}
                          onBlur={(e) => {
                            handlePercentageEvent(e, index);
                            handleChange(e);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <PercentIcon className="input-field-icon" />
                              </InputAdornment>
                            ),
                            min: 0,
                            max: 100,
                          }}
                          error={checkFieldError(
                            touched,
                            errors,
                            "investmentChoices",
                            index,
                            "percentage"
                          )}
                          helperText={checkFieldError(
                            touched,
                            errors,
                            "investmentChoices",
                            index,
                            "percentage"
                          )}
                        />
                        <MuiTextField
                          value={fixedAmountToDecimal(
                            (contributedAvcAmount / 100) *
                              values?.investmentChoices[index]?.percentage
                          )}
                          variant="outlined"
                          className="mt-18"
                          sx={{ width: { xs: "50%", sm: "40%" } }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyPoundIcon className="input-field-icon" />
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                        {values?.investmentChoices.length > 1 && (
                          <DeleteIcon
                            className="delete-icon mt-30"
                            data-value="Delete"
                            sx={{ fontSize: 30 }}
                            onClick={() => {
                              arrayHelpers.remove(index);
                              handleNingiChange();
                              handleUserEventDelete(index);
                            }}
                          />
                        )}
                      </Stack>
                    </>
                  );
                })}
                {values.listCheckbox && (
                  <Typography className="field-array-text stepper-content mt-30">
                    <strong
                      data-name="Add_more_investment_options"
                      onClick={(e) => {
                        handlePush(arrayHelpers);
                        handleNingiChange();
                        handleUserEvent(
                          e?.target?.innerText || "Add more investment options"
                        );
                      }}
                    >
                      Add more investment options +
                    </strong>
                  </Typography>
                )}
              </Box>
            )}
          />
        </>
      ) : (
        <Typography className="imp-text mt-18">
          <strong>
            Sorry! No investment choice is available for this pension fund!
          </strong>
        </Typography>
      )}
    </Box>
  );
};

InvestmentChoicesStep2Options.propTypes = {
  investmentChoicesOptions: PropTypes.array,
  handleNingiChange: PropTypes.func,
};

export default InvestmentChoicesStep2Options;
