import { Grid, Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const AskForSignInContainer = () => {
  return (
    <Box className="AskForSignIn">
      <Box className="AskForSignIn-Container">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography className="Heading">Already registered?</Typography>
              <Typography className="details mt-18">
                If you have already registered with My Money Matters, click here
                to sign in.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="btn-container">
                <Button
                  component={Link}
                  to="/sign_in"
                  className="signIn-btn btn"
                >
                  Sign In
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AskForSignInContainer;
