import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import useStepper from "../../../../../hooks/useStepper";
import FormHeader from "../header/FormHeader";
import { connect } from "react-redux";

const InvestmentChoices = ({ formSharedTitle }) => {
  const { formValues } = useStepper();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const avcAmount = useMemo(
    () =>
      formValues.additional_avc_amount
        ? Number(formValues.avcAmount || 0) + formValues.additional_avc_amount
        : Number(formValues.avcAmount || 0),
    [formValues.avcAmount]
  );

  return (
    <Box className="investment-choices">
      <FormHeader heading="Investment Choices" amount={avcAmount} />
      <Typography className="sub-heading">
        Next up is the important decision on where to invest your
        {""} {formSharedTitle} pot.
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

InvestmentChoices.propTypes = {
  activeStep: PropTypes.number,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(InvestmentChoices);
