import { Box, Grid, Container } from "@mui/material";
import frontImage from "../../../assets/images/front-image.png";
import React from "react";
import RegisterationStepper from "./register-screen/RegisterationStepper";
import PropTypes from "prop-types";
import AskForSignInContainer from "./register-screen/AskForSignInContainer";
const RegisterationPage = () => {
  const [signInFooter, setsignInFooter] = React.useState(true);

  return (
    <Box
      className="front-screen-container container-wrapper"
      sx={{ backgroundImage: `url(${frontImage})` }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className="content-wrapper">
              <Box className="registeration-screen-container">
                <Container>
                  <RegisterationStepper setsignInFooter={setsignInFooter} />
                  {signInFooter == true ? <AskForSignInContainer /> : null}
                </Container>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

RegisterationPage.propTypes = {
  setsignInFooter: PropTypes.bool,
};

export default RegisterationPage;
