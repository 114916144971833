const formType = {
  NEW_APPLICATION_FORM: "NAF",
  NHS_APPLICATION_FORM: "NHS",
};

const formSharedTitle = {
  NAF_SHARED_TITLE: "Shared Cost AVC",
  NHS_SHARED_TITLE: "Salary sacrifice AVC",
};

const sharedTitle = {
  NAF_SHARED_TITLE: "Shared Cost AVC",
  NHS_SHARED_TITLE: "Salary Sacrifice AVC",
};

const pensionName = {
  LOCAL_GOVT: "Local Government Pension Scheme",
  NHS: "NHS Pension Scheme",
};

const pensionType = {
  LGPS: "LGPS",
  NHS: "NHS",
};

const schemeTypeNames = {
  SHARED_COST_AVC: "Shared Cost AVC",
  SALARY_SACRIFICE_AVC: "Salary Sacrifice AVC",
  STANDARD_AVC: "Standard AVC",
};

const mainSchemeTypes = [
  schemeTypeNames.SHARED_COST_AVC,
  schemeTypeNames.SALARY_SACRIFICE_AVC,
];

export {
  formType,
  sharedTitle,
  formSharedTitle,
  pensionName,
  pensionType,
  schemeTypeNames,
  mainSchemeTypes,
};
