import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../../../constants";
import LoadingButton from "../../../../../../generic-components/button";
import "./dialogue.scss";
import IconButton from "../../../../../../generic-components/icon-btn";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationDialogue = ({
  open,
  onCloseDialog,
  onClose,
  showAction = true,
  maxWidthAllowed = false,
  loading,
  handleOnAccept,
  acceptButtonTitle,
  cancelButtonTitle,
  children,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`${
        maxWidthAllowed ? "cancel-reason-dialog" : ""
      } event-confirmation-dialog`}
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">Cancellation Reason</Typography>
      </DialogTitle>
      <IconButton
        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
        extraStyles={{
          position: "absolute",
          right: 4,
          top: 20,
          color: (theme) => theme.palette.grey[500],
        }}
        handleClick={onCloseDialog}
        styleClass="call"
        icon={<CloseIcon />}
      />
      <Box className="detail-heading-box text-left">{children}</Box>
      {showAction && (
        <Stack
          className={`mt-18 mb-30 p-lr-50 ${
            maxWidthAllowed ? "cancel-reason-dialog__buttons" : ""
          }`}
          direction={{ md: "row", xs: "column" }}
          justifyContent={{ md: "space-between", xs: "center" }}
          alignItems="center"
          spacing={2}
        >
          <LoadingButton
            buttonTitle={cancelButtonTitle}
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            handleClick={() => onClose()}
            styleClass={`btn contained-btn disable-text-transform thinbtn ${
              maxWidthAllowed ? "max-w-auto" : ""
            }`}
          />
          <LoadingButton
            buttonTitle={acceptButtonTitle}
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            loading={loading}
            handleClick={() => {
              handleOnAccept();
            }}
            styleClass={`service-button disable-text-transform confirmation-warning thinbtn ${
              maxWidthAllowed ? "max-w-14" : ""
            }`}
          />
        </Stack>
      )}
    </Dialog>
  );
};

ConfirmationDialogue.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  showAction: PropTypes.bool,
  maxWidthAllowed: PropTypes.bool,
  acceptButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  onClose: PropTypes.func,
  onCloseDialog: PropTypes.func,
  handleOnAccept: PropTypes.func,
  children: PropTypes.node,
};

export default ConfirmationDialogue;
