import { Tooltip as MuiTooltip } from "@mui/material";
import PropTypes from "prop-types";

const Tooltip = ({ title, children }) => {
  return (
    <MuiTooltip className="tooltip" title={title}>
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Tooltip;
