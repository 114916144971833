import { Box, Container, Stack, Typography } from "@mui/material";
import isEmpty from "lodash.isempty";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { mmmThemes } from "../../../../constants";
import {
  checkActiveSchemes,
  mmmUniversalRedirection,
} from "../../../../helpers";
import useStepper from "../../../../hooks/useStepper";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";

const SchemeNotFound = () => {
  const { personalDetails } = useStepper();
  const navigate = useNavigate();

  const checkRequiredActiveSchemes = (personalDetails) => {
    const requiredActiveScheme = checkActiveSchemes(personalDetails);
    if (requiredActiveScheme) navigate("/home");
  };

  const handleWebinarRedirection = () => {
    mmmUniversalRedirection(
      personalDetails?.sub_domain,
      `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
    );
  };

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      checkRequiredActiveSchemes(personalDetails);
    }
  }, [personalDetails]);

  return (
    <Box className="application-page-container mt-150">
      <SharedHeader heading="Not long now!" />
      <Container className="mt-200">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography className="large-heading primary-text">
            <strong>
              Shared Cost AVCs are coming to your organisation soon. Whilst you
              wait,{" "}
              <span
                className="underline-text cursor-pointer link-text"
                onClick={handleWebinarRedirection}
                onKeyUp={handleWebinarRedirection}
              >
                book onto a webinar
              </span>{" "}
              to learn more about how you can boost your pension pot.
            </strong>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default SchemeNotFound;
