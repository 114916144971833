import { gql } from "@apollo/client";

export const checkEmployeeNumber = gql`
  query check_employee_number(
    $organisation_id: Int
    $employee_number: String
    $user_id: Int
  ) {
    users_roles(
      where: {
        belongs_to_role: { resource_id: { _eq: $organisation_id } }
        belongs_to_user: {
          employee_number: { _eq: $employee_number }
          id: { _neq: $user_id }
        }
      }
    ) {
      id
      belongs_to_user {
        id
      }
    }
  }
`;

export const checkReferralCode = gql`
  query validateReferralCode(
    $referral_code: String!
    $total_avc_amount_requested: float8!
  ) {
    validate_referral_code(
      referral_code: $referral_code
      total_avc_amount_requested: $total_avc_amount_requested
    ) {
      message
    }
  }
`;

export const checkNiNumber = gql`
  query check_ni_number($ni_number: String, $id: Int) {
    users(
      where: {
        _and: [{ ni_number: { _eq: $ni_number } }, { id: { _neq: $id } }]
      }
    ) {
      id
    }
  }
`;
