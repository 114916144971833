import { gql } from "@apollo/client";

export const getNingiDetails = gql`
  query get_ningi_journey($id: Int!, $organisation_id: Int!) {
    ningi_journeys(
      where: {
        user_id: { _eq: $id }
        organisation_id: { _eq: $organisation_id }
        journey_status: { _eq: 0 }
      }
      order_by: { id: desc }
      limit: 1
    ) {
      end_time
      ningi_recommendations {
        display_name
        weightage
      }
      provider {
        id
        name
      }
    }
  }
`;
