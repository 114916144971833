import { Box, Divider } from "@mui/material";
import SideNavigator from "./SideNavigator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import { useEffect, useState } from "react";
import {
  downloadApplicationForm,
  getSchemeDetails,
} from "../../../../../graphql/queries/manageMySharedCost";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import usePlan from "../../../../../hooks/usePlan";
import {
  findLatestActivePlanId,
  getLegacyAppURL,
} from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";

const SideNavigationLinks = ({ sharedTitle, scheme, isNHS, user }) => {
  const navigate = useNavigate();
  const { setSaveError, setErrorToast } = useState();
  const { plans, isStandardAVCPlan, isOptOutPendingPlan } = usePlan();
  const { personalDetails } = useStepper();

  const [schemeDetails, setSchemeDetails] = useState(null);
  const [downloadFormLink, setDownloadFormLink] = useState(null);
  const [planId, setPlanId] = useState(null);

  const [fetchSchemeDetails, { loading: schemeDetailsLoading }] =
    useLazyQuery(getSchemeDetails);
  const [downloadForm, { loading: downloadFormLoading }] = useLazyQuery(
    downloadApplicationForm
  );

  const handleRedirection = (url, navigation, newTab) => {
    if (navigation) {
      navigate(url, { state: { backUrl: "/contact" } });
    } else if (!navigation && !newTab) {
      window.location.href = url;
    } else if (!navigation && newTab) {
      window.open(url, "_blank");
    }
  };

  const handleDownloadForm = async (planId) => {
    downloadForm({
      variables: {
        plan_id: planId,
      },
      onCompleted: (data) => {
        const applicationFormLink = data?.plan_details?.plans?.application_form;
        setDownloadFormLink(applicationFormLink);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const getSchemeDetailsURLs = async () => {
    fetchSchemeDetails({
      fetchPolicy: "no-cache",
      variables: {
        scheme_id: scheme.scheme_id,
      },
      onCompleted: (data) => {
        setSchemeDetails(data?.scheme_details);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleActivePlans = async () => {
    const latestPlanId = findLatestActivePlanId(plans);
    setPlanId(latestPlanId);
    handleDownloadForm(latestPlanId);
  };

  useEffect(() => {
    if (scheme?.scheme_id) {
      getSchemeDetailsURLs();
    }
  }, [scheme]);

  useEffect(() => {
    if (plans?.length) {
      handleActivePlans();
    }
  }, [plans]);

  return (
    <Box>
      <Divider />
      <SideNavigator
        heading={`My ${sharedTitle}`}
        handleClick={() => {
          isNHS || isStandardAVCPlan || isOptOutPendingPlan
            ? handleRedirection(
                `${getLegacyAppURL(personalDetails?.sub_domain)}/users/${
                  user.id
                }/plans?scheme_id=${scheme?.scheme_id}`,
                false,
                false
              )
            : handleRedirection("/manage_my_shared_cost_avc", true, false);
        }}
      />
      <Divider />
      <SideNavigator
        heading={`Amend My ${sharedTitle}`}
        handleClick={() => {
          isNHS || isStandardAVCPlan || isOptOutPendingPlan
            ? handleRedirection(
                `${getLegacyAppURL(personalDetails?.sub_domain)}/users/${
                  user.id
                }/plans/${planId}/amend_avc_amount_new`,
                false,
                false
              )
            : handleRedirection("/amend_my_shared_cost_avc", true, false);
        }}
      />
      <Divider />
      <SideNavigator
        heading="Download Application Form"
        isLoading={downloadFormLoading}
        handleClick={() => {
          handleRedirection(downloadFormLink, false, true);
        }}
      />
      <Divider />
      <SideNavigator
        heading={`Cancel My ${sharedTitle}`}
        handleClick={() => {
          isNHS || isStandardAVCPlan || isOptOutPendingPlan
            ? handleRedirection(
                `${getLegacyAppURL(personalDetails?.sub_domain)}/users/${
                  user.id
                }/plans/${planId}/show_cancel`,
                false,
                false
              )
            : handleRedirection("/cancel_my_plan", true, false);
        }}
      />
      <Divider />
      <SideNavigator
        heading="FAQ"
        isLoading={schemeDetailsLoading}
        handleClick={() => {
          handleRedirection(schemeDetails?.faq, false, true);
        }}
      />
      <Divider />
      <SideNavigator
        heading="Terms"
        isLoading={schemeDetailsLoading}
        handleClick={() => {
          handleRedirection(schemeDetails?.terms_and_conditions, false, true);
        }}
      />
      <Divider />
      <SideNavigator
        heading="Contact"
        isHighlighted={true}
        handleClick={() => {
          user?.latest_plan
            ? handleRedirection("/contact", true, false)
            : handleRedirection(
                process.env.REACT_APP_CONTACT_US_URL,
                false,
                false
              );
        }}
      />
      <Divider />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.scheme,
    user: state.user.user,
    sharedTitle: state.multiForm.sharedTitle,
    isNHS: isNHSApplication(state),
  };
};

SideNavigationLinks.propTypes = {
  scheme: PropTypes.object,
  user: PropTypes.object,
  sharedTitle: PropTypes.string,
  isNHS: PropTypes.bool,
};

export default connect(mapStateToProps)(SideNavigationLinks);
