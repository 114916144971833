import { gql } from "@apollo/client";

export const deleteNingiAndOptions = gql`
  mutation bulkDeleteNingiAndOptions(
    $ningi_journey_id: Int
    $temp_plan_id: Int
    $investment_advice: Boolean
    $step_number: Int
  ) {
    delete_ningi_recommendations(
      where: { ningi_journey_id: { _eq: $ningi_journey_id } }
    ) {
      returning {
        id
      }
    }
    delete_ningi_journeys(where: { id: { _eq: $ningi_journey_id } }) {
      returning {
        id
        journey_status
      }
    }
    delete_temp_plan_investments(
      where: { temp_plan_id: { _eq: $temp_plan_id } }
    ) {
      returning {
        id
      }
    }
    update_temp_plans(
      where: { id: { _eq: $temp_plan_id } }
      _set: {
        provider_id: null
        investment_advice: $investment_advice
        step_number: $step_number
      }
    ) {
      returning {
        id
        organisation_id
        additional_avc_amount
        first_name
        delivery_address1
        title
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        first_name
        last_name
        mobile_number
        ni_number
        delivery_postcode
        delivery_town
        telephone_number
        total_avc_amount_requested
        annual_salary
        avc_interval
        investment_advice
        signatures
        flexibly_accessed_any_pensions
        lifestyling_option
        pension_fund_id
        provider_id
        retirement_age
        pension_age
        terms_acknowledged
        signatures
        prudential_declaration
        step_number
        choosen_relevant_investment_option
        having_previous_employer_plan
        combining_with_this_employer
        keep_separate_with_employer
        triggered_the_mpaa
        mpaa_date
        flexibly_accessed_date
        ningi_journey {
          id
          journey_status
          journey_url
          recommendation_changed
        }
        temp_plan_investments {
          id
          specialist_value
          investment_option {
            display_name
            id
          }
        }
      }
    }
  }
`;
