import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../../constants";
import LoadingButton from "../../../../../generic-components/button";
import IconButton from "../../../../../generic-components/icon-btn";
import CancelPlanConfirmationDialog from "./CancelPlanConfirmationDialog";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MyPlanHeader from "./MyPlanHeader";
import PropTypes from "prop-types";
import CancellationReasons from "./CancellationReasons";

const CancelMySharedCostContent = ({ plans }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [cancel, setCancel] = useState(false);

  const handleCloseConfirmtion = () => {
    setOpenConfirmation(false);
  };

  const handleBack = () => {
    if (cancel) {
      setCancel(false);
    } else {
      navigate(state?.backUrl ? state.backUrl : "/manage_my_shared_cost_avc");
    }
  };

  return (
    <>
      <IconButton
        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
        buttonTitle="Back"
        styleClass="light-backbtn mb-18"
        handleClick={handleBack}
        link="#"
        icon={<KeyboardArrowLeftIcon />}
      />
      <MyPlanHeader heading="My Plans" />
      <Box
        className={
          cancel
            ? "cancel-plan-content-flex-start mb-50"
            : "cancel-plan-content mb-50"
        }
      >
        <Box
          className={
            cancel
              ? "generic-heading-cotainer flex-start"
              : "generic-heading-cotainer"
          }
        >
          <Typography className="generic-heading">
            {cancel
              ? "We're sorry to see you go"
              : "Cancelling your current Shared Cost AVC plan"}
          </Typography>
        </Box>
        {!cancel ? (
          <>
            <Typography className="mt-18">
              If you wish to cancel your current Shared Cost AVC plan, you can
              do so by clicking the 'I want to cancel' button below.
            </Typography>
            {plans[0]?.plan_status?.name === "Accepted" ? (
              <>
                <Typography className="mt-18">
                  However, it may be more efficient for you to amend your
                  contribution to a lower amount instead.
                </Typography>
                <Typography className="mt-18">
                  You can contribute as little as £2 to the scheme, which will
                  then save you having to go through the application process
                  again if you change your mind in the future.
                </Typography>
                <LoadingButton
                  buttonTitle="I want to amend"
                  trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                  handleClick={() =>
                    navigate("/amend_my_shared_cost_avc", {
                      state: {
                        backUrl: "/cancel_my_plan",
                      },
                    })
                  }
                  styleClass="service-button disable-text-transform service-border mt-18"
                />
              </>
            ) : null}
            <Typography
              className={
                plans[0]?.plan_status?.name === "Accepted" ? "mt-18" : "mt-30"
              }
            >
              By cancelling my Shared Cost AVC plan, I understand I will have to
              go through the new application process again if I change my mind
              in the future.
            </Typography>
            <LoadingButton
              buttonTitle="I want to cancel"
              trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
              handleClick={() => setOpenConfirmation(true)}
              styleClass="service-button disable-text-transform confirmation-warning mt-18 mb-18"
            />
          </>
        ) : (
          <CancellationReasons setCancel={setCancel} plan={plans[0]} />
        )}
      </Box>
      <CancelPlanConfirmationDialog
        open={openConfirmation}
        setCancel={setCancel}
        onClose={handleCloseConfirmtion}
      />
    </>
  );
};

CancelMySharedCostContent.propTypes = {
  plans: PropTypes.array,
};

export default CancelMySharedCostContent;
