import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import {
  findItemBySelectedValue,
  formatCurrency,
} from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";
import Loader from "../../../../generic-components/loader";
import { useLazyQuery } from "@apollo/client";
import { getTax } from "../../../../../graphql/queries/getTax";
import ToggleAmountButton from "../../../../generic-components/toggle-amount-button";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { TRACKING_NAF_OPTIONS } from "../../../../../constants";

const AvcAddToPayroll = ({
  planFrequency,
  totalPot,
  totalProjection,
  contributionAmount,
  setContributionAmount,
  contributionLoading,
  formSharedTitle,
  scheme,
}) => {
  const {
    formValues,
    setFormValues,
    setSaveError,
    setErrorToast,
    setActiveStep,
    setSharedFormIndex,
    trackingCode,
  } = useStepper();

  const [incomeTaxAmount, setIncomeTaxAmount] = useState(0);
  const { userTrackingMutation } = useUserEvent();

  const updateAvcAmount = (_, newAmount) => {
    const totalPot = parseFloat(formValues.avcAmount) + newAmount;

    if (
      totalPot < parseFloat(formValues.income) &&
      totalPot <= Number(formValues.max_contribution_amount)
    ) {
      setContributionAmount(newAmount);
      setFormValues({
        ...formValues,
        additional_avc_amount: newAmount,
      });
    } else {
      let message;
      if (totalPot >= parseFloat(formValues.income)) {
        message = `${formSharedTitle} amount cannot be greater than or equal to your income.`;
      } else if (totalPot > Number(formValues.max_contribution_amount))
        message = `${formSharedTitle} amount requested cannot be greater than ${formatCurrency(
          formValues.max_contribution_amount
        )}.`;

      setSaveError({
        message,
        overRideCustom: true,
      });
      setErrorToast(true);
    }
  };

  const [fetchTaxDetails, { loading: isTaxLoading }] = useLazyQuery(getTax, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const incomeTaxSavingSacrifice =
        data?.plan_savings?.income_tax_saving_on_salary_sacrifice || 0;

      const nicSavingSacrifice =
        data?.plan_savings?.monthly_nic_saving_on_salary_sacrifice || 0;

      setIncomeTaxAmount(incomeTaxSavingSacrifice + nicSavingSacrifice);
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  useEffect(() => {
    if (
      formValues.incomeFrequency &&
      formValues.avcAmount &&
      (formValues.income || formValues.annual_salary)
    ) {
      fetchTaxDetails({
        variables: {
          annual_salary: formValues.income || formValues.annual_salary,
          total_avc_amount_requested: contributionAmount
            ? parseFloat(formValues.avcAmount) + contributionAmount
            : parseFloat(formValues.avcAmount),
          avc_interval: formValues.incomeFrequency,
          scheme_id: scheme.scheme_id,
          add_ni_savings_onto_avc: false,
        },
      });
    } else {
      moveToSharedCostPage();
      setSaveError({
        message: `Please provide the complete required details.`,
        overRideCustom: true,
      });
      setErrorToast(true);
    }
  }, [
    contributionAmount,
    formValues.avcAmount,
    formValues.incomeFrequency,
    formValues.income,
    formValues.annual_salary,
  ]);

  const moveToSharedCostPage = () => {
    setActiveStep(1);
    setSharedFormIndex(1);
  };

  const handleUserEvent = (element) => {
    const { name, textContent } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: fieldName || "",
        field_value: textContent?.toString() || "",
        avc_track_code: trackingCode,
      },
    });
  };

  const showContributionAmountBtn = useCallback(
    (amount) => {
      const totalPot = parseFloat(formValues.avcAmount) + amount;

      return !(totalPot > Number(formValues.max_contribution_amount));
    },
    [formValues.avcAmount]
  );

  return (
    <Box>
      {showContributionAmountBtn(10) && (
        <>
          {!!contributionAmount && (
            <>
              <Typography className="mt-18 stepper-content lnh-2 ">
                Adding an extra{" "}
                <strong className="primary-text mt-30">
                  {contributionAmount
                    ? formatCurrency(contributionAmount)
                    : "£ -"}
                </strong>{" "}
                would take your contribution amount to{" "}
                <strong className="primary-text mt-30">
                  {formatCurrency(formValues.avcAmount + contributionAmount)}.
                </strong>{" "}
                This contribution is estimated to only cost you{" "}
                <strong className="primary-text mt-30">
                  {" "}
                  {!isTaxLoading ? (
                    formatCurrency(
                      parseFloat(formValues.avcAmount) +
                        contributionAmount -
                        incomeTaxAmount
                    )
                  ) : (
                    <Box className="loader-text">
                      <Loader size={19} />
                    </Box>
                  )}
                </strong>{" "}
                each payroll.{" "}
              </Typography>
              <Typography className="mt-30">
                This extra{" "}
                <strong className="primary-text">
                  {contributionAmount
                    ? formatCurrency(contributionAmount)
                    : "£ -"}{" "}
                </strong>
                could mean your pot is worth an extra{" "}
                <strong className="primary-text mt-30">
                  {!contributionLoading ? (
                    formatCurrency(Number(totalPot - totalProjection))
                  ) : (
                    <Box className="loader-text">
                      <Loader size={19} />
                    </Box>
                  )}
                </strong>{" "}
                over{" "}
                <strong className="primary-text mt-30">
                  {planFrequency} years.{" "}
                </strong>
              </Typography>
              <Box className="potworth-green-container mt-30">
                <Typography className="content stepper-content">
                  What your {formSharedTitle} pot is estimated to look like
                  after {planFrequency} years if you contribute{" "}
                  {formatCurrency(formValues.avcAmount + contributionAmount)}.
                  <span className="fs-28">*</span>
                </Typography>
                <Typography className="amount mt-18">
                  {!contributionLoading ? (
                    <Typography className="pot-worth-amount">
                      {contributionAmount ? formatCurrency(totalPot) : "£ -"}*
                    </Typography>
                  ) : (
                    <Box className="loader-start">
                      <Loader size={27} />
                    </Box>
                  )}
                </Typography>
              </Box>
            </>
          )}

          {!contributionAmount ? (
            <Typography className="mt-30 stepper-content">
              <strong>
                Clicking the buttons below will add the corresponding amount to
                your {formSharedTitle} amount.
              </strong>
            </Typography>
          ) : null}
        </>
      )}

      <ToggleAmountButton
        contributionAmount={contributionAmount}
        showContributionAmountBtn={showContributionAmountBtn}
        updateAvcAmount={updateAvcAmount}
      />
      {contributionAmount ? (
        <>
          <Typography className="mt-18 stepper-content">
            <strong>
              Want to add a different amount?{" "}
              <a
                href="#"
                className="web-link "
                name="moveToSharedCostPage"
                onClick={(e) => {
                  moveToSharedCostPage();
                  handleUserEvent(e);
                }}
              >
                Click here
              </a>{" "}
              to go back and change your contribution amount.
            </strong>
          </Typography>
        </>
      ) : null}

      {showContributionAmountBtn(10) && (
        <Typography className="primary-text mt-18">
          <strong>
            Remember, you can amend your {formSharedTitle} amount at any time!
          </strong>
        </Typography>
      )}

      <Typography className="grey-text mt-30 stepper-content">
        <span className="fs-28">*</span>Figures are for illustrative purposes
        only. Estimates aren't guaranteed. Tax treatments are based on
        individual circumstances and may be subject to change in the future.
        Your {formSharedTitle} pot will be invested by your AVC provider. This
        assumes a net assumed growth rate of 3%.
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
    scheme: state.scheme.scheme,
  };
};

AvcAddToPayroll.propTypes = {
  formSharedTitle: PropTypes.string,
  planFrequency: PropTypes.number,
  totalPot: PropTypes.number,
  contributionAmount: PropTypes.number,
  setContributionAmount: PropTypes.func,
  contributionLoading: PropTypes.bool,
  totalProjection: PropTypes.number,
  scheme: PropTypes.object,
};

export default connect(mapStateToProps)(AvcAddToPayroll);
